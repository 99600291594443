import React from 'react';

const ActionGrid = () => {
    return (
        <div className="tw-w-11/12 tw-mx-auto">
            <h1 className="tw-text-5xl tw-font-medium tw-my-4 tw-text-center">Nuestras acciones</h1>
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-2 lg:tw-space-y-0 tw-space-x-0 lg:tw-space-x-2">
 
                <div className="tw-w-full lg:tw-w-1/4 tw-bg-red-600 tw-px-2 tw-py-4 hover:tw-bg-red-800 tw-flex tw-items-center">
                    <p className="tw-text-xl tw-text-center tw-text-white">
                        Estimular las investigaciones científicas de interés psicopedagógico y organizar congresos nacionales e internacionales.
                    </p>
                </div>
                <div className="tw-w-full lg:tw-w-1/4 tw-bg-red-600 tw-px-2 tw-py-4 hover:tw-bg-red-800 tw-flex tw-items-center tw-justify-center">
                    <p className="tw-text-xl tw-text-center tw-text-white">
                        Prestar servicios a sus asociados.
                    </p>
                </div>
                <div className="tw-w-full lg:tw-w-1/4 tw-bg-red-600 tw-px-2 tw-py-4 hover:tw-bg-red-800 tw-flex tw-items-center">
                    <p className="tw-text-xl tw-text-center tw-text-white">
                        Capacitar, asesorar y orientar a sus asociados en materias que sean de interés profesional.
                    </p>
                </div>
                <div className="tw-w-full lg:tw-w-1/4 tw-bg-red-600 tw-px-2 tw-py-4 hover:tw-bg-red-800 tw-flex tw-items-center">
                    <p className="tw-text-xl tw-text-center tw-text-white">
                        Estimular el perfeccionamiento profesional y propender a la difusión de la profesión por todos los medios a su alcance.
                    </p>
                </div>
                <div className="tw-w-full lg:tw-w-1/4 tw-bg-red-600 tw-px-2 tw-py-4 hover:tw-bg-red-800 tw-flex tw-items-center">
                    <p className="tw-text-xl tw-text-center tw-text-white">
                        Prestar colaboración a los organismos docentes en la formación profesional y generar instancias de intercambios de conocimientos profesionales y retroalimentación.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ActionGrid;
