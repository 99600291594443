import React from 'react';
import InputFieldText from "../../atoms/InputFieldText";

const UpdateSectionForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
    handleEdit
}) => {
    return (
        <form onSubmit={handleSubmit} className="tw-w-full tw-bg-gray-600 tw-p-2">
            <div className="tw-flex tw-flex-col tw-space-y-5 lg:tw-space-y-2 tw-items-center tw-w-full">
                <InputFieldText
                    classContainer="tw-w-full tw-border tw-border-gray-600"
                    classLabel="tw-hidden"
                    classInput="tw-px-4 focus:tw-outline-none tw-w-full tw-py-2"
                    classError = "tw-text-red-400 tw-absolute lg:tw-bottom-2 lg:tw-right-4"
                    bgRed={false}

                    name="nombre"
                    type="text"
                    label=""
                    placeholder="Ingrese el nombre de la sección ..."
                    maxLength={30}
                    
                />
                {/* <input name="search_content" className="tw-rounded-l-2xl tw-px-4 focus:tw-outline-none tw-w-full tw-py-2" type="text" placeholder=" Ingrese su búsqueda ..." /> */}
                <div className="tw-flex tw-items-center tw-space-x-3 tw-px-2 ">
                    <button
                        type="submit"
                        className="tw-text-base tw-bg-green-600 
                        hover:tw-bg-green-700 tw-text-white tw-rounded-lg tw-h-8 lg:tw-h-10 tw-w-full tw-px-3 lg:tw-px-6 tw-block tw-shadow-xl focu:tw-outline-none"
                    >
                        Cambiar
                    </button>
                    <button
                        type="button"
                        className="tw-text-base tw-bg-red-600 
                        hover:tw-bg-red-800 tw-text-white tw-rounded-lg tw-h-8 lg:tw-h-10 tw-w-full tw-px-3 lg:tw-px-6 tw-block tw-shadow-xl focu:tw-outline-none"
                        onClick={()=>{handleEdit()}}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default UpdateSectionForm;
