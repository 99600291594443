import React, {useContext, useEffect, useState} from "react";
import axios                from "axios";
import {Redirect }                  from "react-router-dom";
import { makeStyles }       from '@material-ui/core/styles';
import Pagination           from '@material-ui/lab/Pagination';
/**
 * Contextos
 */
import { AppContext }       from "../../context/AppContext";
import { UserContext }      from "../../context/UserContext";


/**
 * Constantes
 */
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
import MessageCard from "../molecules/cards/MessageCard";
import NonContent from "../atoms/NonContent";
import Loader from "../atoms/Loader";
import NonMessage from "../atoms/NonMessage";
import MessageSelected from "../molecules/generals/MessageSelected";


/**
 * Estilos para la paginacion
 */
 const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Messages = () => {
    // Cargamos las propiedades que usaremos en este componente
    // Estado de autenticacion (hay un usuario logueado?)
    const userContext = useContext(UserContext);
    const {userLogged, authStatus} = userContext;
    // Si hay alguna alerta que renderizar
    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;
    // Estado para manejar si hay algun mensaje seleccionado
    const [message, setMessage]   = useState(null);
    // Estado para manejar si hay mensajes recibidos
    const [messages, setMessages] = useState(null);
    // Estado para manejar si se esta cargando un mensaje
    const [loading, setLoading] = useState(false);
    //estilo paginacion
    const classes = useStyles();

    // Opciones de cada consulta, se debe ordenar por algo? que pagina estamos?
    const [sortBy]   = useState('created_at');
    const [sortAsc, setSortAsc] = useState(false);
    const [page, setPage]   = useState(1);
    const [totalMessages, setTotalMessages] = useState(0);
    const [perPage] = useState(10);
    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        //funcion asincrona para obtener los mensajes        
        const getMessages = async () => {
            try {
                // let url = `api/messages?${sortAsc === true ? 'sort_by=created_at':''}&per_page=${8}&page=${page}`;
                let url = `api/messages?sort_by=${sortBy}${
					sortAsc ? "&asc" : "&desc"
				}&per_page=${perPage}&page=${page}`;
                const response = await axios.get(url);
                
                if(response.data.message){
					// console.log('Recibimos un mensaje en la solicitud de noticias');
					return false;
				}
				return response.data;
            } catch (error) {
                return false;
            }
        };
        //no avanzamos hasta que se resuelva la promesa de la funcion asincrona
        getMessages().then(response => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
			if(!response){
				//array vacio, se mostrará sin contenido
				setMessages(null);
			}else{
				//como hubo un contenido obtenemos los mensajes
                if(response.data.length === 0){
                    setMessages(null);
                }else{
                    const messages = response.data;
                    setMessages(messages);	
                    setTotalMessages(response.last_page);
                }
			}
        })
    //se re ejecutará useEffect cada vez que haya un cambio en:
    }, [message, sortBy, sortAsc, perPage, page])

    function getMessage(value) {
        setMessage(null);
        setLoading(true);
        const getMessage = async (id) => {
            try{
                const response = await axios.get(`api/messages/${id}`);
                //comprobamos si hubo algun error en la solicitud
                if(response.data.message && response.data.type){
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(response.data.title, response.data.message, response.data.type);
                    // Activamos para que se renderice la alerta
                    setAlertRender(true); 
                    return false;
                }else{
                    //en el response recibiremos el contenido recien creado
                    return response.data;
                }
            }catch(error){
                // console.log(error);
                return false;
            }
        };
        
        getMessage(value).then(response => {
            if(response){
                setMessage(response);
                setLoading(false);
            }
        });
    }

    function destroyMessage(value) {  
        axios.delete(`api/messages/${value}`).then((response) => {
            //comprobamos si hubo algun error en la solicitud
            // Elevamos el titulo, mensaje y el tipo de mensaje
            handleAlertMessage(response.data.title, response.data.message, response.data.type);
            // Activamos para que se renderice la alerta
            setAlertRender(true); 
            setMessage(null);
        })
        .catch(error=>{
            // console.log(error);
            return false;
        })
    }

    return (
        <>
            {
                authStatus === LOGGED_IN ? (
                    userLogged.tipo_usuario === ADMIN ? (
                        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-full">
                            <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-4/12 tw-border-r">
                                <div className="tw-bg-white tw-border-b">
                                    <h1 className="tw-text-center tw-text-2xl tw-font-bold tw-p-2 tw-bg-white">Mensajes recibidos</h1>
                                    <div className="tw-flex tw-justify-between tw-px-2">
                                        <button className={`${sortAsc === false ? 'tw-text-black' : 'tw-text-blue-600'}`} disabled= {sortAsc === false ? true : false} 
                                            onClick={()=>{setSortAsc(false)}}
                                        >
                                            Más nuevos
                                        </button>
                                        <p>&laquo; Ordenar por &raquo; </p>
                                        <button className={`${sortAsc === false ? 'tw-text-blue-600' : 'tw-text-black'}`} disabled= {sortAsc === true ? true : false} 
                                            onClick={()=>{setSortAsc(true)}}
                                        >
                                            Más antiguos
                                        </button>
                                    </div>
                                </div>
                                {/* Hay mensajes que mostrar? */}
                                {
                                    messages ? messages.map((message) => 
                                            <MessageCard
                                                key={message.id} id={message.id}
                                                subject={message.subject} message={message.message} readed={message.readed} date={message.created_at}
                                                getMessage={getMessage}
                                            />) : <NonContent />
                                }
                                <div className={`${classes.root} tw-bg-white tw-px-2 tw-py-1 tw-mx-auto`}>
                                    <Pagination count={totalMessages} page={page} onChange={handleChangePage} color="primary" />
                                </div>
                            </div>
                            <div className="tw-w-full lg:tw-w-8/12 tw-p-3 tw-bg-white">
                                {/* Hay un mensaje seleccionado */}
                                {
                                    message !== null ? (
                                        <MessageSelected message={message} destroyMessage={destroyMessage} />
                                    ) : (
                                        // se está cargando es mensaje?
                                        loading === true ? (
                                            <Loader />
                                        ) : (
                                            <NonMessage/>
                                        )
                                    )
                                }           
                            </div> 
                        </div>
                    ) : (
                        <Redirect to="/"/>
                    )
                ) : <Redirect to="/"/>
            }
        </>
    );
};

export default Messages;