import React from 'react'
import Loader from '../../atoms/Loader';
import NonContent from '../../atoms/NonContent';
import UserRow from '../../molecules/cards/UserRow'

const UsersTable = ({users, handleUsers}) => {
    return (
        <div className="tw-grid tw-grid-cols-1 tw-gap-2 tw-my-6 tw-px-2 lg:tw-px-2">
            
            {users === null ? (
                <Loader />
            ) : users.length === 0 ? (
                <NonContent />
            ) : (
                users.map((user) => (
                    <UserRow
                        key={user.id}
                        user={user}
                        handleUsers = {handleUsers}
                    />
                ))
            )}
        </div>
    );
};

export default UsersTable;
