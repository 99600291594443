/**
 * Librerias de react o terceros
 */
import React from "react";

/**
 * Iconos
 */
import {FaComment } from "react-icons/fa";

const NonMessage = () => {
    return (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-60 tw-bg-white">
            <FaComment className="tw-text-gray-700 tw-m-4 tw-text-8xl tw-mx-auto"/>
            <p className="tw-text-3xl tw-text-gray-700 tw-text-center">Ningún mensaje seleccionado.</p>
        </div>
    );
};

export default NonMessage;
