/**
 * Librerias de react y terceros
 */
import React, { useContext } from "react";

/**
 * Contextos
 */
import { UserContext } from "../../../context/UserContext.js";

/**
 * Constantes
 */
import { ADMIN, LOGGED_IN } from "../../../constants/AuthStatus.js";

/**
 * Componentes
 */
import UserInfo from "../cards/UserInfo";
import Logo from "../../atoms/anims/Logo";
import SideBarItem from "../../atoms/buttons/SideBarItem";

/**
 * Iconos
 */
import { IoNewspaper } from "react-icons/io5";
import {
    FaUsers,
    FaDonate,
    // FaUserFriends,
} from "react-icons/fa";
import { TiMessages } from "react-icons/ti";
import { AiFillHome } from "react-icons/ai";
import { BiCalendarEvent } from "react-icons/bi";
// import { RiSurveyFill } from "react-icons/ri";
import { BsFolderFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const SideBar = () => {
    // Contextos a usar
    const userContext = useContext(UserContext);
    const { userLogged, authStatus } = userContext;

    return (
        <div
            id="menu_sidebar"
            className="
                tw-absolute tw-left-0 
                tw-w-full   lg:tw-w-1/6 
                tw-h-11     lg:tw-h-screen 
                tw-z-50 
                fondo_sidebar
                tw-overflow-y-auto
                tw-overflow-x-auto 
                scrollbar-sidebar
                
                tw-flex
                tw-flex-row
                lg:tw-flex-col 
                
                tw-justify-between
                tw-transform tw--translate-x-full
                "
        >
            {/* Logo principal */}
            <div className="sdb_item tw-hidden lg:tw-block tw-mx-auto tw-mt-2">
                <NavLink to="/">
                    <Logo />
                </NavLink>
            </div>
            {/* Items/botones del menú lateral */}
            <div className="tw-flex tw-flex-row lg:tw-flex-col lg:tw-px-4 tw-py-1 lg:tw-py-2 tw-text-white">
                {/* Botón para volver a la pagina de inicio */}
                <SideBarItem link="/">
                    <AiFillHome className="tw-text-2xl lg:tw-text-xl tw-text-white" />
                </SideBarItem>
                <SideBarItem link="/noticias" title="Noticias">
                    <IoNewspaper className="tw-text-2xl lg:tw-text-xl" />
                </SideBarItem>
                <SideBarItem
                    link="/eventos-actividades"
                    title="Eventos actividades"
                >
                    <BiCalendarEvent className="tw-text-2xl lg:tw-text-xl" />
                </SideBarItem>
                {/* <SideBarItem link="/encuestas" title="Encuestas">
                    <RiSurveyFill className="tw-text-2xl lg:tw-text-xl" />
                </SideBarItem> */}
                <SideBarItem
                    link="/trabajos"
                    title="Trabajos"
                >
                    <FaDonate className="tw-text-2xl lg:tw-text-xl" />
                </SideBarItem>
                {/* <SideBarItem link="/foros" title="Foros">
                    <RiSurveyFill className="tw-text-2xl lg:tw-text-xl" />
                </SideBarItem> */}
                <SideBarItem link="/documentos" title="Documentos">
                    <BsFolderFill className="tw-text-2xl lg:tw-text-xl" />
                </SideBarItem>
                {
                    userLogged.tipo_usuario === ADMIN ? (
                        <SideBarItem link="/afiliados" title="Afiliados">
                            <FaUsers className="tw-text-2xl lg:tw-text-xl" />
                        </SideBarItem>
                    ) : (null)
                }
                {
                    userLogged.tipo_usuario === ADMIN ? (
                        <SideBarItem link="/mensajes-recibidos" title="Mensajes recibidos">
                            <TiMessages className="tw-text-2xl lg:tw-text-xl" />
                        </SideBarItem>
                    ) : (null)
                }
                
            </div>
            {/* Se debe mostrar la info del usuario y el botón para cerrar sesión? */}
            {
                authStatus === LOGGED_IN ? (
                    <UserInfo />
                ) : (
                    null
                )
            }
        </div>
    );
};

export default SideBar;