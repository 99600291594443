import React from 'react'
import InputFieldText from '../../atoms/InputFieldText';
import { Field } from 'formik';

const RegistryFormPart2 = ({
    handleChange, 
    handleSubmit, 
    isSubmitting, 
    resetForm, 
    values, 
    errors, 
    touched, 
    

    setCurrentForm,

    communes,

}) => {
    
    return (
        
        <form onSubmit={handleSubmit}> 
        <h1 className="tw-text-white tw-text-2xl tw-text-center">Información laboral</h1>
            <hr className="tw-w-1/2 tw-mx-auto tw-bg-white tw-h-px tw-rounded tw-mb-5"></hr>

            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="direccion_laboral"  type="text" label="Dirección laboral" />
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="ciudad_laboral"     type="text" label="Ciudad laboral" />

                {
                    communes === null ? (
                        null
                    ) : (        
                        <div className="tw-w-full lg:tw-w-1/3 tw-px-3">
                            
                            <label className="tw-text-white tw-text-xl">Comuna laboral</label>
                            <Field as="select" name="comuna_laboral" className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                                    onChange={handleChange}
                            >
                                {
                                    communes.map((commune,index) => (
                                        <option value={`${commune.name}`} key={index}>{commune.name}</option>
                                    ))
                                }
                                
                            </Field>
                            {
                                touched.comuna_laboral && errors.comuna_laboral ? <p className="tw-text-gray-300 tw-text-xl"> {errors.comuna_laboral}</p> : null
                            }
                        </div>
                    )
                }
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-3/4 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="telefono_laboral" placeholder="YYXXXXXX" type="tel"   label="Teléfono laboral (opcional)" min="6" max="10"/>
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/4 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="cargo"     type="text"  label="Cargo" />
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <div className="tw-w-full lg:tw-w-4/12 tw-px-3">
                    <label className="tw-text-white tw-text-xl">Tipo afiliación</label>
                    <Field as="select" name="tipo_afiliacion" className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                        
                    >
                        <option value="Trabajador">Trabajador</option>
                        <option value="CONAECH">CONAECH</option> 
                        <option value="Cesante">Cesante</option>
                    </Field>
                </div>
            </div>

            <div className="tw-flex tw-items-center tw-justify-center lg:tw-justify-end tw-space-x-2 tw-mb-6 lg:tw-mr-10">
                
                <button type="button"
                        className="tw-text-lg tw-font-semibold tw-bg-yellow-400 hover:tw-bg-yellow-600 tw-text-white tw-rounded-lg tw-p-2 lg:tw-px-6 lg:tw-py-2 tw-block tw-shadow-xl"
                        onClick={()=>{setCurrentForm(1)}}
                >
                    Paso anterior
                </button>               
                <button type="submit"
                        className="tw-text-lg tw-font-semibold tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded-lg tw-p-2 lg:tw-px-6 lg:tw-py-3 tw-block tw-shadow-xl"
                >
                    Paso siguiente
                </button>               
                
            </div>
        </form>
    );
};

export default RegistryFormPart2;