import React from 'react'
import { AiFillContacts, AiFillPhone } from "react-icons/ai";
import { MdLocationOn, MdTimelapse } from "react-icons/md";

import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.locale("es");
dayjs.extend(relativeTime);

const JobCard = ({id, imagen_principal, titulo, contacto, ubicacion, telefono, updated_at, tipo, selectJob, url}) => {
    return (
        <div className="tw-flex tw-relative tw-w-full tw-max-w-md lg:tw-max-w-xs tw-border-b tw-border-l tw-border-r
        tw-my-4 tw-shadow-lg tw-rounded-md tw-h-60
        tw-mx-2">
            <div className="tw-absolute tw-z-0 tw-w-full tw-h-full">
                <img alt="imagen trabajo" className="tw-object-cover tw-object-center tw-w-full tw-h-full tw-mb-4 tw-bg-gray-800 tw-rounded-t-md" src={imagen_principal} />
            </div>
            <div className="tw-w-full tw-z-10">
                <div className="tw-w-full tw-h-2/5  tw-block tw-relative tw-border-b tw-border-black">
                    <div className=" tw-z-10 tw-absolute tw-bg-gray-800 tw-h-full tw-w-full tw-top-0 tw-left-0 tw-rounded-t-md tw-opacity-30">
                    </div>
                    <h1 className="tw-absolute tw-mt-8 tw-ml-3 tw-z-20 tw-bottom-0 tw-mr-2 tw-right-0 tw-font-semibold tw-text-xs tw-text-white"> {dayjs(updated_at).fromNow()}</h1>
                    <h1 className="tw-absolute tw-mt-8 tw-ml-3 tw-z-20 tw-top-0 tw-left-0 tw-font-bold tw-text-lg tw-text-white">{titulo}</h1>
                </div>
                <div className="tw-w-full tw-h-3/5 tw-block tw-bg-gray-800  tw-bg-opacity-80">
                    <div className=" tw-pt-2">
                        <p className="tw-text-sm tw-ml-4 tw-z-20  tw-text-gray-200 tw-font-medium">
                            <AiFillContacts className="tw-inline-block tw-text-2xl tw-text-blue-600" /> {contacto}
                        </p>
                        <p className="tw-text-sm tw-ml-4 tw-z-20 tw-text-gray-200   tw-font-medium">
                            <AiFillPhone className="tw-inline-block tw-transform tw-rotate-90 tw-text-2xl tw-text-yellow-600" /> {telefono}
                        </p>
                        <p className="tw-text-sm tw-ml-4 tw-z-20 tw-text-gray-200   tw-font-medium">
                            <MdLocationOn className="tw-inline-block tw-text-2xl tw-text-green-600" /> {ubicacion}
                        </p>
                        <div className="tw-flex tw-w-full tw-z-20 tw-mt-2 tw-justify-between">
                            <p className=" tw-p-2 tw-ml-4 tw-rounded-md tw-text-white">
                                <MdTimelapse className="tw-inline-block tw-text-2xl tw-text-white" /> {tipo}
                            </p>
                            <button className="tw-mr-4 tw-bg-green-600 tw-rounded-md tw-text-white tw-px-2 hover:tw-bg-green-800"
                                onClick={() => { selectJob(url) }}
                            >
                                Detalles
                            </button>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobCard;
