import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './styles/index.css';
import './styles/App.css';
import "suneditor/dist/css/suneditor.min.css";
import App from './components/App.js';


ReactDOM.render(
  	<React.StrictMode>
    	<App />
  	</React.StrictMode>,
	document.getElementById('root')
);
