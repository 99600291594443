import React from 'react'
import { useField, Field } from 'formik';
// import { Field } from 'formik';


const InputField = ({ label, classContainer, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className={`${classContainer}`}>
            <label className="tw-block tw-text-white tw-text-lg tw-font-medium tw-mb-2">
                {label}
            </label>
            <input className="tw-block tw-w-full tw-bg-gray-200 tw-border-2 tw-border-gray-200 tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white focus:tw-border-green-500" 
                    {...field} 
                    {...props} 
            />
            {meta.touched && meta.error && (
                <p className="tw-text-white">{meta.error}</p>
            )}
        </div>
    );
};
const EditUserConfig = ({handleChange, handleSubmit, isSubmitting, resetForm, values, errors, touched, cancelEditConfig}) => {
	return (
        <form onSubmit={handleSubmit} className="tw-w-full">
            <h1 className="tw-text-xl tw-font-semibold tw-text-white tw-text-center sm:tw-text-left">Modifica tus datos</h1>
            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-bg-white tw-mb-2 tw-mx-auto sm:tw-mx-0"></hr>

            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="telefono" type="text" label="Teléfono" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="celular" type="text" label="Celular" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="email" type="email" label="Correo" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="direccion" type="text" label="Dirección" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="ciudad" type="text" label="Ciudad" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="region" type="text" label="Region" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="comuna" type="text" label="Comuna" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="telefono_laboral" type="text" label="Teléfono laboral" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="cargo" type="text" label="Cargo" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="direccion_laboral" type="text" label="Direccion laboral" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="ciudad_laboral" type="text" label="Ciudad laboral" />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="comuna_laboral" type="text" label="Comuna laboral" />
            </div>
            <div className="tw-w-full tw-px-3 tw-mb-2">
                <label className="tw-text-white tw-text-xl">Mostrarme en buscar profesional</label>
                <p className="tw-text-red-400 tw-text-lg tw-text-justify tw-mb-2">
                    Al habilitar esta opción usted pertime que www.colegionacionalpsp.cl muestre su foto de perfil, cargo, nombre, correo, región y comuna en la sección buscar profesional. Sección de la página a la cual pueden acceder usuarios externos (no registrados). Si no está de acuerdo seleccione "No aparecer".
                </p>
                <Field as="select" name="buscador" className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" >    
                    <option value="true">Aparecer</option> 
                    <option value="false">No aparecer</option> 
                </Field>      
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-1 tw-justify-center tw-text-gray-200 tw-text-center">
                <button className = "tw-bg-red-600 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        type="button"
                        onClick = {cancelEditConfig}
                >
                    Cancelar
                </button> 

                <button className="tw-bg-green-700 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        type="submit"
                >
                    Actualizar
                </button> 
            </div>
        </form>	
    );
};

export default EditUserConfig;
