/**
 * Librerias de react o terceros
 */
import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.locale("es");
dayjs.extend(relativeTime);

const MessageCard = ({ id, subject, message, readed, date, getMessage }) => {
    return (
        <button
            className={`${
                readed ? "tw-bg-gray-300" : "tw-bg-white"
            }  focus:tw-outline-none hover:tw-bg-gray-400`}
            onClick={() => {
                getMessage(id);
            }}
        >
            <div className="tw-flex tw-flex-row tw-p-1 tw-border-b">
                <img
                    src="https://api.colegionacionalpsp.cl/contents/images/users-profile/default-user.png"
                    alt="user contact"
                    className="tw-object-cover tw-w-14 tw-mr-2 tw-rounded-full"
                />
                <div className="tw-flex tw-flex-col tw-items-start tw-w-full">
                    <p className="tw-text-md tw-font-medium">
                        {subject.slice(0, 30)}
                    </p>
                    <p className="tw-text-gray-500">
                        {message.slice(0, 30)} ...
                    </p>
                    <p className="tw-text-gray-600 tw-text-xs">
                        {dayjs(date).fromNow()}
                    </p>
                </div>
                {!readed ? (
                    <div className="tw-flex tw-items-center  tw-ml-1">
                        <p className="tw-p-1 tw-text-white tw-rounded-full tw-bg-red-500">
                            Nuevo!
                        </p>
                    </div>
                ) : null}
            </div>
        </button>
    );
};

export default MessageCard;