/**
 * Librerias de react o terceros
 */
import { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/**
 * Contextos
 */
import { AppContext } from "../../context/AppContext";
import { UserContext } from "../../context/UserContext";

/**
 * Componentes generales
 */
import Header from "../molecules/generals/Header";
import Navbar from "../molecules/generals/Navbar";
import AlertMessage from "../atoms/AlertMessage";
import SideBar from "../molecules/generals/SideBar";
import MenuButton from "../atoms/buttons/MenuButton";

/**
 * Paginas
 */
import Home from "./Home";
import Login from "./Login";
import ErrorPage from "./ErrorPage";

/**
 * Utilidades
 */
import { LOGGED_IN } from "../../constants/AuthStatus.js";
import Footer from "../molecules/generals/Footer";
import Registry from "./Registry";
import Messages from "./Messages";
import Contact from "./Contact";
import AboutUs from "./AboutUs";
import News from "./News";
import AdminContent from "./AdminContent";
import Documents from "./Documents";
import Affiliates from "./Affiliates";
import UserConfig from "./UserConfig";
import NewSelected from "./NewSelected";
import Jobs from "./Jobs";
import BuscarProfesional from "./BuscarProfesional";
import EventsActivities from "./EventsActivities";

const MasterPage = () => {
    // Contextos que usaremos en este componente
    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);
    // Cargamos las propiedades que usaremos en este componente
    // Estado de autenticacion (hay un usuario logueado?)
    const { authStatus } = userContext;
    // Estado del menu lateral (solo cuando se esta autenticado)
    // Estado para verificar si hay una alerta que renderizar
    const { alertRender } = appContext;

    return (
        <Router>
            <div className="tw-relative tw-flex tw-bg-white tw-bg-opacity-80 tw-flex-col-reverse lg:tw-flex-row tw-h-screen tw-overflow-hidden">
                {/* Se debe mostra el menu lateral? */}
                {authStatus === LOGGED_IN ? <SideBar /> : null}
                {/* div auxiliar para el layout principal */}
                {/* Se debe mostrar solo cuando haya un usuario logueado */}
                {authStatus === LOGGED_IN ? (
                    // <div className="sdb_aux tw-w-11 lg:tw-w-0 fondo_sidebar"></div>
                    <div className="sdb_aux tw-hidden lg:tw-block lg:tw-w-0 fondo_sidebar"></div>
                ) : null}
                {/* Layout principal de la web tw-pb-20 lg:tw-pb-0*/}
                <div
                    id="master_page"
                    className="tw-w-full tw-overflow-y-auto tw-overflow-x-hidden tw-pb-11 lg:tw-pb-0 scrollbar"
                >
                    {/* Search form, iconos y ley de transparencias */}
                    <Header />
                    <Navbar />
                    {/* Contenedor principal de cada pagina, es relativo puesto que podría 
                        haber una aletar con posicion absoluta o el botón para ocultar el menú */}
                    <div className="">
                        {/* Se debe mostrar el botón para ocultar el menú? */}
                        {authStatus === LOGGED_IN ? <MenuButton /> : null}
                        {/* Rutas definidas en la pagina */}
                        <Switch>
                            <Route path="/" exact                           component= { Home       } />        
                            <Route path="/sobre-nosotros"                   component= { AboutUs    } />
                            <Route path="/noticias/:url"                    component= { NewSelected} />
                            <Route path="/noticias"                         component= { News       } />
                            <Route path="/trabajos"                         component= { Jobs       } />
                            <Route path="/documentos"                       component= { Documents  } />
                            <Route path="/afiliados"                        component= { Affiliates } />
                            <Route path="/mensajes-recibidos"               component= { Messages   } />
                            <Route path="/contacto"                         component= { Contact    } />

                            <Route path="/buscar-profesional"               component= {BuscarProfesional}  />

                            <Route path="/configuracion-usuario/:userID" component= { UserConfig} />
                            <Route path="/eventos-actividades"              component={EventsActivities} />
                            <Route path="/administrar-contenido/:type/:redirectTo/:title/:action" component={AdminContent} />
                            <Route path="/login"                            component= {Login}    />
                            <Route path="/registro"                         component= {Registry} />
                            <Route component={ErrorPage} />
                        </Switch>
                    </div>
                    {/* Alertas que podrian mostrarse o no */}
                    {/* Hay algun alerta que renderizar? */}
                    {alertRender ? <AlertMessage /> : null}
                    <Footer />
                </div>
            </div>
        </Router>
    );
};

export default MasterPage;