import React, { useContext, useEffect, useState } from 'react';
import { Formik }           from "formik";
import axios from 'axios';
import RegistryForm from '../molecules/forms/RegistryForm';

import checkRut from '../../utils/checkRut.js';
import Loader from '../atoms/Loader';
import {AppContext} from '../../context/AppContext.js';
import { Redirect } from 'react-router';
import RegistryFormPart2 from '../molecules/forms/RegistryFormPart2';
import RegistryFormPart3 from '../molecules/forms/RegistryFormPart3';
import RegistryFormPart4 from '../molecules/forms/RegistryFormPart4';

import Regions from '../../utils/region.json';

const validationPersonalInfo = values => {
	let errors = {};
    // Validacion del rut
    if(!values.rut){
      errors.rut = "Ingrese su rut!";
    }else if(!checkRut(values.rut)){
      errors.rut = "Rut no válido!";
    }
  
    // validacion email
	// const rex = new RegExp('^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$');
	if(!values.email){
	  errors.email = "Ingrese su correo!";
	}else if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/.test(values.email)){
	  errors.email = "Correo no válido!";
	} else if (values.email.length >= 50){
        errors.email = "Correo no válido!";
    }
    
    // Validacion password
	if (!values.password) {
	  errors.password = "Ingrese su contraseña!";
	} else if (values.password.length < 8) {
	  errors.password = "La constraseña debe ser de mas de 8 caracteres!";
    } else if (values.password.length >= 35 ) {
        errors.password = "Contraseña no valida!";
    }

    if (!values.password_confirmation) {
        errors.password_confirmation = "Ingrese su contraseña!";
      } else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = "Las contraseñas no coinciden!";
      }

	// Validacion nombre
	if (!values.nombre) {
	  errors.nombre = "Ingrese sus nombres!";
	} else if (values.nombre.length >= 30) {
	  errors.nombre = "Nombres no válido!";
	}

	// Validacion apellido paterno
	if(!values.apellido_paterno){
	  errors.apellido_paterno = "Ingrese su apellido!";
	}else if (values.apellido_paterno.length <= 3 || values.apellido_paterno.length >= 30){
	  errors.apellido_paterno = "Apellido no válido!";
	}

	// Validacion apellido materno
	if(!values.apellido_materno){
	  errors.apellido_materno = "Ingrese su apellido!";
	}else if (values.apellido_materno.length <= 3 || values.apellido_materno.length >= 30){
	  errors.apellido_materno = "Apellido no válido!";
	}


	// validacion fecha de nacimiento
	if(!values.fecha_nacimiento){
	  errors.fecha_nacimiento = "Ingrese su fecha de nacimiento!";
	} else if (values.fecha_nacimiento.length >= 30){
        errors.fecha_nacimiento = "Fecha de nacimiento no válida!";
    }

	// Validacion de nacionalidad
	if(!values.nacionalidad) {
	  errors.nacionalidad = "Ingrese su nacionalidad!";
	}else if(values.nacionalidad.length <= 4 || values.nacionalidad.length > 30){
	  errors.nacionalidad = "Nacionalidad no válida!";
	}

	// Validacion direccion particular
	if(!values.direccion){
	  errors.direccion = "Ingrese su dirección!";
	}else if(values.direccion.length < 4 || values.direccion.length > 40){
	  errors.direccion = "Dirección no válida!";
	}
    // validacion ciudad
    if(!values.ciudad){
        errors.ciudad = "Ingrese su ciudad!";
    }else if(values.ciudad.length < 4 || values.ciudad.length >= 30){
        errors.ciudad = "Ciudad no válida!";
    }
	// validacion comuna
	// if(values.comuna === "Sin seleccionar"){
	//   errors.comuna = "Ingrese su comuna!";
	// }else if(values.comuna.length >= 30){
	//   errors.comuna = "Comuna no válida!";
	// }

    // validacion comuna
	// if(values.region){
    //     errors.region = "Ingrese su Región!";
    // }else if(values.region.length >= 40){
    //     errors.region = "Región no válida!";
    // }

	// validacion celular
	if(!values.celular){
	  errors.celular = "Ingrese su celular!";
	}else if(values.celular.length < 8 || values.celular.length > 12){
	  errors.celular = "Celular no valido!";
	}

	return errors;
}

const validationWorkInfo = values => {
	let errors = {};
	// validacion direccion laboral
	if(!values.direccion_laboral){
		errors.direccion_laboral = "Ingrese su dirección!";
	  }else if(values.direccion_laboral.length < 4 || values.direccion_laboral.length >= 50){
		errors.direccion_laboral = "Dirección no valida!";
	  }
  
	  // validacion ciudad laboral
	  if(!values.ciudad_laboral){
		errors.ciudad_laboral = "Ingrese su ciudad!";
	  }else if(values.ciudad_laboral.length < 4 || values.ciudad_laboral.length >= 30){
		errors.ciudad_laboral = "Ciudad no valida!";
	  }
  
	//   // validacion comuna laboral
	//   if(values.comuna_laboral === "Sin seleccionar"){
	// 	errors.comuna_laboral = "Ingrese su comuna!";
	//   }else if(values.comuna_laboral.length < 4 || values.comuna_laboral.length >= 30){
	// 	errors.comuna_laboral = "Comuna no valida!";
	//   }
  
  
	  // validacion cargo laboral
	  if(!values.cargo){
		errors.cargo = "Ingrese su cargo!";
	  }else if(values.cargo.length < 3 || values.cargo.length >= 40){
		errors.cargo = "Cargo no valido!";
	  }
	  return errors;
}
	
const validationStudyInfo = values => {
	let errors = {};
	// validacion casa de estudio
	if(!values.casa_estudio){
	  errors.casa_estudio = "Ingrese su casa de estudio!";
	}else if(values.casa_estudio.length < 2 || values.casa_estudio.length > 60){
	  errors.casa_estudio = "Casa de estudio no valida!";
	}

	// validacion fecha de titulacion
	if(!values.fecha_titulacion){
	  errors.fecha_titulacion = "Ingrese su fecha de titulación!";
	} else if (values.fecha_nacimiento.length >= 30){
        errors.fecha_nacimiento = "Fecha de titulación no válida!";
    }

	// validacion codigo validacion titulo
	if(!values.codigo_validacion_titulo){
	  errors.codigo_validacion_titulo = "Ingrese su código de título!";
	}else if(values.codigo_validacion_titulo.length < 6 || values.codigo_validacion_titulo.length >= 400){
	  errors.codigo_validacion_titulo = "Código no valido!";
	}
	
	return errors;
};

const RegistrySection = () => {

	const appContext = useContext(AppContext)
	const { handleAlertMessage, setAlertRender } = appContext;
	const [sending] = useState(false);
	const [redirect, setRedirect] = useState(false);
    
	const [currentForm, setCurrentForm] = useState(1);
	
	//------ Datos del registro ------//
	
	//datos personales
	const [rut, setRut] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [nombre, setNombre] = useState('');
	const [apellidoPaterno, setApellidoPaterno] = useState('');
	const [apellidoMaterno, setApellidoMaterno] = useState('');
	const [fechaNacimiento, setFechaNacimiento] = useState('');
	const [sexo, setSexo] = useState('Otro');
	const [celular, setCelular] = useState('');
	const [telefono, setTelefono] = useState('');
	const [direccion, setDireccion] = useState('');
	const [ciudad, setCiudad] = useState('');


	const [regions] = useState(Regions.regions);
	const [communes, setCommunes] = useState(null);
	const [region, setRegion] = useState('Arica y Parinacota');
	const [comuna, setComuna] = useState('Arica');

	const [nacionalidad, setNacionalidad] = useState('');
	//datos laborales
	const [direccionLaboral, setDireccionLaboral] = useState('');
	const [ciudadLaboral, setCiudadLaboral] = useState('');
	const [comunaLaboral, setComunaLaboral] = useState('');
	const [telefonoLaboral, setTelefonoLaboral] = useState('');
	const [cargo, setCargo] = useState('');
	//datos academicos
	const [casaEstudio, setCasaEstudio] = useState('');
	const [fechaTitulacion, setFechaTitulacion] = useState('');
	const [codigoValidacionTitulo, setCodigoValidacionTitulo] = useState('');
	//otros
	// const [tipoAfiliacion, setTipoAfiliacion] = useState('Trabajador');
	const [archivoTitulo, setArchivoTitulo] = useState(null);
	const [archivoCarnet, setArchivoCarnet] = useState(null);
	const [archivoMenores, setArchivoMenores] = useState(null);


	useEffect(() => {
		const regionSelected = regions.filter(reg => (reg.name === region))[0];
		const comunas = regionSelected.communes;
		setCommunes(comunas);
		setRegion(regionSelected.name);
		setComuna(regionSelected.communes[0].name);
		setComunaLaboral(regionSelected.communes[0].name);
	}, [regions, region])

	const handleRegions = (value) => {
		setCommunes(null);
		const region = regions.filter(region => (region.name === value))[0];
		const comunas = region.communes;
		setCommunes(comunas);
		setRegion(region.name);
		setComuna(comunas[0].name);
		setComunaLaboral(comunas[0].name);
		console.log(comunaLaboral);
	}

	const handleCommune = (value) => {
		setComuna(value);
	}

	//Cambios en los archivos subidos
    function handleFile(e) {
        //cuando capture un cambio en el input, va a tomar el valor para ingresarlo en los estado de registro
        // Maximo tamaño de los archivos a subir
        // 2MB por cada uno
		const maxAllowedSize = 2 * 1024 * 1024;
		
		let fileUpload = e.target.files[0];
		
		// console.log(fileUpload.type);
		if (fileUpload.size > maxAllowedSize || !/application\/*/.test(fileUpload.type)) {
			alert(
				"El archivo " +
					fileUpload.name +
					" no está permitido (Supera el límite de tamaño 2MB) o no es un formato valido."
			);
		}else{
			if(e.target.name === 'archivo_carnet'){
				setArchivoCarnet(fileUpload);
			}else if(e.target.name === 'archivo_menores'){
				setArchivoMenores(fileUpload);
			}else if(e.target.name === 'archivo_titulo'){
				setArchivoTitulo(fileUpload);
			}
		}
    }
	
	const setDataFormPersonalInfo = (values) => {

		let rut = values.rut.replace(/\./g,'');
    	// Despejar Guión
	    rut = rut.replace(/-/g,'');
		setRut(rut);
		setEmail(values.email);
		setPassword(values.password);
		setNombre(values.nombre);
		setApellidoPaterno(values.apellido_paterno);
		setApellidoMaterno(values.apellido_materno);
		setFechaNacimiento(values.fecha_nacimiento);
		setSexo(values.sexo);
		setCelular(values.celular);
		setTelefono(values.telefono);
		setDireccion(values.direccion);
		setCiudad(values.ciudad);
		// setComuna(values.comuna);
		// setRegion(values.region);
		setNacionalidad(values.nacionalidad);
		
		setCurrentForm(2);

	}

	const setDataFormWorkInfo = (values) => {
		setDireccionLaboral(values.direccion_laboral);
		setCiudadLaboral(values.ciudad_laboral);
		// setComunaLaboral(values.comuna_laboral);
		setTelefonoLaboral(values.telefono_laboral);
		setCargo(values.cargo);
		// setTipoAfiliacion(values.tipo_afiliacion);
		setCurrentForm(3);
	}

	const setDataFormStudyInfo = (values) => {
		setCasaEstudio(values.casa_estudio);
		setFechaTitulacion(values.fecha_titulacion);
		setCodigoValidacionTitulo(values.codigo_validacion_titulo);
		setCurrentForm(4);
	}

	const sendData = (values) => {
				
		let formData = new FormData();
		formData.append('rut', rut);
		formData.append('email', values.email);
		formData.append('password', values.password);
		formData.append('nombre', values.nombre);
		formData.append('apellido_paterno', values.apellido_paterno);
		formData.append('apellido_materno', values.apellido_materno);
		formData.append('fecha_nacimiento', values.fecha_nacimiento);
		formData.append('sexo', values.sexo);
		formData.append('celular', values.celular);
		formData.append('telefono', values.telefono);
		formData.append('direccion', values.direccion);
		formData.append('ciudad', values.ciudad);
		formData.append('comuna', comuna);
		formData.append('region', region);
		formData.append('nacionalidad', values.nacionalidad);
		formData.append('direccion_laboral', values.direccion_laboral);
		formData.append('ciudad_laboral', values.ciudad_laboral);
		formData.append('comuna_laboral', comunaLaboral);
		formData.append('telefono_laboral', values.telefono_laboral);
		formData.append('cargo', values.cargo);
		formData.append('tipo_afiliacion', values.tipo_afiliacion);
		//datos academicos
		formData.append('casa_estudio', values.casa_estudio);
		formData.append('fecha_titulacion', values.fecha_titulacion);
		formData.append('codigo_validacion_titulo', values.codigo_validacion_titulo);
		//otros
		formData.append("archivos[]", archivoTitulo);
		formData.append("archivos[]", archivoMenores);
		formData.append("archivos[]", archivoCarnet);

		const createUser = async (user) => {
            try {
                const response = await axios.post("api/register", user);
                if (response.data.message) {
					if(response.data.type === "alert" || response.data.type === "error"){
						handleAlertMessage(
							response.data.title,
							response.data.message,
							response.data.type
						);
						// Activamos para que se renderice la alerta
						setAlertRender(true);
						return false;
					}
					// Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        "Usuario registrado",
                        "Registro realizado correctamente, te llegará la respuesta a tu correo con la validación de tus documentos.",
                        "success"
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
					return true;
                } else {
                    //si no hubo algun mensaje de error entonces
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        "Usuario registrado",
                        "Registro realizado correctamente, te llegará la respuesta a tu correo con la validación de tus documentos.",
                        "success"
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
                    //en el response recibiremos el contenido recien creado
                    return true;
                }
            } catch (error) {
                // console.log(error);
				return false;
            }
        };

		createUser(formData).then((response) => {
            
            if (!response) {
                // console.log("hubo algun error?.");
            } else{
				setRedirect(true);
			}

        });

	}
	// console.log(communes);
    return (

		<>
			{	
				redirect ? <Redirect to="/" /> :(
					sending ? <Loader /> : (
						currentForm === 1 ? (
							<Formik
								initialValues={{
									//datos personales
									rut: rut,
									email: email,
									password: password,
									password_confirmation: '',
									nombre: nombre,
									apellido_paterno: apellidoPaterno,
									apellido_materno: apellidoMaterno,
									fecha_nacimiento: fechaNacimiento,
									sexo: sexo,
									celular: celular,
									telefono: telefono,
									direccion: direccion,
									ciudad: ciudad,
									comuna: comuna,
									region: region,
									nacionalidad: nacionalidad,
								}}
								onSubmit={(values, actions) => {
									
									actions.setSubmitting(false);
									// actions.resetForm(true);
									// console.log(values);
									setDataFormPersonalInfo(values);
						
								}}
								
								validate={validationPersonalInfo}
							>
								{props => <RegistryForm {...props} 
												regions={regions}
												region={region}
												communes={communes}
												setCommunes={setCommunes}
												handleRegions={handleRegions}
												handleCommune={handleCommune} 
											/>}
							</Formik>

						) : (
							currentForm === 2 ? (
								<Formik
									initialValues={{
										
										//datos laborales
										direccion_laboral:direccionLaboral,
										ciudad_laboral:ciudadLaboral,
										comuna_laboral:comunaLaboral,
										telefono_laboral:telefonoLaboral,
										cargo:cargo,
										//otros
										tipo_afiliacion:'Trabajador',
								
									}}
									onSubmit={(values, actions) => {
										// console.log(values)
										actions.setSubmitting(false);
										// actions.resetForm(true);
										setDataFormWorkInfo(values);
				
									}}
									validate={validationWorkInfo}
								>
									{props => <RegistryFormPart2 {...props} 
												communes={communes}
												setCurrentForm={setCurrentForm}
												/>}
								</Formik>
							) : (
								currentForm === 3 ? (
									<Formik
										initialValues={{
											// datos academicos
											casa_estudio:casaEstudio,
											fecha_titulacion:fechaTitulacion,
											codigo_validacion_titulo:codigoValidacionTitulo
										}}
										onSubmit={(values, actions) => {
											// console.log(values)
											actions.setSubmitting(false);
											// actions.resetForm(true);
											setDataFormStudyInfo(values);
											
										}}

										validate={validationStudyInfo}
									>
										{props => <RegistryFormPart3 {...props}
													setCurrentForm={setCurrentForm}
												/>}
									</Formik>
								) : (
									currentForm ===  4 ? (
										<Formik
											initialValues={{
											}}
											onSubmit={(values, actions) => {
												// console.log(values)
												if(archivoTitulo === null || archivoCarnet === null || archivoMenores === null){
													alert('Suba todos los archivos solicitados.');
												} else {
													actions.setSubmitting(false);
													// actions.resetForm(true);
													sendData(values);
												}
											}}
										>
											{props => <RegistryFormPart4 {...props} 
														
														handleFile={handleFile}
														archivoTitulo = {archivoTitulo}
														archivoCarnet = {archivoCarnet}
														archivoMenores = {archivoMenores}
														setArchivoTitulo = {setArchivoTitulo}
														setArchivoCarnet = {setArchivoCarnet}
														setArchivoMenores = {setArchivoMenores}
														setCurrentForm={setCurrentForm}
													/>}
										</Formik>
									) : (
										null
									)
								) 
							)
						)
					)
				)
			} 
		</>
    );
};

export default RegistrySection;
