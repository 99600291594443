/**
 * Librerias de react o terceros
 */
import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        
        <div className="tw-bg-gray-800 tw-pt-10 tw-static tw-bottom-0">
            <div className="tw-max-w-6xl tw-m-auto tw-text-gray-800 tw-flex tw-flex-wrap tw-justify-left">
                <div className="tw-p-5 tw-w-1/2 sm:tw-w-4/12 md:tw-w-3/12">
                    <div className="tw-text-xs tw-uppercase tw-text-gray-400 tw-font-medium tw-mb-6">
                        Home
                    </div>
                    <NavLink
                        to="/"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        Inicio
                    </NavLink>
                    
                    <NavLink
                        to="/registro"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        Registro
                    </NavLink>
                    <NavLink
                        to="/login"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        Ingreso
                    </NavLink>
                </div>
                <div className="tw-p-5 tw-w-1/2 sm:tw-w-4/12 md:tw-w-3/12">
                    <div className="tw-text-xs tw-uppercase tw-text-gray-400 tw-font-medium tw-mb-6">
                        Actualidad
                    </div>
                    <NavLink
                        to="/noticias"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        Noticias
                    </NavLink>
                    <NavLink
                        to="/actividades"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        Eventos y actividades
                    </NavLink>
                </div>
                <div className="tw-p-5 tw-w-1/2 sm:tw-w-4/12 md:tw-w-3/12">
                    <div className="tw-text-xs tw-uppercase tw-text-gray-400 tw-font-medium tw-mb-6">
                        Sobre Nosotros
                    </div>
                    <NavLink
                        to="/sobre-nosotros"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        ¿Quienes somos?
                    </NavLink>
                    
                    <NavLink
                        to="/contacto"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        Contacto
                    </NavLink>
                </div>
                <div className="tw-p-5 tw-w-1/2 sm:tw-w-4/12 md:tw-w-3/12">
                    <div className="tw-text-xs tw-uppercase tw-text-gray-400 tw-font-medium tw-mb-6">
                        Comunidad
                    </div>
                    <NavLink
                        to="/buscar-profesional"
                        className="tw-my-3 tw-block tw-text-gray-300 hover:tw-text-gray-100 tw-text-sm tw-font-medium tw-duration-700"
                    >
                        Encuentra un profesional
                    </NavLink>
                </div>
            </div>
            <div className="tw-pt-2">
                <div
                    className="tw-flex tw-pb-5 tw-px-3 tw-m-auto tw-pt-5 
                                tw-border-t tw-border-gray-500 tw-text-gray-400 tw-text-sm 
                                tw-flex-col md:tw-flex-row tw-max-w-6xl"
                >
                    <div className="tw-mt-2">
                        &copy; Copyright {new Date().getFullYear()} - COLEGIO DE
                            PSICOPEDAGOGOS DE CHILE, TODOS LOS DERECHOS RESERVADOS.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;