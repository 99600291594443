import React from 'react'
import Loader from '../../atoms/Loader';
import NonContent from '../../atoms/NonContent';
import SectionRow from '../../molecules/cards/SectionRow';

const SectionsTable = ({sections, filterSection}) => {
    return (
        <>
            {
                sections === null ? (
                    <Loader />
                ) : (
                    sections.length === 0 ? (
                        <NonContent />
                    ) : (
                        sections.map((section) => (
                            <SectionRow
                                key = {section.id}
                                id = {section.id}
                                nombre = {section.nombre} 
                                filterSection={filterSection}
                            />
                        ))
                    )
                )
            }
        </>
    );
};

export default SectionsTable;
