import React from 'react';

import LogoImage from '../../../assets/images/logoPsp.png';

const UserPspCard = ({imagen_perfil, nombre, apellido_paterno, region, comuna, email, cargo}) => {
    
    return (
        <div className="tw-w-80 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden tw-my-4">
            <div className="tw-flex tw-flex-col tw-relative">
                <img className="tw-w-full tw-h-72 tw-object-cover tw-object-center" src={imagen_perfil} alt="avatar" />
                <div className="tw-flex tw-absolute tw-bottom-0 tw-w-full tw-items-center tw-px-6 tw-py-1 tw-bg-gray-700 tw-bg-opacity-70">
                    <img src={LogoImage} className="tw-object-cover tw-w-16 tw-h-16" alt="Logo psp"/>
                    <h1 className="tw-mx-3 tw-text-white tw-font-semibold tw-text-lg">{cargo}</h1>
                </div>
            </div>
            <div className="tw-py-4 tw-px-6">
                <h1 className="tw-text-2xl tw-font-semibold tw-text-gray-800">{nombre} {apellido_paterno}</h1>
                
                <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-700">
                    <svg className="tw-h-6 tw-w-6 tw-fill-current" viewBox="0 0 512 512">
                        <path d="M256 32c-88.004 0-160 70.557-160 156.801C96 306.4 256 480 256 480s160-173.6 160-291.199C416 102.557 344.004 32 256 32zm0 212.801c-31.996 0-57.144-24.645-57.144-56 0-31.357 25.147-56 57.144-56s57.144 24.643 57.144 56c0 31.355-25.148 56-57.144 56z" />
                    </svg>
                    <h1 className="tw-px-2 tw-text-sm">{region}, {comuna}</h1>
                </div>
                <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-700">
                    <svg className="tw-h-6 tw-w-6 tw-fill-current" viewBox="0 0 512 512">
                        <path d="M437.332 80H74.668C51.199 80 32 99.198 32 122.667v266.666C32 412.802 51.199 432 74.668 432h362.664C460.801 432 480 412.802 480 389.333V122.667C480 99.198 460.801 80 437.332 80zM432 170.667L256 288 80 170.667V128l176 117.333L432 128v42.667z" />
                    </svg>
                    <h1 className="tw-px-2 tw-text-sm">{email}</h1>
                </div>
            </div>
        </div>
    );
};

export default UserPspCard;
