import React, {useState, useEffect} from 'react';
import axios from "axios";
// import AddMoreImages from '../molecules/adminContent/AddMoreImages';
import UpdateEditor from '../molecules/adminContent/UpdateEditor';
// import UpdateImages from '../molecules/adminContent/UpdateImages';
import UpdateMainImage from '../molecules/adminContent/UpdateMainImage';
import UpdateTitleDescription from '../molecules/adminContent/UpdateTitleDescription';
import Loader from '../atoms/Loader';
import { Redirect } from 'react-router';

const UpdateContent = ({typeContent, titleContent, redirectTo}) => {

    const [contentSelected, setContentSelected] = useState(null);
    const [redirect, setRedirect] = useState(false);

    const [unlockEditor, setUnlockEditor] = useState(false);

    useEffect(() => {
        
        const getContent = async (content) => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/notices/${content}`;
                if(typeContent === "2"){
                    url = `api/jobs/${content}`;
                }
                if(typeContent === "3"){
                    url = `api/activities/${content}`;
                }
                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    return false;
                }
                // Como es una data, entonces es el contenido requerido
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getContent(`${titleContent}`).then((response) => {
            // console.log(response);
            if (!response) {
                setContentSelected(false);
            } else {
                setContentSelected(response);
            }
        });
    }, [titleContent, typeContent]);

    return (
        <div className="tw-bg-gray-600 tw-m-2 tw-rounded-md tw-p-2">
            {
                redirect ? (
                    (typeContent === "2" || typeContent === "3") ? (
                        <Redirect to={`/${redirectTo}`} />
                    ) : (
                        <Redirect to={`/${redirectTo}/${contentSelected.url}`} />
                    )
                ) : (
                    contentSelected === null ? <Loader /> : (
                        <>
                            <h1 className="tw-text-white tw-text-3xl tw-font-bold tw-text-center">
                                Editar contenido
                            </h1> 
                            <div className="tw-flex tw-flex-col lg:tw-flex-row">
                                <div className="tw-w-full lg:tw-w-3/4 tw-p-1">
                                    <UpdateTitleDescription 
                                        typeContent={typeContent} 
                                        contentSelected = {contentSelected}
                                        setContentSelected = {setContentSelected}
                                    />
                                </div>
                                <div className="tw-w-full lg:tw-w-1/4 tw-p-1">
                                    <UpdateMainImage 
                                        typeContent={typeContent} 
                                        contentSelected = {contentSelected} 
                                        setContentSelected = {setContentSelected} 
                                        />
                                </div>
                            </div>
                            <div className="tw-flex tw-w-full tw-mt-4">
                                {
                                    unlockEditor  ? (null) : (
                                        typeContent === "3" ? (null) : (
                                            <button
                                                type="button"
                                                className="tw-text-md tw-font-semibold tw-bg-yellow-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-yellow-700 focu:tw-outline-none"
                                                onClick={() => {setUnlockEditor(true)}}
                                            >
                                                Editar texto
                                            </button>
                                        )
                                    )
                                }
                                <button
                                    type="button"
                                    className="tw-ml-4 tw-text-md tw-font-semibold tw-bg-red-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-red-700 focu:tw-outline-none"
                                    onClick={() => {setRedirect(true)}}
                                >
                                    Volver a la página anterior
                                </button>
                            </div>
                            <div className="tw-w-full">
                                {
                                    unlockEditor ? (
                                        <UpdateEditor typeContent={typeContent} 
                                            setUnlockEditor = {setUnlockEditor}
                                            contentSelected = {contentSelected} 
                                            setContentSelected = {setContentSelected}
                                            />
                                    ) : (null)
                                }
                            </div>
                        </>
                    )
                )
            }
        </div>
    );
};

export default UpdateContent;