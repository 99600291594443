/**
 * Librerias de react o terceros
 */
import React, { useContext, useState } from "react";
import { Formik } from "formik";
import axios from "axios";

/**
 * Contextos
 */
import { AppContext } from "../../context/AppContext";
import { UserContext } from "../../context/UserContext";

/**
 * Componentes
 */
import AddContentForm from "../molecules/forms/AddContentForm";
import Loader from "../atoms/Loader";
import { Redirect } from "react-router";

const validationNotice = (values) => {
    let errors = {};
    //validacion titulo 
    if (!values.title) {
        errors.title = "Ingrese el título!";
    } else if (values.title.length < 10 || values.title.length > 100) {
        errors.title = "Título demasiado corto o demasiado largo!";
    }
    // validacion descripcion 
    if (!values.description) {
        errors.description = "Ingrese una descripción!";
    } else if (
        values.description.length < 10 ||
        values.description.length > 400
    ) {
        errors.description = "Descripción demasiado corta o demasiado extensa!";
    }
    return errors;
};
const validationJob = (values) => {
    let errors = {};

    if (!values.title) {
        errors.title = "Ingrese el título!";
    } else if (values.title.length < 10 || values.title.length > 100) {
        errors.title = "Título demasiado corto o demasiado largo!";
    }
    
    if (!values.location) {
        errors.location = "Ingrese una ubicación!";
    } else if (
        values.location.length < 10 ||
        values.location.length > 60
    ) {
        errors.location = "Ubicación demasiado corta o demasiado extensa!";
    }

    if (!values.contact) {
        errors.contact = "Ingrese un contácto!";
    } else if (
        values.contact.length < 10 ||
        values.contact.length > 60
    ) {
        errors.contact = "Contácto demasiado corto o demasiado extenso!";
    }

    if (values.phone.length > 20) {
        errors.phone = "Teléfono muy extenso!";
    }
    return errors;
};

const AddContent = ({typeContent, titleContent, redirectTo}) => {
    //contextos
    const appContext = useContext(AppContext);
    const userContext = useContext(UserContext);
    //propiedades a usar de cada contexto
    const { handleAlertMessage, setAlertRender } = appContext;
    const { userLogged } = userContext;


    // Estado que maneja si se debe mostrar el formulario o no
    const [sending, setSending] = useState(false);
    // Estado que maneja si hay que redireccionar o no
    const [redirect, setRedirect] = useState(false);
    // Estado que recibe la imagen principal del contenido
    const [mainImage, setMainImagen] = useState(null);
    // Estado que maneja la vista previa de la imagen principal del contenido
    const [mainImagePrevew, setMainImagePrevew] = useState(null);
    // Estado de maneja el contenido que se ha escrito en el editor
    const [editorState, setEditorState] = useState(null);


    //Cambios en el editor
    const handleChangeEditor = (content) => {
        // console.log("OnChange: ", content);
        setEditorState(content);
    };

    //Cambios en la imagen principal
    function handleMainImage(e) {
        // Maximo tamaño de los archivos a subir
        // 2MB por cada uno
        const maxAllowedSize = 2 * 1024 * 1024;
        if (e.target.files[0]) {
            //comprobamos que sea menor al tamaño permitido y que sea del tipo imagen
            if (
                e.target.files[0].size > maxAllowedSize ||
                !/image\/*/.test(e.target.files[0].type)
            ) {
                alert(
                    "El tamaño del archivo seleccionado sobrepasa el limite(2 MB) o no es un formato válido."
                );
                //limpiamos el archivo seleccionado
                e.target.value = "";
                setMainImagen(null);
                setMainImagePrevew(null);
            } else {
                setMainImagen(e.target.files[0]);
                setMainImagePrevew(URL.createObjectURL(e.target.files[0]));
            }
        } else {
            setMainImagen(null);
            setMainImagePrevew(null);
        }
    }

    //Enviar formulario de creacion
    function sendContentNoticesToCreate(values) {
        setSending(true);

        //Creamos el contenido que se enviara a la API
        let formData = new FormData();
        formData.append("titulo", values.title);
        formData.append("descripcion", values.description);
        formData.append("imagen_principal", mainImage);
        formData.append("autor_id", userLogged.id);
        formData.append("contenido", editorState);

        const createContent = async (content) => {
            try {

                let response;
                if(typeContent === "1"){
                    response = await axios.post("api/notices", content);
                }else if (typeContent === "3"){
                    response = await axios.post("api/activities", content);
                }
                
                if (response.data.message) {
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        response.data.title,
                        response.data.message,
                        response.data.type
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
                    return true;
                } 
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        createContent(formData).then((response) => {
            setMainImagePrevew(null);
            if (response) {
                setRedirect(true);
            } else {
                // console.log("hubo algun error?.");
            }
            setSending(false);
        });
    }

    function sendContentJobsToCreate(values) {
        setSending(true);
        let formData = new FormData();
        formData.append("titulo", values.title);
        formData.append("tipo", values.type);
        formData.append("ubicacion", values.location);
        formData.append("contacto", values.contact);
        formData.append("telefono", values.phone);
        formData.append("imagen_principal", mainImage);
        formData.append("autor_id", userLogged.id);
        formData.append("contenido", editorState);

        const createContent = async (content) => {
            try {

                let response;
                
                response = await axios.post("api/jobs", content);
                
                if (response.data.message) {
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        response.data.title,
                        response.data.message,
                        response.data.type
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
                    return true;
                } 
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        createContent(formData).then((response) => {
            setMainImagePrevew(null);
            if (response) {
                setRedirect(true);
            } else {
                // console.log("hubo algun error?.");
            }
            setSending(false);
        });
    }

    return (
        <div className="tw-flex tw-w-full tw-flex-col tw-items-end tw-justify-end tw-p-2 tw-mb-2">
            {
                redirect ? (
                    <Redirect to={`/${redirectTo}`} />
                ) : (
                    sending ? (
                        <Loader />
                    ) : (
                        (typeContent === "1" || typeContent === "3") ? (
                            <Formik
                                initialValues={{
                                    title: "",
                                    description: "",
                                }}
                                onSubmit={(values, actions) => {
                                    if (mainImage === null) {
                                        alert(
                                            "Debes ingresar una imagen principal para poder crear el contenido."
                                        );
                                    } else {
                                        actions.setSubmitting(false);
                                        actions.resetForm(true);
                                        sendContentNoticesToCreate(values);
                                    }
                                }}
                                validate={validationNotice}
                            >
                                {(props) => (
                                    <AddContentForm
                                        {...props}

                                        typeContent={typeContent}
                                        titleContent={titleContent}

                                        mainImagePrevew={mainImagePrevew}
                                        handleMainImage={handleMainImage}

                                        defaultValueEditor={editorState}
                                        handleChangeEditor={handleChangeEditor}

                                        setRedirect={setRedirect}
                                    />
                                )}
                            </Formik>
                        ) : (
                            typeContent === "2" ? (
                                <Formik
                                    initialValues={{
                                        
                                        title : "",
                                        type : "full-time",
                                        location: "",
                                        contact: "",
                                        phone : ""
                                        
                                    }}
                                    onSubmit={(values, actions) => {
                                        if (mainImage === null) {
                                            alert(
                                                "Debes ingresar una imagen principal para poder crear el contenido."
                                            );
                                        } else {
                                            actions.setSubmitting(false);
                                            actions.resetForm(true);
                                            sendContentJobsToCreate(values);
                                        }
                                    }}
                                    validate={validationJob}
                                >
                                    {(props) => (
                                        <AddContentForm
                                            {...props}

                                            typeContent={typeContent}
                                            titleContent={titleContent}

                                            mainImagePrevew={mainImagePrevew}
                                            handleMainImage={handleMainImage}

                                            defaultValueEditor={editorState}
                                            handleChangeEditor={handleChangeEditor}

                                            setRedirect={setRedirect}
                                        />
                                    )}
                                </Formik>
                            ) : (
                                null
                            )
                        )
                    )
                )
            }
        </div>
    );
};

export default AddContent;