import React from "react";
import { NavLink } from "react-router-dom";


const AddContentButton = ({typeContent, redirectTo, nameContent, titleContent}) => {
    return (
        <div className="tw-flex tw-w-full tw-flex-col tw-items-end tw-justify-end tw-py-2 tw-pr-2 tw-mb-2 tw-border-t-2 tw-border-b-2 tw-border-red-500">
            <NavLink to={`/administrar-contenido/${typeContent}/${redirectTo}/${titleContent}/new`}
                className="tw-bg-yellow-500 hover:tw-text-blueOil tw-shadow-xl tw-rounded-lg tw-p-2 tw-text-white tw-text-md tw-font-medium"
            >
                Agregar {nameContent}
            </NavLink>
        </div>
    ); 
};

export default AddContentButton;
