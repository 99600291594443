import React from 'react';
import LogoImage from '../../../assets/images/logoPsp.png';

const Logo = () => {
    return (
        <div>
            <img src={LogoImage} className="tw-object-cover tw-w-20 lg:tw-w-32 lg:tw-h-32" alt="Logo psp"/>
        </div>
    );
};

export default Logo;
