import React from "react";
/**
 * Componentes
 */
import InputFieldText from "../../atoms/InputFieldText";
import InputFieldTextArea from "../../atoms/InputFieldTextArea";
import SelectImage from "../../atoms/adminContents/SelectImage";
import Editor from "../../atoms/adminContents/Editor";
import EditorOnlyText from "../../atoms/adminContents/EditorOnlyText";
import { Field } from "formik";
import { FaTimes } from "react-icons/fa";
const AddContentForm = ({
  handleChange,
  handleSubmit,
  isSubmitting,
  resetForm,
  values,
  errors,
  touched,

  typeContent,
  titleContent,
  mainImagePrevew,
  handleMainImage,
  defaultValueEditor,
  handleChangeEditor,

  setRedirect,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="tw-w-full"
      encType="multipart/form-data"
    >
      <div className="tw-w-full tw-mt-2 tw-py-3 tw-rounded-md tw-bg-gray-600 tw-relative">
        <button
          type="button"
          className="tw-absolute tw-top-2 tw-right-2"
          onClick={() => {
            setRedirect(true);
          }}
        >
          <FaTimes className="tw-text-white tw-text-3xl" />
        </button>

        <h1 className="tw-text-white tw-text-3xl tw-font-bold tw-text-center">
          {titleContent.replaceAll("-", " ")}
        </h1>
        {typeContent === "1" || typeContent === "3" ? (
          <>
            <InputFieldText
              classContainer="tw-w-full tw-px-3 tw-my-2"
              classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
              classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
              classError="tw-text-white"
              name="title"
              type="text"
              label="Título"
              placeholder="Soy el título . . ."
            />
            <InputFieldTextArea
              classContainer="tw-w-full tw-px-3"
              classLabel="tw-block tw-text-white tw-text-lg tw-font-bold tw-mr-2"
              classTextArea="tw-w-full tw-rounded-md tw-resize-none tw-h-36 tw-rounded tw-p-2 focus:tw-outline-none"
              classError="tw-text-white"
              name="description"
              label="Descripción"
              placeholder="Máximo 400 caracteres . . ."
            />
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-space-y-1 md:tw-space-y-0  tw-items-center tw-px-3 tw-mt-2">
              <SelectImage
                mainImagePrevew={mainImagePrevew}
                handleMainImage={handleMainImage}
              />
            </div>
            {typeContent === "1" ? (
              <Editor
                defaultValueEditor={defaultValueEditor}
                handleChangeEditor={handleChangeEditor}
              />
            ) : null}
          </>
        ) : typeContent === "2" ? (
          <div className="">
            <InputFieldText
              classContainer="tw-w-full tw-px-3 tw-my-2"
              classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
              classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
              classError="tw-text-white"
              name="title"
              type="text"
              label="Título"
              placeholder="Soy el título . . ."
            />
            <div className="tw-w-full lg:tw-w-1/3 tw-px-3">
              <label className="tw-text-white tw-text-xl">Tipo de oferta</label>
              <Field
                as="select"
                name="type"
                className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"
              >
                <option value="Full-time">Full time</option>
                <option value="Part-time">Part time</option>
              </Field>
            </div>
            <InputFieldText
              classContainer="tw-w-full tw-px-3 tw-my-2"
              classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
              classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
              classError="tw-text-white"
              name="location"
              type="text"
              label="Ubicación"
              placeholder="Soy el ubicación . . ."
            />
            <InputFieldText
              classContainer="tw-w-full tw-px-3 tw-my-2"
              classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
              classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
              classError="tw-text-white"
              name="contact"
              type="email"
              label="Contácto"
              placeholder="Soy el email de contácto . . ."
            />
            <InputFieldText
              classContainer="tw-w-full tw-px-3 tw-my-2"
              classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
              classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
              classError="tw-text-white"
              name="phone"
              type="text"
              label="Teléfono (Opcional)"
              placeholder="Soy el el teléfono . . ."
            />
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-space-y-1 md:tw-space-y-0  tw-items-center tw-px-3 tw-mt-2">
              <SelectImage
                mainImagePrevew={mainImagePrevew}
                handleMainImage={handleMainImage}
              />
            </div>
            <EditorOnlyText
              defaultValueEditor={defaultValueEditor}
              handleChangeEditor={handleChangeEditor}
            />
          </div>
        ) : null}
        <div className="tw-flex tw-mt-6 tw-mx-auto tw-items-center">
          <button
            type="submit"
            className="tw-mx-auto tw-text-lg tw-font-semibold tw-bg-yellow-500 tw-text-white tw-rounded-lg tw-h-12 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-yellow-700 focu:tw-outline-none"
          >
            Publicar
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddContentForm;
