/**
 * Librerias de react o terceros
 */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

/**
 * Componentes
 */
import Loader from "../atoms/Loader";
import NonContent from "../atoms/NonContent";
import NewsGrid from "../organisms/grids/NewsGrid";
import BannerSection from "../atoms/anims/BannerSection";
// import parser from "html-react-parser";
import ContentEditable from "react-contenteditable";

import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import UpdateContentButton from "../atoms/buttons/UpdateContentButton";
import DeleteContentButton from "../atoms/buttons/DeleteContentButton";
import { UserContext } from "../../context/UserContext";
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
dayjs.locale("es");
dayjs.extend(relativeTime);

const NewSelected = ({ match }) => {

    const userContext = useContext(UserContext);
    const {userLogged, authStatus} = userContext;

    const [news, setNews] = useState(null);
    const [newsSelected, setNewsSelected] = useState(null);

    useEffect(() => {
        const getNews = async () => {
            try {
                let url = `api/notices?sort_by=updated_at&desc&per_page=5`;
                const response = await axios.get(url);
                //comprobamos que tipo de respuesta no entrego la api
                //si fue un mensaje o los datos requeirdos
                // console.log(response);
                if (response.data.message) {
                    // console.log(
                    //     "Recibimos un mensaje en la solicitud de noticias"
                    // );
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };
        getNews().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            if (!response) {
                //array vacio, se mostrará sin contenido
                setNews([]);
            } else {
                //como hubo un contenido obtenemos las noticias
                setNews(response.data);
            }
        });
        const getNew = async (content) => {
            try {
                // console.log("requiriendo contenido a la api");
                const url = `api/notices/${content}`;
                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    // console.log(
                    //     "Recibimos un mensaje en la solicitud de noticias"
                    // );
                    return false;
                }
                // Como es una data, entonces es el contenido requerido
                return response.data;
            } catch (error) {
                console.log(error);
                return false;
            }
        };

        getNew(`${match.params.url}`).then((response) => {
            // console.log(response);
            if (!response) {
                setNewsSelected(false);
            } else {
                setNewsSelected(response);
            }
        });
    }, [match.params.url]);

    return (
        <>
            {
                newsSelected === null ? (
                    <Loader />
                ) : (
                    newsSelected === false ? (
                        <NonContent />
                    ) : (
                        <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-p-2 tw-mb-2">
                            <BannerSection
                                image={newsSelected.imagen_principal}
                                title={newsSelected.descripcion}
                                classTitle="tw-bottom-0 tw-w-full sm:tw-h-24 tw-py-2 tw-px-5 tw-text-lg tw-bg-blueOil"
                            />
                            {
                                authStatus === LOGGED_IN ? ( 
                                    userLogged.tipo_usuario === ADMIN ? (
                                        <div className="tw-fixed tw-right-4 tw-z-50 tw-flex tw-flex-col tw-space-y-2 tw-p-3">
                                            <UpdateContentButton typeContent={1} redirectTo="noticias" titleContent={newsSelected.url}/>
                                            <DeleteContentButton typeContent={1} redirectTo="noticias" titleContent={newsSelected.url} />
                                        </div>
                                    ) : (null)
                                ) : (null)
                            }
                            
                            <div className="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-center tw-justify-start tw-py-2 tw-pr-2 tw-my-2 tw-border-t tw-border-b tw-border-red-500">
                                <div className=" tw-flex tw-flex-row tw-items-center tw-ml-3 tw-w-full lg:tw-w-1/4">
                                    <img
                                        className="tw-object-cover tw-w-16 tw-h-16 tw-rounded-full"
                                        src={newsSelected.autor.imagen_perfil}
                                        alt="Autor"
                                    />
                                    <div>
                                        <p className="tw-ml-3 tw-text-gray-500 tw-font-mono tw-text-md">
                                            Autor:
                                        </p>
                                        <p className="tw-ml-3 tw-text-gray-500 tw-font-mono tw-text-lg">
                                            {newsSelected.autor.nombre} {newsSelected.autor.apellido_paterno}
                                        </p>
                                    </div>
                                </div>
                                <div className="tw-flex tw-flex-col tw-items-end tw-justify-center tw-w-full lg:tw-w-3/4">
                                    <h1 className="tw-text-xl md:tw-text-3xl tw-font-medium">
                                        {newsSelected.titulo}
                                    </h1>
                                    <p className="tw-text-md lg:tw-text-lg tw-font-semibold tw-text-gray-500 tw-ml-2">
                                        {dayjs(newsSelected.updated_at).fromNow()}
                                    </p>
                                </div>
                            </div>
        
                            <div className="tw-flex tw-flex-col xl:tw-flex-row tw-w-full tw-bg-white">
                                <div className="tw-w-full xl:tw-w-9/12 tw-px-2">
                                    {newsSelected.contenido ? (
                                        <div className="sun-editor-editable">
                                            <ContentEditable
                                                // innerRef={this.contentEditable}
                                                html={newsSelected.contenido} // innerHTML of the editable div
                                                disabled={true} // use true to disable editing
                                                // onChange={this.handleChange} // handle innerHTML change
                                                tagName="article" // Use a custom HTML tag (uses a div by default)
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <div className="tw-flex tw-flex-col tw-w-full xl:tw-w-3/12 tw-border">
                                    <h1 className="tw-mx-auto tw-font-medium tw-text-xl">
                                        Otras noticias
                                    </h1>
                                    <NewsGrid news={news} />
                                </div>
                            </div>
                        </div>
                    )
                ) 
            }
        </>
    );
};

export default NewSelected;
