import React from 'react';
import Loader from '../../atoms/Loader';
import NonContent from '../../atoms/NonContent';
import DocumentCard from '../../molecules/cards/DocumentCard';

const DocumentsTable = ({idSection, documents, deleteDocument}) => {
    return (
        <div className="tw-grid tw-grid-cols-1 tw-gap-2 tw-px-2 lg:tw-px-2">

            {
                documents === null ? (
                    <Loader textColor="tw-text-white" />
                ) : (
                    documents.length === 0 ? (
                        <NonContent textColor="tw-text-white" />
                    ) : (
                        documents.map((document) => (
                            <DocumentCard key = {document.id} 
                                        id={document.id}
                                        nombre={document.nombre}
                                        peso={document.peso}
                                        path_file={document.path_file}
                                        autorNombre = {document.autor.nombre}
                                        autorApellido={document.autor.apellido_paterno}
                                        time={document.created_at}
                                        idSection={idSection} 
                                        deleteDocument={deleteDocument} 
                                        
                            />
                        ))
                    )
                )
            }
        </div>
    );
};

export default DocumentsTable;
