/**
 * Librerias de react o terceros
 */
import React from "react";

const SocialnetCard = () => {
    return (
        <div className="tw-relative tw-flex tw-items-top tw-justify-center  dark:tw-bg-gray-900 sm:tw-items-center sm:tw-pt-0">
            <div className="tw-max-w-6xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
                <div className="tw-overflow-hidden">
                    <div className="tw-grid tw-grid-cols-1">
                        <div className="tw-p-6 tw-mr-2  dark:tw-bg-gray-800 sm:tw-rounded-lg">
                            <h1 className="tw-text-4xl sm:tw-text-3xl tw-text-gray-800 dark:tw-text-white tw-font-extrabold tw-tracking-tight">
                                Redes Sociales
                            </h1>
                            <div className="tw-flex tw-items-center tw-mt-8 tw-text-gray-600 dark:tw-text-gray-400">
                                <a href="https://twitter.com/colegionacion14">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="tw-w-8 tw-h-8"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                    </svg>
                                </a>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    Colegio Nacional de Psicopedagogos Chile A.G
                                </div>
                            </div>

                            <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-600 dark:tw-text-gray-400">
                                <a href="https://www.facebook.com/groups/434837880022650">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="tw-h-8 tw-w-8"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
                                    </svg>
                                </a>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    Colegio Nacional de Psicopedagogos Chile A.G
                                </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-600 dark:tw-text-gray-400">
                                <a href="https://www.facebook.com/groups/1455719781335045">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="tw-h-8 tw-w-8"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
                                    </svg>
                                </a>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    Psicopedagogía Chile
                                </div>
                            </div>

                            <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-600 dark:tw-text-gray-400">
                                <a href="https://www.youtube.com/channel/UCJtSysUZ4lw4g02BoxcnGWw">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="tw-w-8 tw-h-8"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M10.918 13.933h.706v3.795h-.706v-.419c-.13.154-.266.272-.405.353-.381.218-.902.213-.902-.557v-3.172h.705v2.909c0 .153.037.256.188.256.138 0 .329-.176.415-.284v-2.881zm.642-4.181c.2 0 .311-.16.311-.377v-1.854c0-.223-.098-.38-.324-.38-.208 0-.309.161-.309.38v1.854c-.001.21.117.377.322.377zm-1.941 2.831h-2.439v.747h.823v4.398h.795v-4.398h.821v-.747zm4.721 2.253v2.105c0 .47-.176.834-.645.834-.259 0-.474-.094-.671-.34v.292h-.712v-5.145h.712v1.656c.16-.194.375-.354.628-.354.517.001.688.437.688.952zm-.727.043c0-.128-.024-.225-.075-.292-.086-.113-.244-.125-.367-.062l-.146.116v2.365l.167.134c.115.058.283.062.361-.039.04-.054.061-.141.061-.262v-1.96zm10.387-2.879c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-10.746-2.251c0 .394.12.712.519.712.224 0 .534-.117.855-.498v.44h.741v-3.986h-.741v3.025c-.09.113-.291.299-.436.299-.159 0-.197-.108-.197-.269v-3.055h-.741v3.332zm-2.779-2.294v1.954c0 .703.367 1.068 1.085 1.068.597 0 1.065-.399 1.065-1.068v-1.954c0-.624-.465-1.071-1.065-1.071-.652 0-1.085.432-1.085 1.071zm-2.761-2.455l.993 3.211v2.191h.835v-2.191l.971-3.211h-.848l-.535 2.16-.575-2.16h-.841zm10.119 10.208c-.013-2.605-.204-3.602-1.848-3.714-1.518-.104-6.455-.103-7.971 0-1.642.112-1.835 1.104-1.848 3.714.013 2.606.204 3.602 1.848 3.715 1.516.103 6.453.103 7.971 0 1.643-.113 1.835-1.104 1.848-3.715zm-.885-.255v.966h-1.35v.716c0 .285.024.531.308.531.298 0 .315-.2.315-.531v-.264h.727v.285c0 .731-.313 1.174-1.057 1.174-.676 0-1.019-.491-1.019-1.174v-1.704c0-.659.435-1.116 1.071-1.116.678.001 1.005.431 1.005 1.117zm-.726-.007c0-.256-.054-.445-.309-.445-.261 0-.314.184-.314.445v.385h.623v-.385z" />
                                    </svg>
                                </a>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    Colegio Nacional de Psp
                                </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-600 dark:tw-text-gray-400">
                                <a href="https://www.instagram.com/colegionacionalpspchile/">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="tw-w-8 tw-h-8"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
                                    </svg>
                                </a>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    @colegionacionalpspchile
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialnetCard;