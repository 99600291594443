import React, {useState} from 'react'

const EditPhotoForm = ({changePhoto, cancelEditPhoto}) => {
    
    // Estado que recibe la imagen principal del contenido
    const [mainImage, setMainImagen] = useState(null);
    // Estado que maneja la vista previa de la imagen principal del contenido
    const [mainImagePrevew, setMainImagePrevew] = useState(null);

    //Cambios en la imagen principal
    function handleMainImage(e) {
        // Maximo tamaño de los archivos a subir
        // 2MB por cada uno
        const maxAllowedSize = 2 * 1024 * 1024;
        if (e.target.files[0]) {
            //comprobamos que sea menor al tamaño permitido y que sea del tipo imagen
            if (
                e.target.files[0].size > maxAllowedSize ||
                !/image\/*/.test(e.target.files[0].type)
            ) {
                alert( 
                    "El tamaño del archivo seleccionado sobrepasa el limite(2 MB) o no es un formato válido."
                );
                //limpiamos el archivo seleccionado
                e.target.value = "";
                setMainImagen(null);
                setMainImagePrevew(null);
            } else {
                setMainImagen(e.target.files[0]);
                setMainImagePrevew(URL.createObjectURL(e.target.files[0]));
            }
        } else {
            setMainImagen(null);
            setMainImagePrevew(null);
        }
    }

    return (

        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-auto">
                
            <div className="tw-flex tw-items-center tw-border tw-border-dashed tw-my-2 tw-w-28 tw-h-24">
                {mainImagePrevew ? (
                    <img
                        className="tw-object-contain tw-h-16 tw-w-full"
                        src={mainImagePrevew}
                        alt="imagen principal del contenido"
                    />
                ) : null}
            </div>
            <label className="tw-w-full tw-flex tw-flex-col tw-items-center tw-px-3 tw-py-2 tw-bg-white tw-text-gray-700 tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-border-gray-700 tw-cursor-pointer hover:tw-bg-blue-500 hover:tw-text-white">
                <svg
                    className="tw-w-8 tw-h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="tw-mt-2 tw-text-base tw-leading-normal">
                    Seleccione una imagen
                </span>
                <input
                    accept="image/*"
                    type="file"
                    className="tw-hidden"
                    onChange={handleMainImage}
                />
            </label>
            <div className="tw-flex tw-flex-wrap tw-my-4 tw-justify-center tw-text-gray-200 tw-text-center">
                <button className = "tw-bg-red-600 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        type="button"
                        onClick = {cancelEditPhoto}
                >
                    Cancelar
                </button> 

                <button className="tw-bg-green-700 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        onClick = {() => {changePhoto(mainImage)}}
                >
                    Actualizar
                </button> 
            </div>
        </div>
    );
};

export default EditPhotoForm;
