import React, { useContext, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import { AppContext } from '../../../context/AppContext';
import LinearIndeterminate from '../../atoms/LinearIndeterminate';
const UploadDocument = ({idSection, getDocuments}) => {

    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;
    const userContext = useContext(UserContext);
    const {userLogged} = userContext;
    
    const [uploadingDocument, setUploadingDocument] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    //Cambios en la imagen principal
    function handleFile(e) {
        // Maximo tamaño de los archivos a subir
        // 2MB por cada uno
        const maxAllowedSize = 40 * 1024 * 1024;
        if (e.target.files[0]) {
            //comprobamos que sea menor al tamaño permitido y que sea del tipo imagen
            // console.log(e.target.files[0].type);
            // const fileName = e.target.files[0].name;
            // const fileName = e.target.files[0].name.split('.').pop();
            // const fileExt = fileName.split('.').pop();
            // pdf docx rar zip mp4 mp3 xlsx

            // archivos no permitidos
            // 
            
            // const notAllowedExtensions = /(,$/i; application/x-msdownload
            if (
                e.target.files[0].size > maxAllowedSize 
                || !/(image\/*|application\/*|audio\/*)/.test(e.target.files[0].type) 
                || /(application\/x-msdownload)/.test(e.target.files[0].type)

            ) {
                alert( 
                    "El tamaño del archivo seleccionado sobrepasa el limite(40 MB) o no es un formato válido."
                );
                //limpiamos el archivo seleccionado
                e.target.value = "";
            } else {
                uploadFile(e.target.files[0]);
            }
        } 
    }

    const uploadFile = (fileUpload) => {
        // console.log(fileUpload);
        
        let formData = new FormData();

        formData.append('document', fileUpload);
        formData.append('section_id', idSection);
        formData.append('autor_id', userLogged.id);

        const onUploadProgress = event => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            // const percentCompleted = (event.loaded * 100) / event.total;
            // console.log('onUploadProgress', percentCompleted);
            setProgressValue(percentCompleted);
        };

        setUploadingDocument(true);

        const uploadFile = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = 'api/documents';
                const response = await axios.post(url, formData, {onUploadProgress});
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    handleAlertMessage(response.data.title, response.data.message, response.data.type);
                    setAlertRender(true);
                }
                return true;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        uploadFile().then((response) => {
            setUploadingDocument(false);
            setProgressValue(0);
            getDocuments();
        });
    }

    return (
        <>
            {
                uploadingDocument ? (
                    <div className="tw-flex tw-items-center tw-w-48">
                        <div className="tw-w-10/12 tw-px-2">
                            <LinearIndeterminate />
                        </div>
                        <div className="tw-w-2/12">
                            <span className="tw-text-white tw-text-xl">
                                {progressValue}%
                            </span>
                            {/* <p className="tw-text-white tw-text-xl">100 %</p> */}
                        </div>
                    </div>
                ) : (   
                    <label className="tw-w-48 tw-flex tw-items-center tw-bg-blue-500 hover:tw-bg-blue-700 tw-p-3 tw-text-white tw-shadow-2xl tw-tracking-wide">
                        <span className="tw-mr-1">
                            Agregar documento
                        </span>
                        <svg
                            className="tw-w-8 tw-h-8"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <input
                            accept="aplication/*"
                            type="file"
                            className="tw-hidden"
                            onChange={handleFile}
                        />
                    </label>
                )
            }
        </>
    );
};

export default UploadDocument;
