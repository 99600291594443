import React, { useState } from 'react';
import Loader from '../atoms/Loader';

const DeleteDocument = ({idDocument, documentName, size, setShowForm, deleteDocument}) => {

    const [sending, setSending] = useState(false);

    const handleDocument = () => {
        deleteDocument(idDocument);
        setSending(true);
    }

    return (
        <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-text-white">
            {
                sending ? (<Loader />) : (
                    <>
                        <span className="tw-w-10/12 tw-break-words">
                            Desea borrar el documento {documentName} ({size}) ?
                        </span>
                        <div className="tw-flex lg:tw-block tw-w-2/12">
                            <button className="tw-bg-green-600 tw-rounded-md tw-shadow-xl tw-px-4 tw-py-1"
                                onClick={()=>{handleDocument()}}
                            >
                                Si
                            </button>
                            <button className="tw-bg-red-700 tw-rounded-md tw-shadow-xl tw-ml-4 tw-px-4 tw-py-1"
                                onClick={()=>{setShowForm(false)}}
                            >
                                No
                            </button>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default DeleteDocument;
