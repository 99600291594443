import React from 'react';
import BannerSection from '../atoms/anims/BannerSection';
import imgBannerDirectory from '../../assets/images/aboutus/directorioBanner2.jpg';
// import imgBanner from '../../assets/images/aboutus/organigrama5.jpg';
import imgOrg from '../../assets/images/aboutus/organigramaEstructura.png';
import image2 from "../../assets/images/aboutus/image2.jpeg";
import imagenFuncion from "../../assets/images/aboutus/imagenFuncion.jpg";
// import imagenVision from "../../assets/images/aboutus/image3.jpeg";
import imagenVision from "../../assets/images/aboutus/vision.jpg";
import imagenMision from "../../assets/images/aboutus/mision.jpg";


const AboutUs = () => {
    return (
        <div className="tw-mx-auto tw-w-full">
                {/* <h1 className="tw-text-3xl md:tw-text-4xl tw-my-5 tw-font-semibold tw-text-center tw-text-gray-700">Sobre nosotros</h1>                         */}

                <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-w-full tw-relative">
                    <div className="tw-bg-blue-200 tw-w-full lg:tw-w-1/3 tw-flex">
                        <img src={image2} alt="Imagen 2" className="tw-object-cover tw-w-full tw-z-0" />
                    </div>
                    <div className="tw-flex tw-w-full lg:tw-w-2/3">
                        <div className="tw-m-3 lg:tw-mx-16 tw-my-10 lg:tw-my-16">
                            <h1 className="tw-text-3xl tw-font-semibold tw-text-gray-600">Quiénes Somos</h1>
                            <p className="tw-text-justify tw-text-lg tw-mt-5 tw-mb-0 lg:tw-mb-6">
                                El Colegio Nacional de Psicopedagogos de Chile Asociación Gremial (CONAPSICOPCH) es una organización sin fines de lucro, apolítica y laica, con registro N° 610-8. Fue creado el 28 de abril de 2017 y es regulado por la Subsecretaría de Economía y Empresas, del Ministerio de Economía, Fomento y Turismo. Tiene como objetivo promover y velar por la racionalización, desarrollo, protección, perfeccionamiento, progreso y prerrogativas de la profesión del psicopedagogo y por regular el correcto ejercicio de la profesión por parte de sus asociados.
                            </p>
                        </div>
                    </div>
                    {/* Separador */}
                    <div className="tw-absolute tw-h-5 tw-top-0 tw-w-2/3 tw-left-0 tw-flex tw-justify-center">
                        <div className="img_item_izq tw-w-16 tw-h-5 tw-bg-green-300"></div>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-relative tw-bg-gray-200">
                    <div className="tw-h-full tw-w-full lg:tw-w-1/2">
                        <div className="tw-m-3 lg:tw-mx-16 tw-mt-10 lg:tw-mt-16">
                            <h1 className="tw-text-3xl tw-font-semibold tw-text-gray-600">¿Cuál es la función de un psicopedagogo?</h1>
                            <p className="tw-text-justify tw-text-lg tw-mt-5 tw-mb-0 lg:tw-mb-4">
                                Nuestra función es propiciar el desarrollo integral (biológico, psicológico, cultural, social y espiritual) del ser humano a través de acciones que intervengan y fortalezcan los procesos cognitivos y emocionales que sostienen el aprendizaje en todo ámbito y contexto sea cual sea la realidad de la cual parta. Esto, a través de la pesquisa preventiva, potenciación, habilitación, rehabilitación, fortalecimiento y educación de capacidades y habilidades, con el fin de generar estructuras sólidas que sustenten los conocimientos o saberes que han sido, son y serán adquiridos a lo largo de su vida.  El psicopedagogo es un profesional que trabaja en el área de prevención, diagnóstico y tratamiento de las dificultades de aprendizaje, en el sentido más amplio.
                            </p>
                            <p className="tw-text-justify tw-text-lg tw-mt-5 tw-mb-0 lg:tw-mb-4">
                                El psicopedagogo está preparado para generar, analizar, evaluar y planificar el desarrollo y la modificación de políticas institucionales que favorezcan la comunicación efectiva hacia el usuario y entre los trabajadores.
                            </p>
                        </div>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-center tw-w-full lg:tw-w-1/2 tw-bg-red-200">
                        <img src={imagenFuncion} alt="Imagen 2" className="" />
                    </div>
                    {/* Separador */}
                    <div className="tw-absolute tw-h-5 tw-top-0 tw-w-full tw-left-0 tw-flex tw-justify-center">
                        <div className="img_item_der tw-w-16 tw-h-5 tw-bg-blue-300"></div>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-w-full tw-relative">
                    <div className="tw-flex tw-items-center lg:tw-w-1/3 tw-bg-white">
                        <img src={imagenMision} alt="Imagen 2" className="tw-object-contain tw-mx-auto tw-z-0" />
                    </div>
                    <div className="tw-flex tw-w-full lg:tw-w-2/3 tw-relative">
                        <div className="tw-mx-4 lg:tw-ml-48 lg:tw-mr-20 tw-my-10 tw-mb-10">
                            <h1 className="tw-text-3xl tw-font-semibold tw-text-gray-600">Misión</h1>
                            <p className="tw-text-justify tw-text-lg tw-mt-5 tw-mb-0 lg:tw-mb-6">
                            "El Colegio Nacional de Psicopedagogos de Chile Asociación Gremial, tiene como misión promover,
                                representar, orientar y defender nuestro campo de acción, velando por los intereses del psicopedagogo y el cumplimiento de una adecuada labor profesional;
                            fomentando la formación continua y la participación de los asociados, logrando así el reconocimiento, 
                            la valoración y el reposicionamiento a nivel nacional de nuestra profesión en los ámbitos de la educación, la salud y socio - comunitario."
                            </p>
                        </div>
                    </div>
                    {/* Separador */}
                    <div className="tw-absolute tw-h-5 tw-top-0 tw-w-2/3 tw-left-0 tw-flex tw-justify-center">
                        <div className="img_item_izq tw-w-16 tw-h-5 tw-bg-red-300"></div>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-relative tw-bg-gray-200">
                    <div className="tw-h-full tw-w-full lg:tw-w-1/2">
                        <div className="tw-mx-4 lg:tw-ml-20 lg:tw-mr-20 tw-my-8">
                            <h1 className="tw-text-3xl tw-font-semibold tw-text-gray-600">Visión</h1>
                            <p className="tw-text-justify tw-text-lg tw-mt-5 tw-mb-0 lg:tw-mb-6">
                                "El Colegio Nacional de Psicopedagogos de Chile Asociación Gremial, se proyecta como una organización centrada en los intereses y
                                necesidades del profesional Psicopedagogo colegiado y de los estudiantes de la carrera de Psicopedagogía en Chile, preservando el campo laboral
                                y favoreciendo el adecuado ejercicio profesional, el trabajo colaborativo, la investigación y el perfeccionamiento a través de lazos nacionales
                                e internacionales que propicien mejoras significativas de la labor psicopedagógica.
                                Materializándose como una opción concreta y accesible a las familias y personas que requieran de su apoyo."
                            </p>
                        </div>
                    </div>
                    <div className="tw-flex tw-items-center tw-bg-white tw-w-full lg:tw-w-1/2">
                        <img src={imagenVision} alt="Imagen 2" className="tw-object-contain tw-mx-auto tw-z-0" />
                    </div>
                    {/* Separador */}
                    <div className="tw-absolute tw-h-5 tw-top-0 tw-w-full tw-left-0 tw-flex tw-justify-center">
                        <div className="img_item_der tw-w-16 tw-h-5 tw-bg-blue-300"></div>
                    </div>
                </div>


                <BannerSection image={imgBannerDirectory} title="Organigrama" classTitle="tw-bottom-10 lg:tw-bottom-20 tw-flex tw-h-2/5 tw-w-full tw-justify-center tw-items-center tw-text-3xl lg:tw-text-6xl tw-bg-gray-700" />
                
                {/* <DirectorySection/> */}
                {/* <BannerSection image={imgBanner} title="Organigrama" classTitle="tw-bottom-10 lg:tw-bottom-20 tw-flex tw-h-2/5 tw-w-full tw-justify-center tw-items-center tw-text-3xl lg:tw-text-6xl tw-bg-red-800" /> */}

                <div className="tw-flex tw-relative tw-mt-4">
                    <img className="tw-mx-auto tw-w-10/12" src={imgOrg} alt="asdas" ></img>
                </div>

               

            </div>
    );
};

export default AboutUs;
