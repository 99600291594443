import React, { useState } from 'react';
import axios from "axios";
import Loader from '../../atoms/Loader';
import NonContent from '../../atoms/NonContent';
import EditPhotoForm from '../forms/EditPhotoForm';

const UpdateMainImage = ({typeContent, contentSelected, setContentSelected}) => {

    
    const [unlock, setUnlock] = useState(false);
    const [sending, setSending] = useState(false);

    const changePhoto = (value) => {
        
        setSending(true);
        let formData = new FormData();

        formData.append('imagen_principal', value);
        const sendUpdatedPhoto = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let urlAxios = `api/notices/updatePhoto/${contentSelected.url}`;
                if(typeContent==="2"){
                    urlAxios = `api/jobs/updatePhoto/${contentSelected.url}`;
                }else if (typeContent==="3"){
                    urlAxios = `api/activities/updatePhoto/${contentSelected.url}`;
                }
                const response = await axios.post(urlAxios, formData);
                //Comprobamos si la respuesta es un mensaje
                // console.log(response);
                if (response.data.message) {
                    return false;
                }
                // Como es una data, entonces es el contenido requerido
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        }; 

        sendUpdatedPhoto().then((response) => {
            // console.log(response);
            if (!response) {
                setContentSelected(false);
            } else {
                setContentSelected(response);
            }
            setSending(false);
            setUnlock(false);
        });
    }

    return (
        <div className="tw-mt-2">
            {
                sending ? <Loader /> : (
                    contentSelected ? (
                        unlock ? (
                            <EditPhotoForm changePhoto = {changePhoto} cancelEditPhoto = {()=>{setUnlock(false)}} />
                        ) : (
                            <>
                                <h1 className="tw-text-white tw-text-xl tw-text-center">Imagen principal</h1>
                                <div className="tw-relative">
                                    <img src={contentSelected.imagen_principal} alt="Imagen principal noticia"/>
                                    {
                                        unlock ? (null) : (
                                            <div className="tw-absolute tw-flex tw-w-full tw-items-center tw-justify-center tw-bg-gray-800 tw-bg-opacity-50 tw-h-full tw-top-0">
                                                <button className="tw-bg-yellow-600 tw-border tw-border-white tw-text-white tw-p-3 tw-rounded-md"
                                                    onClick={() =>{setUnlock(true)}}
                                                >
                                                    Editar imagen principal
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        )
                    ) : ( <NonContent /> )
                )
            }
        </div>
    );

};

export default UpdateMainImage;