import React from 'react'
import InputFieldText from '../../atoms/InputFieldText';

const RegistryFormPart3 = ({
    handleChange, 
    handleSubmit, 
    isSubmitting, 
    resetForm, 
    values, 
    errors, 
    touched, 
    
    setCurrentForm
}) => {
    
    return (
        <form onSubmit={handleSubmit}> 
            <h1 className="tw-text-white tw-text-2xl tw-text-center">Información Academica</h1>
            <hr className="tw-w-1/2 tw-mx-auto tw-bg-white tw-h-px tw-rounded tw-mb-5"></hr>

            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/2 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="casa_estudio" 	type="text"  label="Casa de estudio" />
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/2 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="fecha_titulacion" type="date"  label="Fecha titulación" placeholder="AAAA/MM/DD" />
            </div>

            <div className="tw-flex tw-items-end tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-6/12 tw-px-3"classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="codigo_validacion_titulo" 	type="text"  label="Código validación título" />
            </div>

            <div className="tw-flex tw-items-center tw-justify-center lg:tw-justify-end tw-space-x-2 tw-mb-6 lg:tw-mr-10">
            
                <button type="button"
                        className="tw-text-lg tw-font-semibold tw-bg-yellow-400 hover:tw-bg-yellow-600 tw-text-white tw-rounded-lg tw-p-2 lg:tw-px-6 lg:tw-py-2 tw-block tw-shadow-xl"
                        onClick={()=>{setCurrentForm(2)}}
                >
                    Paso anterior
                </button>               
                <button type="submit"
                        className="tw-text-lg tw-font-semibold tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded-lg tw-p-2 lg:tw-px-6 lg:tw-py-3 tw-block tw-shadow-xl"
                >
                    Paso siguiente
                </button>               
                
            </div>
        </form>
    );
};

export default RegistryFormPart3;
