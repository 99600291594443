import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
const PriceCard = ({priceCardTitle, priceCardSubTitle, classTitle, classSubTitle, classContainer, otherHeader}) => {
    return (
        <div className={`${classContainer}`}>
            <div className="tw-shadow-inner tw-rounded-lg tw-bg-white tw-overflow-hidden">

                {/* Header only to middle price */}
                {otherHeader ? (<div className="tw-text-sm tw-leading-none tw-rounded-t-lg tw-bg-gray-200 tw-text-black tw-font-semibold tw-uppercase tw-py-4 tw-text-center tw-tracking-wide">
                                    Especial
                                </div>) : <div></div>
                }
                {/* header */}
                <div className="">

                    <h1 className={`${classTitle}`}>
                        {priceCardTitle}
                    </h1>

                    <h2 className={`${classSubTitle}`}>{priceCardSubTitle}</h2>
                    
                </div>
                {/* body */}
                <div className="tw-flex tw-flex-wrap tw-mt-3 tw-px-6">
                    <ul>
                        <li className="tw-flex tw-items-center">
                            <div className=" tw-rounded-full tw-p-2 tw-fill-current tw-text-green-700">
                                <svg className="tw-w-6 tw-h-6 tw-align-middle" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            
                            <span className="tw-text-gray-700 tw-text-lg tw-ml-3">Convenios</span>
                            
                        </li>

                        <li className="tw-flex tw-items-center">
                            <div className=" tw-rounded-full tw-p-2 tw-fill-current tw-text-green-700">
                                <svg className="tw-w-6 tw-h-6 tw-align-middle" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            
                            <span className="tw-text-gray-700 tw-text-lg tw-ml-3">Material de trabajo</span>
                        </li>
                        
                        <li className="tw-flex tw-items-center">
                            <div className=" tw-rounded-full tw-p-2 tw-fill-current tw-text-green-700">
                                <svg className="tw-w-6 tw-h-6 tw-align-middle" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            
                            <span className="tw-text-gray-700 tw-text-lg tw-ml-3">Apoyo</span>
                        </li>
                    </ul> 
                </div>  
                {/* footer */}
                
                <div className="tw-flex tw-items-center tw-p-8">
                    <Link to="/registro" 
                        className="
                                    hover:tw-no-underline 
                                    hover:tw-text-white 
                                    tw-mt-3 
                                    tw-text-lg 
                                    tw-text-center 
                                    tw-font-semibold 
                                    tw-bg-green-500 
                                    tw-w-full 
                                    tw-text-white 
                                    tw-rounded-lg 
                                    tw-px-6 
                                    tw-py-3 
                                    tw-block 
                                    tw-shadow-xl 
                                    hover:tw-bg-gray-700">
                        Afiliarme!
                    </Link>
                </div>

            </div>
        </div>
    )
}

PriceCard.propType = {
    priceCardTitle  : PropTypes.string,
    priceCardSubTitle  : PropTypes.string,
    classTitle : PropTypes.string,
    classSubTitle : PropTypes.string
}

PriceCard.defaultProps = {
    priceCardTitle : "Titulo",
    priceCardSubTitle  : "$0 /mes",
    classTitle : "tw-mt-2 tw-text-lg tw-font-medium tw-p-3 tw-pb-0 tw-text-center",
    classSubTitle : "tw-text-sm tw-text-gray-500 tw-text-center tw-pb-6"
}

export default PriceCard;
