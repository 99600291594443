import React from 'react'

const UserFullCard = ({user}) => {
    return (
        <div className="tw-w-full">
            <h1 className="tw-text-xl tw-text-white tw-font-semibold tw-text-center sm:tw-text-left">Información personal</h1>
            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-mb-2  tw-bg-white tw-mx-auto sm:tw-mx-0"></hr>
        
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-7/12 tw-px-3">
                    <span>Nombre:</span> <p className="tw-inline tw-text-green-400">{user.nombre} {user.apellido_paterno} {user.apellido_materno}</p>
                </div>
                <div className="tw-w-full sm:tw-w-5/12 tw-px-3">
                    <span>Rut:</span> <p className="tw-inline tw-text-green-400">{user.rut}</p>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-4/12 tw-px-3">
                    <span>Nacionalidad:</span> <p className="tw-inline tw-text-green-400">{user.nacionalidad}</p>
                </div>
                <div className="tw-w-full sm:tw-w-3/12 tw-px-3">
                    <span>Sexo:</span> <p className="tw-inline tw-text-green-400">{user.sexo}</p>
                </div>
                <div className="tw-w-full sm:tw-w-5/12 tw-px-3">
                    <span>Fecha de nacimiento:</span> <p className="tw-inline tw-text-green-400">{user.fecha_nacimiento}</p>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Teléfono:</span> <p className="tw-inline tw-text-green-400">{user.telefono}</p>
                </div>
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Celular:</span> <p className="tw-inline tw-text-green-400">{user.celular}</p>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Correo:</span> <p className="tw-inline tw-text-green-400">{user.email}</p>
                </div>
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Dirección:</span> <p className="tw-inline tw-text-green-400">{user.direccion}</p>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Cuidad:</span> <p className="tw-inline tw-text-green-400">{user.ciudad}</p>
                    
                </div>
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Comuna:</span> <p className="tw-inline tw-text-green-400">{user.comuna}</p>
                </div>
            </div>
            <div className="tw-w-full sm:tw-w-6/12 tw-px-3 tw-mb-1 tw-text-white tw-font-medium">
                <span>Región:</span> <p className="tw-inline tw-text-green-400">{user.region}</p>
            </div>
            
            <h1 className="tw-text-xl tw-text-white tw-font-semibold tw-text-center sm:tw-text-left">Información laboral</h1>
            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-mb-2  tw-bg-white tw-mx-auto sm:tw-mx-0"></hr>

            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Teléfono:</span> <p className="tw-inline tw-text-green-400">{user.telefono_laboral}</p>
                </div>
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Cargo:</span> <p className="tw-inline tw-text-green-400">{user.cargo}</p>
                </div>
            </div>

            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Dirección:</span> <p className="tw-inline tw-text-green-400">{user.direccion_laboral}</p>
                </div>
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Cuidad:</span> <p className="tw-inline tw-text-green-400">{user.ciudad_laboral}</p>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                    <span>Comuna:</span> <p className="tw-inline tw-text-green-400">{user.comuna_laboral}</p>
                </div>
            </div>
        
            <h1 className="tw-text-xl tw-font-semibold tw-text-white tw-text-center sm:tw-text-left">Información academica</h1>
            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-mb-2  tw-bg-white tw-mx-auto sm:tw-mx-0"></hr>

            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-7/12 tw-px-3">
                    <span>Casa de estudio:</span> <p className="tw-inline tw-text-green-400">{user.casa_estudio}</p>
                </div>
                <div className="tw-w-full sm:tw-w-5/12 tw-px-3">
                    <span>Fecha titulación:</span> <p className="tw-inline tw-text-green-400">{user.fecha_titulacion}</p>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full tw-px-3">
                    <span>Código validación título:</span> <p className="tw-inline tw-text-green-400">{user.codigo_validacion_titulo}</p>
                </div>
            </div>
        
            <h1 className="tw-text-xl tw-font-semibold tw-text-white tw-text-center sm:tw-text-left">Información de la cuenta</h1>
            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-bg-white tw-mb-2 tw-mx-auto sm:tw-mx-0"></hr>

            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full sm:tw-w-1/3 tw-px-3">
                    <span>Estado de la cuenta:</span> <p className="tw-inline tw-text-green-400">{user.estado}</p>
                </div>
                <div className="tw-w-full sm:tw-w-1/3 tw-px-3">
                    <span>Tipo de afiliación:</span> <p className="tw-inline tw-text-green-400">{user.tipo_afiliacion}</p>
                </div>
                <div className="tw-w-full sm:tw-w-1/3 tw-px-3">
                    <span>Tipo de cuenta:</span> <p className="tw-inline tw-text-green-400">{user.tipo_usuario}</p>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                <div className="tw-w-full tw-px-3">
                    <span>Mostrarse en los resultados de buscar profesional?:</span> <p className="tw-inline tw-text-green-400">{user.buscador === "true" ? 'Si aparecer' : 'No aparecer'}</p>
                </div>
            </div>
        </div>
    )
}

export default UserFullCard;