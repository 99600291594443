/**
 * Librerias de react o terceros
 */
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { LOGGED_IN } from "../../../constants/AuthStatus";
import { UserContext } from "../../../context/UserContext";

const Navbar = () => {
    const userContext = useContext(UserContext);
    const {authStatus} = userContext;
    const [activado, setActivado] = React.useState(false);

    const activarButton = () => {
        setActivado(!activado);
    };

    return (
        <nav className="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-py-2 tw-border-b fondo_login">
            <div className="tw-flex tw-mx-auto lg:tw-hidden">
                <button
                    onClick={() => activarButton()}
                    className="tw-flex focus:tw-outline-none tw-px-3 tw-py-2 tw-border tw-rounded tw-text-green-200 tw-border-green-400 hover:tw-text-white hover:tw-border-white"
                >
                    <svg
                        className="tw-fill-current tw-h-3 tw-w-3"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                </button>
            </div>
            <div 
                className={
                    !activado
                        ? "tw-w-full tw-block tw-flex-grow lg:tw-flex lg:tw-items-center lg:tw-w-auto lg:tw-text-center tw-hidden"
                        : "tw-w-full tw-block tw-flex-grow lg:tw-flex lg:tw-items-center lg:tw-w-auto lg:tw-text-center"
                }
            >
                <div className="tw-text-sm lg:tw-flex-grow tw-ml-4">
                    <NavLink
                        exact
                        to="/"
                        activeStyle={{ color: "#279a99" }}
                        className="tw-block tw-font-bold tw-mt-4 tw-text-base lg:tw-inline-block lg:tw-mt-0 tw-text-gray-50 hover:tw-text-gray-500 tw-mr-10"
                    >
                        Inicio
                    </NavLink>
                    <NavLink
                        exact
                        to="/sobre-nosotros"
                        activeStyle={{ color: "#279a99" }}
                        className="tw-block tw-font-bold tw-mt-4 tw-text-base lg:tw-inline-block lg:tw-mt-0 tw-text-gray-50 hover:tw-text-gray-500 tw-mr-10"
                    >
                        Sobre nosotros
                    </NavLink>
                    <NavLink
                        exact
                        to="/noticias"
                        activeStyle={{ color: "#279a99" }}
                        className="tw-block tw-font-bold tw-mt-4 tw-text-base lg:tw-inline-block lg:tw-mt-0 tw-text-gray-50 hover:tw-text-gray-500 tw-mr-10"
                    >
                        Noticias
                    </NavLink>
                    <NavLink
                        exact
                        to="/buscar-profesional"
                        activeStyle={{ color: "#279a99" }}
                        className="tw-block tw-font-bold tw-mt-4 tw-text-base lg:tw-inline-block lg:tw-mt-0 tw-text-gray-50 hover:tw-text-gray-500 tw-mr-10"
                    >
                        Buscar profesional
                    </NavLink>
                    <NavLink
                        exact
                        to="/eventos-actividades"
                        activeStyle={{ color: "#279a99" }}
                        className="tw-block tw-font-bold tw-mt-4 tw-text-base lg:tw-inline-block lg:tw-mt-0 tw-text-gray-50 hover:tw-text-gray-500 tw-mr-10"
                    >
                        Actividades
                    </NavLink>
                    <NavLink
                        exact
                        to="/contacto"
                        activeStyle={{ color: "#279a99" }}
                        className="tw-block tw-font-bold tw-mt-4 tw-text-base lg:tw-inline-block lg:tw-mt-0 tw-text-gray-50 hover:tw-text-gray-500 tw-mr-10"
                    >
                        Contacto
                    </NavLink>

                    {
                        authStatus === LOGGED_IN ? null : (
                            <NavLink
                                exact
                                to="/login"
                                activeStyle={{ color: "#279a99" }}
                                className="tw-block tw-font-bold tw-mt-4 tw-text-base lg:tw-inline-block lg:tw-mt-0 tw-text-gray-50 hover:tw-text-gray-500 tw-mr-10"
                            >
                                Ingresar
                            </NavLink>
                        )
                    }
                    
                </div>
            </div>
        </nav>
    );
};

export default Navbar;