import React from 'react';
import TeamWork from '../../../assets/images/teamWork.jpg';
const PartOfOurWork = () => {
    return (
        <div className="tw-w-11/12 tw-mx-auto tw-my-8">

            <h1 className="tw-text-center tw-text-5xl tw-font-medium tw-mb-2">Parte de nuestro trabajo</h1>
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center">
                <div className="tw-text-center tw-px-4 tw-py-2 tw-m-2 lg:tw-w-4/12">
                    <img className="tw-transform tw-rotate-90 lg:tw-rotate-0" src={TeamWork} alt="team"/>
                </div>
                <div className="lg:tw-w-8/12">
                    <div className="">

                        <h1 className="tw-text-3xl tw-font-medium tw-my-2">Ministerio de Educación</h1>
                        <ul className="tw-text-lg tw-text-justify tw-list-disc tw-px-5">
                            <li>Trabajo con la mesa técnica para la modificación del DFL 170/09.</li>
                            <li>Solicitud de oficios y ordinarios aclaratorios para evitar actos de discriminación y segregación laboral.</li>
                            <li>Trabajo para recepción de beneficios económicos como bonos y capacitaciones por el CPEIP.</li>
                            <li>Reconocimiento como profesional que interviene desde la educación parvularia y/o preescolar hasta la educación superior y en todas las modalidades de educación especial.</li>
                            <li>Trabajo colaborativo en la realización del Plan Paso a Paso y Retorno Seguro.</li>
                            <li>Presentación de iniciativas para la retención escolar.</li>
                        </ul>
                    </div>

                    <div className="">
                        <h1 className="tw-text-3xl tw-font-medium tw-my-2">Ministerio de Salud</h1>
                        <ul className="tw-text-lg tw-text-justify tw-list-disc tw-px-5">
                            <li>Reconocimiento del psicopedagogo/a como profesional del área de la salud.</li>
                            <li>Ingreso del psicopedagogo al Código Sanitario.</li>
                            <li>Incorporación como prestadores de la modalidad libre elección (FONASA e ISAPRES).</li>
                            <li>Incorporación en centros de atención primaria, secundaria y centros de atención ambulatoria especializada.</li>
                            <li>Aumento de profesionales psicopedagogos en salud mental.</li>
                        </ul>
                    </div>

                    <div className="">
                        <h1 className="tw-text-3xl tw-font-medium tw-my-2">Ministerio de Desarrollo social</h1>
                        <ul className="tw-text-lg tw-text-justify tw-list-disc tw-px-5">
                            <li>Los programas enfocados a la niñez, como el programa Chile Crece Contigo.</li>
                            <li>En todos los programas de SENAME.</li>
                            <li>Participación en mesa de trabajo con SENADIS.</li>
                            <li>En los programas y proyectos que atienden al adulto mayor.</li>
                            <li>En programas de reinserción social-laboral.</li>
                            <li>Programas de prevención y rehabilitación del consumo de drogas y alcohol.</li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartOfOurWork;
