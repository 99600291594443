export default function checkRut(rut) {

    // let rutEntrada = rut;
    // Despejar Puntos
    let valor = rut.replace(/\./g,'');
    // Despejar Guión
    valor = valor.replace(/-/g,'');

    
    // let valor = rutEntrada.replace(".", "");
    // valor = valor.replace("-", "");



    let cuerpo = valor.slice(0, -1);
    let dv = valor.slice(-1).toUpperCase();
    // rutEntrada = cuerpo + "-" + dv;
    
    if (cuerpo.length < 7) {
        // console.log("RUT INCOMPLETO");
        return false;
    }
    
    let suma = 0;
    let multiplo = 2;
    
    for (let i = 1; i <= cuerpo.length; i++) {
    
        let index = multiplo * valor.charAt(cuerpo.length - i);
        suma = suma + index;
    
        if (multiplo < 7) {
            multiplo = multiplo + 1;
        } else {
            multiplo = 2;
        }
    }
    
    let dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === "k" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    
    if (Number(dv) !== Number(dvEsperado)) {
        // console.log("RUT INVALIDO");
        return false;
    } else {
        // console.log("RUT VALIDO");
        return true;
    }
};