import React from 'react';
import InputFieldText from "../../atoms/InputFieldText";
import InputFieldTextArea from "../../atoms/InputFieldTextArea";
import { Field } from 'formik';
const UpdateTitleDescriptionForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,

    unlock,
    setUnlock,
    typeContent,
}) => {
    return (
        <form
            onSubmit={handleSubmit}
            className="tw-w-full tw-pb-2"
        >
            { 
                (typeContent === "1" || typeContent === "3") ? (
                    <>
                        <InputFieldText
                            classContainer="tw-w-full tw-px-3 tw-my-2"
                            classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
                            classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
                            classError="tw-text-white"
                            name="title"
                            type="text"
                            label="Título"
                            placeholder="Soy el título . . ."
                        />
                        
                        <InputFieldTextArea
                            classContainer="tw-w-full tw-px-3"
                            classLabel="tw-block tw-text-white tw-text-lg tw-font-bold tw-mr-2"
                            classTextArea="tw-w-full tw-rounded-md tw-resize-none tw-h-36 tw-rounded tw-p-2 focus:tw-outline-none"
                            classError="tw-text-white"
                            name="description"
                            label="Descripción"
                            placeholder="Máximo 400 caracteres . . ."
                        />
                    </>
                ) : (
                    typeContent === "2" ? (
                        <>
                            <InputFieldText
                                classContainer="tw-w-full tw-px-3 tw-my-2"
                                classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
                                classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
                                classError="tw-text-white"
                                name="title"
                                type="text"
                                label="Título"
                                placeholder="Soy el título . . ."
                            />
                            <div className="tw-w-full lg:tw-w-1/3 tw-px-3">
                                <label className="tw-text-white tw-text-xl">Tipo de oferta</label>
                                <Field as="select" name="type" className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" >    
                                    <option value="Full-time">Full time</option> 
                                    <option value="Part-time">Part time</option> 
                                </Field>      
                            </div>
                            <InputFieldText
                                classContainer="tw-w-full tw-px-3 tw-my-2"
                                classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
                                classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
                                classError="tw-text-white"
                                name="location"
                                type="text"
                                label="Ubicación"
                                placeholder="Soy el ubicación . . ."
                            />
                            <InputFieldText
                                classContainer="tw-w-full tw-px-3 tw-my-2"
                                classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
                                classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
                                classError="tw-text-white"
                                name="contact"
                                type="email"
                                label="Contácto"
                                placeholder="Soy el email de contácto . . ."
                            />
                            <InputFieldText
                                classContainer="tw-w-full tw-px-3 tw-my-2"
                                classLabel="tw-text-white tw-text-lg tw-font-bold tw-mr-2"
                                classInput="tw-w-full tw-rounded-md tw-p-2 focus:tw-outline-none"
                                classError="tw-text-white"
                                name="phone"
                                type="text"
                                label="Teléfono (Opcional)"
                                placeholder="Soy el el teléfono . . ."
                            />
                        </>
                    ) : (null)
                )
            } 
            
            
            {
                unlock ? (
                    <div className="tw-flex tw-mt-2 tw-mx-auto tw-items-center">
                        <button
                            type="submit"
                            className="tw-mx-auto tw-text-md tw-font-semibold tw-bg-yellow-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-yellow-700 focu:tw-outline-none"
                        >
                            Guardar
                        </button>
                        <button
                            type="button"
                            className="tw-mx-auto tw-text-md tw-font-semibold tw-bg-red-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-yellow-700 focu:tw-outline-none"
                            onClick={() => {setUnlock(false)}}
                        >
                            Cancelar
                        </button>
                    </div>
                ) : (null)
            }
        </form>
    );
};

export default UpdateTitleDescriptionForm;