import React from 'react';

import { FiEdit2 } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
const UpdateContentButton = ({typeContent, redirectTo, titleContent}) => {
    return (
        <NavLink to={`/administrar-contenido/${typeContent}/${redirectTo}/${titleContent}/edit`}
            className="tw-bg-yellow-300 tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-10 tw-h-10 tw-p-2"
        >
            <FiEdit2 className="tw-text-4xl tw-text-black"/>
        </NavLink> 
    );
};

export default UpdateContentButton;