/**
 * Librearias de react o terceros
 */
import React from "react";

/**
 * Iconos
 */
import { RiLoader5Fill } from "react-icons/ri";

const Loader = ({textColor}) => {
    return (
        <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-wrap tw-justify-center tw-items-center tw-animate-pulse">
            <RiLoader5Fill className={`tw-text-9xl ${textColor} tw-mx-auto tw-animate-spin`}/>
            <h1 className={`${textColor} tw-font-semibold tw-mx-auto`}>
                Cargando. . .
            </h1>
        </div>
    );
};

Loader.defaultProps = {
    textColor: "tw-text-gray-700",
};

export default Loader;