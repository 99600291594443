/**
 * Librerias de react o terceros
 */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

/**
 * Contextos
 */
import { UserContext } from "../../context/UserContext";

/**
 * Componentes
 */
import NewsGrid from "../organisms/grids/NewsGrid";
import Paginate from "../molecules/generals/Paginate";

/**
 * Constantes
 */
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
import AddContentButton from "../atoms/buttons/AddContentButton";

/**
 * Assests
 */
// import AddContentButton from "../atoms/buttons/AddContentButton";

const News = () => {
    const userContext = useContext(UserContext);
    const { userLogged, authStatus } = userContext;

    //Estado para manejar las noticias
    const [news, setNews] = useState(null);
    //Opciones de cada consulta
    //ordenado por?
    const [sortBy] = useState("updated_at");
    const [sortAsc, setSortAsc] = useState(false);
    //n° de pagina
    const [page, setPage] = useState(1);
    //n° por pagina
    const [perPage] = useState(18);
    const [totalNews, setTotalNews] = useState(0);

    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setNews(null);
        setPage(value);
    };
 
    useEffect(() => {
        const getNews = async () => {
            try {
                let url = `api/notices?sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;
                const response = await axios.get(url);
                //comprobamos que tipo de respuesta no entrego la api
                //si fue un mensaje o los datos requeirdos
                // console.log(response);
                if (response.data.message) {
                    // console.log('Recibimos un mensaje en la solicitud de noticias');
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };
        getNews().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            if (!response) {
                //array vacio, se mostrará sin contenido
                setNews([]);
            } else {
                //como hubo un contenido obtenemos las noticias
                setTotalNews(response.last_page);
                setNews(response.data);
            }
        });
    }, [sortBy, sortAsc, perPage, page]);

    return (
        <div className="tw-flex tw-flex-col tw-w-full tw-p-2 tw-mb-2">
            {/* <h1 className="tw-text-3xl md:tw-text-4xl tw-my-5 tw-font-semibold tw-text-center tw-text-gray-700">Ultimas noticias</h1> */}
            {
                authStatus === LOGGED_IN ? ( 
                    userLogged.tipo_usuario === ADMIN ? (
                        <AddContentButton typeContent = {1} redirectTo="noticias" nameContent="noticia" titleContent="Crea-una-nueva-noticia"/>
                    ) : (null)
                ) : (null)
            }

            <Paginate
                totalContent={totalNews}
                page={page}
                handleChangePage={handleChangePage}
                sortAsc={sortAsc}
                setSortAsc={setSortAsc}
            />
            <NewsGrid news={news} />
            <Paginate
                totalContent={totalNews}
                page={page}
                handleChangePage={handleChangePage}
                sortAsc={sortAsc}
                setSortAsc={setSortAsc}
            />
        </div>
    );
};

export default News;