/**
 * Librerias de react o terceros
 */
import React from "react";
import ActionGrid from "../atoms/home/ActionGrid";
import Banner from "../atoms/home/Banner";
import PartOfOurWork from "../atoms/home/PartOfOurWork";
import PriceGrid from "../atoms/home/PriceGrid";
import WhyJoin from "../atoms/home/WhyJoin";

/**
 * Componentes
 */


const Home = () => {
    return (
        <div className="tw-mb-2">
            <Banner />
            <WhyJoin />
            <ActionGrid />
            <PartOfOurWork />
            <PriceGrid />
        </div>
    );
};

export default Home;