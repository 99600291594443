import React from 'react';
import NonContent from '../../atoms/NonContent';
import UserPspCard from '../../molecules/cards/UserPspCard';

const UsersPspGrid = ({usersPsp}) => {
    return (
        <div className="tw-flex tw-w-full tw-justify-center tw-flex-wrap tw-my-6 md:tw-px-2 lg:tw-px-4">
            {usersPsp === null ? (
                null
            ) : usersPsp.length === 0 ? (
                <NonContent />
            ) : (
                usersPsp.map((userPsp) => (
                    <UserPspCard 
                        key={userPsp.id}
                        imagen_perfil = {userPsp.imagen_perfil}
                        nombre = {userPsp.nombre}
                        apellido_paterno={userPsp.apellido_paterno}
                        region={userPsp.region}
                        comuna={userPsp.comuna}
                        email={userPsp.email}
                        cargo={userPsp.cargo}
                    />
                ))
            )}
        </div>
    );
};

export default UsersPspGrid;
