import React, {useContext, useState} from 'react';
import axios from "axios";
import Editor from '../../atoms/adminContents/Editor';
import Loader from '../../atoms/Loader';
import { AppContext } from '../../../context/AppContext';
import EditorOnlyText from '../../atoms/adminContents/EditorOnlyText';

const UpdateEditor = ({typeContent, setUnlockEditor, contentSelected, setContentSelected}) => {

    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;

    // Estado de maneja el contenido que se ha escrito en el editor
    const [sending, setSending] = useState(false);
    
    const [editorState, setEditorState] = useState(contentSelected.contenido);

    //Cambios en el editor
    const handleChangeEditor = (content) => {
        setEditorState(content);
    };

    const changeContent = () => {
        if(editorState === '<p><br></p>'){
            handleAlertMessage('Alerta', 'El contenido no puede quedar vacío', 'alert');
            setAlertRender(true);
        }else{
            setSending(true);
            let formData = new FormData();
            formData.append('contenido', editorState);
            
            const sendUpdatedEditor = async () => {
                try {
                    // console.log("requiriendo contenido a la api");
                    let urlAxios = `api/notices/updateContent/${contentSelected.url}`;
                    if(typeContent === "2"){
                        urlAxios = `api/jobs/updateContent/${contentSelected.url}`;
                    }
                    const response = await axios.post(urlAxios, formData);
                    //Comprobamos si la respuesta es un mensaje
                    // console.log(response);
                    if (response.data.message) {
                        handleAlertMessage(response.data.title, response.data.message, response.data.type);
                        setAlertRender(true);
                        if(response.data.type === 'alert' || response.data.type === 'error'){
                            return false; 
                        }
                    }
                    // Como es una data, entonces es el contenido requerido
                    return response.data;
                } catch (error) {
                    // console.log(error);
                    return false;
                }
            };
            
            sendUpdatedEditor().then((response) => {
                // console.log(response);
                if (!response) {
                    setUnlockEditor(true);
                } else {
                    setContentSelected(response);
                    setUnlockEditor(false);
                }
                setSending(false);
            });
        }
    }

    return (
        <div>
            {
                sending ? <Loader /> : (
                    <>
                        {
                            typeContent === "2" ? (
                                <EditorOnlyText handleChangeEditor = {handleChangeEditor} defaultValueEditor={editorState} />
                            ) : (
                                <Editor handleChangeEditor = {handleChangeEditor} defaultValueEditor={editorState} />
                            )
                        }

                        <div className="tw-flex tw-mt-2 tw-mx-auto tw-justify-center">
                            <button
                                type="button"
                                className="tw-text-md tw-font-semibold tw-bg-yellow-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-yellow-700 focu:tw-outline-none"
                                onClick={() => {changeContent()}}
                            >
                                Guardar
                            </button>
                            <button
                                type="button"
                                className=" tw-ml-4 tw-text-md tw-font-semibold tw-bg-red-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-yellow-700 focu:tw-outline-none"
                                onClick={() => {setUnlockEditor(false)}}
                            >
                                Cancelar
                            </button>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default UpdateEditor;
