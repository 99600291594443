import React from 'react';
import { FiTrash2 } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
const DeleteContentButton = ({typeContent, redirectTo, titleContent}) => {
    return (
        <NavLink to={`/administrar-contenido/${typeContent}/${redirectTo}/${titleContent}/delete`}
            className="tw-bg-red-700 tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-10 tw-h-10 tw-p-2"
        >
            <FiTrash2 className="tw-text-4xl tw-text-white"/>
        </NavLink>
    ); 
};


export default DeleteContentButton;
