/**
 * Librerias de react o terceros
 */
import React from "react";
/**
 * Componentes
 */
import InputFieldText from "../../atoms/InputFieldText";
import InputFieldTextArea from "../../atoms/InputFieldTextArea";

const ContactForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
}) => {
    return (
        <form onSubmit={handleSubmit} className="tw-w-full">
            <div className="tw-w-full tw-mt-2 tw-p-3 tw-rounded-md">
                <h1 className="tw-text-black tw-text-3xl tw-font-sans tw-font-bold tw-text-center">
                    Formulario de contacto
                </h1>
                <InputFieldText
                    classContainer="tw-w-full tw-px-3 tw-my-2"
                    classLabel="tw-text-white tw-text-lg tw-font-bold"
                    classInput="tw-w-full tw-rounded-md tw-p-2 tw-border-2 focus:tw-outline-none"
                    classError="tw-text-red-500"
                    name="name"
                    type="text"
                    label=""
                    placeholder="Tu nombre . . ."
                />

                <InputFieldText
                    classContainer="tw-w-full tw-px-3 tw-my-2"
                    classLabel="tw-text-white tw-text-lg tw-font-bold"
                    classInput="tw-w-full tw-rounded-md tw-p-2 tw-border-2 focus:tw-outline-none"
                    classError="tw-text-red-500"
                    name="email"
                    type="email"
                    label=""
                    placeholder="Tu correo . . ."
                />

                <InputFieldText
                    classContainer="tw-w-full tw-px-3 tw-my-2"
                    classLabel="tw-text-white tw-text-lg tw-font-bold"
                    classInput="tw-w-full tw-rounded-md tw-p-2 tw-border-2 focus:tw-outline-none"
                    classError="tw-text-red-500"
                    name="subject"
                    type="text"
                    label=""
                    placeholder="Asunto del mensaje . . ."
                />

                <InputFieldTextArea
                    classContainer="tw-w-full tw-px-3"
                    classLabel="tw-block tw-text-white tw-text-lg tw-font-bold tw-mr-2"
                    classTextArea="tw-w-full tw-rounded-md tw-border-2 tw-resize-none tw-h-36 tw-rounded tw-p-2 focus:tw-outline-none"
                    classError="tw-text-red-500"
                    name="message"
                    label=""
                    placeholder="Ingresa tu mensaje (máx 400 caracteres) . . ."
                />

                <div className="tw-flex tw-justify-center tw-px-3">
                    <button
                        type="submit"
                        className="tw-text-lg tw-font-semibold tw-bg-blue-500 
                        hover:tw-bg-blue-600 tw-text-white tw-rounded-lg tw-h-12 tw-w-full tw-px-6 tw-block tw-shadow-xl focu:tw-outline-none"
                    >
                        Enviar mensaje
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;