/**
 * Librerias de react o terceros
 */
import React, { useEffect, useContext } from "react";
import anime from "animejs/lib/anime.es.js";

/**
 * Contextos
 */
import { AppContext } from "../../../context/AppContext";

/**
 * Iconos
 */
import { BiRightArrow } from "react-icons/bi";

const MenuButton = () => {
    // Contextos que usaremos en este componente
    const appContext = useContext(AppContext);
    // propiedad/funcion que usaremos
    const { menuActive, setMenuActive } = appContext;

    //funcion que se ejecutara cada vez que se renderice el componente
    //y cada vez que haya un cambio es la propiedad menuActive
    useEffect(() => {
        // Si el menu esta activo
        if (menuActive) {
            //cerramos el menu
            // return () => {
            runAnimClose();
            // };
            //si esta desactivado
        } else {
            //abrimos el menú
            // return () => {
            runAnimOpen();
            // };
        }
    }, [menuActive]);

    //Se encarga de animar el sidebar cuando se abre el menu lateral
    const runAnimOpen = () => {
        //le seteamos lo siguientes estilos antes de comenzar la animación
        anime.set(["#menu_sidebar"], {
            //se tranlada en X, -100% hacia afuera
            translateX: "-100%",
            //Tendra una opacidad de 0 (oculto)
            opacity: 0,
        });

        anime.set([".sdb_aux"], {
            //no tendra ancho
            width: 0,
            //Tendra una opacidad de 0 (oculto)
            opacity: 0,
        });

        anime.set([".sdb_item"], {
            //su transformacion se tranlada en X, -100% hacia afuera
            translateX: "-100%",
            //Tendra una opacidad de 0 (oculto)
            opacity: 0,
        });

        //Linea de tiempo de animacion (Cuando se abre) de cada elemento indicado
        let animeSideBar = anime.timeline({
            //la linea completa de animacion tardara 250ms
            duration: 350,
            //Tipo de aceleracion
            easing: "easeInOutSine",
            //no se va a repetir
            loop: false,
            //comenzará automaticamente cuando se llame la funcion
            autoplay: true,
        });

        //agregando escenas a la linea de tiempo
        animeSideBar
            .add({
                //obtendra los elementos que tenga de id menu_sidebar
                targets: ["#menu_sidebar"],
                //trasladará en X, el elemento indicado hacia el centro
                translateX: 0,
                //y le irá pintando una opacidad hasta llegar a 1
                opacity: 1,
            })
            .add(
                {
                    //seleccionamos el div auxiliar
                    targets: [".sdb_aux"],
                    //le damos el width
                    width: "20%",
                    //le damos opacidad hasta que aparesca
                    opacity: 1,
                },
                "-=350"
            )
            //comenzara con un offset de 200, es decir
            //no esperara a que termine la animacion anterior
            .add(
                {
                    //obtendra los elementos que tenga de id menu_sidebar
                    targets: [".sdb_item"],
                    //trasladará en X, el elemento indicado hacia el centro
                    translateX: 0,
                    //y le irá pintando una opacidad hasta llegar a 1
                    opacity: 1,
                    //cada elemento tendra un delay de 50
                    delay: anime.stagger(50),
                },
                "-=200"
            );
    };

    const runAnimClose = () => {
        //le seteamos lo siguentes estilos antes de comenzar la animación
        anime.set(["#menu_sidebar"], {
            //lo trasladamos en X a la posicion 0
            translateX: 0,
            // le damos opacidad (que se muestre)
            opacity: 1,
        });

        anime.set([".sdb_aux"], {
            //Le damos todo su ancho
            width: "20%",
            // le damos opacidad (que se muestre)
            opacity: 1,
        });

        anime.set([".sdb_item"], {
            //lo trasladamos en X a la posicion 0
            translateX: 0,
            // le damos opacidad (que se muestre)
            opacity: 1,
        });

        //Linea de tiempo de la aplicacion
        let animeSideBar = anime.timeline({
            //tendra una duracion de 350ms
            duration: 350,
            //tipo de aceleracion
            easing: "easeInOutSine",
            //no se repetirá
            loop: false,
            //comenzará automatico cuando se llame la funcion
            autoplay: true,
        });

        //agregamos escenas a la animacion
        animeSideBar
            //primera escena
            .add({
                //seleccionamos los botones del menu
                targets: [".sdb_item"],
                //y empezamos a trasladarlos en x hasta que este totalmente oculto
                translateX: "-100%",
                //le quitamos la opacidad para que vaya desapareciendo
                opacity: 0,
                // delay: anime.stagger(100),
            })
            //segunda escena
            .add(
                {
                    //seleccionamos el menu
                    targets: ["#menu_sidebar"],
                    //lo trasladamos en X hasta que esté completamente oculto
                    translateX: "-100%",
                    //le quitamos opacidad hasta que desaracesca
                    opacity: 0,
                    //tendra un offset que comienza antes de que termine la escena anterior
                },
                "-=200"
            )
            .add(
                {
                    //seleccionamos el div auxiliar
                    targets: [".sdb_aux"],
                    //le quitamos el width
                    width: 0,
                    //le quitamos opacidad hasta que desaracesca
                    opacity: 0,
                    //tendra un offset que comienza antes de que termine la escena anterior
                },
                "-=350"
            );
    };
    
    return (
        <button
            className="tw-text-white tw-text-md tw-font-medium tw-items-center tw-inline-flex tw-p-2 tw-ml-2 tw-mt-2 tw-rounded-full tw-fixed tw-bottom-12 tw-right-6 tw-z-50 tw-bg-blue-500 hover:tw-text-blueOil focus:tw-outline-none"
            onClick={() => {
                setMenuActive(!menuActive);
            }}
        >
            <BiRightArrow />
            <span className="tw-ml-1">Menú</span>
        </button>
    );
};

export default MenuButton;