/**
 * Librerias de react o terceros
 */
import React, { useState } from "react";
import axios from "axios";

// Context general para la aplicacion (este manejará el tema, y estructura de la app)
const AppContext = React.createContext();
// Proveedor de las propiedades
const AppProvider = (props) => {
  // Url base de la api
  let hostName = "http://localhost:8000";
  hostName = "https://psp.tinkerlabs.cl/";
  // let hostName = "https://api.colegionacionalpsp.cl/";
  // URL base de las consultas
  axios.defaults.baseURL = hostName;

  //intentamos conseguir el token de autorizacion (si existe)
  const token = localStorage.getItem("token");
  //si existe el token lo agregamos a los headers de cada request de axios
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  // Estado que manejará si el menú del dashboard estará activo o no
  const [menuActive, setMenuActive] = useState(false);

  // Estado que manejará si se muestra la alerta o no
  const [alertRender, setAlertRender] = useState(false);

  // Estado que manejará el titulo del mensaje de alerta
  const [messageTitle, setMessageTitle] = useState("¡Error!");

  // Estado que manejará el mensaje de alerta
  const [alertMessage, setAlertMessage] = useState(null);

  // Estado que manejará el tipo de alertMessage que se mostrará
  // tipos : error, alert, success
  // si no se define un tipo sera un mensaje personalizado
  const [messageType, setMessageType] = useState("error");

  function handleAlertMessage(title, message, type) {
    setMessageTitle(title);
    // setAlertMessage(JSON.stringify(message, null, '\t'));
    setAlertMessage(message);
    setMessageType(type);
  }

  // Retornamos la propiedades y funciones que heredaran los componentes hijos
  return (
    <AppContext.Provider
      value={{
        menuActive,
        setMenuActive,
        alertRender,
        setAlertRender,
        messageTitle,
        alertMessage,
        messageType,
        handleAlertMessage,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
