/**
 * Librerias de react o terceros
 */
import React from "react";
import { NavLink } from "react-router-dom";

/**
 * Boton del menu lateral
 *
 * recibe un link a donde redireccionar
 * un nombre/titulo
 * y si tiene hijos(es decir, que envuelve otras etiquetas html)
 */
const SideBarItem = ({ link, title, children }) => {
    return (
        <NavLink to={link} activeClassName="tw-text-yellow-600">
            <div className="sdb_item tw-flex tw-flex-col tw-justify-between tw-items-center tw-mx-1 lg:tw-mx-0 lg:tw-my-1 tw-p-1 lg:tw-p-px tw-rounded-md tw-bg-red-800 hover:tw-bg-red-900">
                {children}
                <div className="tw-hidden lg:tw-inline-block tw-text-md tw-font-medium">
                    {title}
                </div>
            </div>
        </NavLink>
    );
};

export default SideBarItem;