/**
 * Librerias de react o tereceros
 */
import React, { useContext, useState } from "react";
import { Formik } from "formik";
import axios from "axios";

/**
 * Contextos
 */
import { AppContext } from "../../context/AppContext";

/**
 * Componentes
 */
import Loader from "../atoms/Loader";
import ContactForm from "../molecules/forms/ContactForm";

/**
 * Assets
 */
import Nosotros from "../../assets/images/contacto.jpeg";

const validation = (values) => {
    let errors = {};
    if (!values.name) {
        errors.name = "Ingrese su nombre!";
    } else if (values.name.length < 10 || values.name.length > 50) {
        errors.name = "Su nombre debe tener entre 10 y 20 caracteres.";
    }
    if (!values.email) {
        errors.email = "Ingrese su correo!";
    } else if (values.email.length < 5 || values.email.length > 50) {
        errors.email = "Su correo debe tener entre 5 y 30 caracteres.";
    }
    if (!values.subject) {
        errors.subject = "Ingrese el asunto de su mensaje!";
    } else if (values.subject.length < 10 || values.subject.length > 60) {
        errors.subject = "El asunto debe tener entre 10 y 60 caracteres.";
    }
    if (!values.message) {
        errors.message = "Ingrese su mensaje!";
    } else if (values.message.length < 1 || values.message.length > 400) {
        errors.message = "Su mensaje de tener entre 10 y 400 caracteres.";
    }
    return errors;
};

const ContactFormSection = () => {
    // Manejo de alertas
    const appContext = useContext(AppContext);
    const { handleAlertMessage, setAlertRender } = appContext;

    // Mientras se envia el formulario no se muestra
    const [showForm, setShowForm] = useState(true);

    // Enviando datos del mensaje a la API
    function sendMessage(values) {
        //escondemos el fomrulario mientras
        

        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("subject", values.subject);
        formData.append("message", values.message);


        // axios
        // .post("api/messages/", formData)
        // .then((response) => {
        //     setShowForm(true);
        //     // Elevamos el titulo, mensaje y el tipo de mensaje
        //     handleAlertMessage(
        //         response.data.title,
        //         response.data.message,
        //         response.data.type
        //     );
        //     // Activamos para que se renderice la alerta
        //     setAlertRender(true);
        // })
        // .catch((error) => {
        //     console.log(error);
        //     return false;
        // });

        setShowForm(false);
        const sendData = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/messages`;
                const response = await axios.post(url, formData);
                //Comprobamos si la respuesta es un mensaje
                // console.log(response);
                if (response.data.message) {
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        response.data.title,
                        response.data.message,
                        response.data.type
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
                }
                // Como es una data, entonces es el contenido requerido
                return true;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        sendData().then((response) => {
            // if (!response) {
            //     setUsersPsp(null);
            // } else {
            //     setShowForm(true);
            // }
            setShowForm(true);
        });

        
    }

    return (
        <div className="tw-flex tw-relative tw-flex-col-reverse md:tw-flex-row">
            <div className="tw-w-full md:tw-w-1/2">
                <img
                    src={Nosotros}
                    alt="Fotografia de parte del personal"
                    className="tw-object-cover tw-w-full tw-h-full tw-opacity-80"
                />
            </div>
            <div className="tw-w-full md:tw-w-1/2">
                {/* Se está enviando el formulario? */}
                {showForm ? (
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            subject: "",
                            message: "",
                        }}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                            actions.resetForm(true);
                            sendMessage(values);
                        }}
                        validate={validation}
                    >
                        {(props) => <ContactForm {...props} />}
                    </Formik>
                ) : (
                    <Loader />
                )}
            </div>
            <div className="tw-absolute tw-top-0 tw-w-full tw-flex tw-justify-start lg:tw-justify-center ">
                <div className="tw-h-5 tw-w-16 tw-bg-yellow-400"></div>
            </div>
        </div>
    );
};

export default ContactFormSection;