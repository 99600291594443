/**
 * Librerias de react o terceros
 */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
/**
 * Contextos externos
 */
import { AppContext } from "./AppContext";
/**
 * Utilidades
 */
import { NOT_LOGGED_IN, LOGGED_IN } from "../constants/AuthStatus";

// Contexto de usuario para la aplicacion
const UserContext = React.createContext();

// Proveedor de propiedades
const UserProvider = (props) => {
    // Cargamos el contexto AppContext para poder enviar los mensajes de error
    // y que estos se rendericen correctamente
    const appContext = useContext(AppContext);
    // Cargamos el manajedor si la alerta debe renderizarse o no
    // y el manejador para definir el mensaje y tipo
    const { setAlertRender, handleAlertMessage } = appContext;

    // Estado que se encargara de manejar si hay una sesion iniciada o no
    const [authStatus, setAuthStatus] = useState(NOT_LOGGED_IN);

    // Estado que maneja al usuario que está logueado en la aplicacion
    const [userLogged, setUserLogged] = useState(null);

    // Manejando los cambios en los estados del contexto
    useEffect(() => {
        // Al montar el componente lo primero que haremos es verificar si ya existe un usuario logueado
        // Inicialmente el usuario es null
        // Esto puede deberse a dos situaciones
        // 1. Se actualizó la página y se perdió el estado
        // 2. En ningún momento se hizo un ingreso a la aplicación
        if (userLogged === null) {
            // Obtenemos el usuario
            // Esto retorna una promesa
            // por lo que habrá que hacer una instancia a la funcion
            // Asi podremos saber que nos devolvio luego de resolverse
            // Es mas que nada para esperar a que la promesa se resuelva y luego continuar
            const getUserAuthenticated = async () => {
                try {
                    // console.log("requiriendo el usuario autenticado");
                    // Hacemos la consulta a la API por el posible usuario autenticado
                    const response = await axios.get("api/me");

                    // La consulta anterior puede retornar dos tipos de cosas
                    // 1. El usuario autenticado
                    // 2. un mensaje posiblemente de error o alerta

                    if (response.data.user) {
                        // console.log("recibimos al usuario");
                        setUserLogged(response.data.user);
                        setAuthStatus(LOGGED_IN);
                    }
                    // Retornamos que la respuesta se resolvio correctamente
                    // independiente si es una data o mensaje
                    return true;
                } catch (error) {
                    //en caso de algun error no controlado
                    // console.log(error.response.data);
                    return false;
                }
            };
            getUserAuthenticated().then((response) => {
                if (response) {
                    // console.log("Hubo un resultado esperado.");
                } else {
                    // console.log("Hubo un error no controlado.");
                }
            });
        } else {
            // console.log("Usuario logueado.");
        }
    }, [userLogged]);

    /**
     * Realizamos el ingreso del usuario por medio de la api
     */
    const login = (values) => {
        let rut = values.rut.replace(/\./g,'');
    	// Despejar Guión
	    rut = rut.replace(/-/g,'');

        values.rut = rut;
        
        axios
            .post("api/login", values)
            .then((response) => {
                // Comprobamos el tipo de respuesta que nos retorna la api
                // si es un mensaje, entonces es una alerta
                // si no entonces es el usuario logueado
                if (response.data.user) {
                    //token de acceso para las consultas
                    const token = response.data.token;
                    //guardamos el token
                    localStorage.setItem("token", token);
                    //lo agregamos en los headers para futuras consultas
                    axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${token}`;
                    
                    setUserLogged(response.data.user);
                    setAuthStatus(LOGGED_IN);

                    // Si es un mensaje
                } else if (response.data.message) {
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        response.data.title,
                        response.data.message,
                        response.data.type
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
                }
            })
            //si hubo algun error en el login no controlado
            .catch((error) => {
                // console.log(error.response);
                return false;
            });
    };

    /**
     * Cerramos la sesión del usuario
     */
    function logout() {
        // console.log("logout usuario");
        //eliminamos el token de acceso
        localStorage.removeItem("token");
        //lo quitamos de los headers para futuras consultas
        axios.defaults.headers.common["Authorization"] = null;
        //cerramos la sesion
        setAuthStatus(NOT_LOGGED_IN);
        setUserLogged(null);
    }

    /**
     * retornamos la propiedades y funciones que heredaran los componentes hijos
     */
    return (
        <UserContext.Provider
            value={{
                userLogged,
                setUserLogged,
                login,
                logout,
                authStatus,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };