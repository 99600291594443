import React, { useContext } from "react";
import { Redirect } from "react-router";
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
import { UserContext } from "../../context/UserContext";
import AddContent from "../organisms/AddContent";
import DeleteContent from "../organisms/DeleteContent";
import UpdateContent from "../organisms/UpdateContent";

const AdminContent = ({ match }) => {
  const userContext = useContext(UserContext);
  const { userLogged, authStatus } = userContext;

  console.log(match);

  return (
    <>
      {authStatus === LOGGED_IN ? (
        userLogged.tipo_usuario === ADMIN ? (
          <>
            {match.params.action === "edit" ? (
              <UpdateContent
                typeContent={match.params.type}
                titleContent={match.params.title}
                redirectTo={match.params.redirectTo}
              />
            ) : match.params.action === "delete" ? (
              <DeleteContent
                typeContent={match.params.type}
                titleContent={match.params.title}
                redirectTo={match.params.redirectTo}
              />
            ) : match.params.action === "new" ? (
              <AddContent
                typeContent={match.params.type}
                titleContent={match.params.title}
                redirectTo={match.params.redirectTo}
              />
            ) : null}
          </>
        ) : (
          <Redirect to="/" />
        )
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default AdminContent;
