/**
 * Librerias de react o terceros
 */
import React from "react";

/**
 * Componentes
 */
import Loader from "../../atoms/Loader";
import NonContent from "../../atoms/NonContent";
import EventCard from "../../molecules/cards/EventCard";

const EventsGrid = ({ events }) => {
    return (
        <div className="tw-flex tw-flex-wrap tw-justify-center tw-mx-2 tw-my-5 tw-items-center">
            {events === null ? (
                <Loader />
            ) : events.length === 0 ? (
                <NonContent />
            ) : (
                events.map((event) => (
                    <EventCard
                        key={event.id}
                        id={event.id}
                        title={event.titulo}
                        description={event.descripcion}
                        mainPicture={event.imagen_principal}
                        time={event.updated_at}
                        url={event.url}
                    />
                ))
            )}
        </div>
    );
};

export default EventsGrid;