/**
 * Librerias de react o terceros
 */
import React from "react";

/**
 * Componentes
 */
import Loader from "../../atoms/Loader";
import NonContent from "../../atoms/NonContent";
import NewsCard from "../../molecules/cards/NewsCard";

const NewsGrid = ({ news }) => {
    return (
        <div className="tw-flex tw-w-full tw-flex-wrap tw-my-6 md:tw-px-2 lg:tw-px-4">
            {news === null ? (
                <Loader />
            ) : news.length === 0 ? (
                <NonContent />
            ) : (
                news.map((notice) => (
                    <NewsCard
                        key={notice.id}
                        titulo={notice.titulo}
                        descripcion={notice.descripcion}
                        fechaActualizacion={notice.updated_at}
                        imagenPrincipal={notice.imagen_principal}
                        direccion={notice.url}
                    />
                ))
            )}
        </div>
    );
};

export default NewsGrid;