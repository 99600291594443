/**
 * Librerias de react o terceros
 */
import React from "react";
import { useField } from "formik";

const InputFieldText = ({
    label,
    classContainer,
    classLabel,
    classInput,
    classError,
    bgRed = true,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        // Si existe class container entonces usamos esos estilos
        // de lo contrario usamos los por defecto
        <div className={`tw-relative ${classContainer}`}>
            <label className={`${classLabel}`}>{label}</label>
            {
                bgRed ? (
                    <input className={`${classInput} ${(meta.touched && meta.error) ? 'tw-bg-red-300 tw-border tw-border-red-400': ''}`} {...field} {...props} />
                ) : (
                    <input className={`${classInput}`} {...field} {...props} />
                )
            }
            
            {meta.touched && meta.error && (
                <p className={`${classError}`}> {meta.error} </p>
            )}
        </div>
    );
};

//Propiedades por defecto
InputFieldText.defaultProps = {
    classContainer: "tw-mx-auto",
    classLabel: "tw-text-lg tw-mr-2",
    classInput: "",
    classError: "tw-text-red-500",
};

export default InputFieldText;