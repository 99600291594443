import React from 'react';
import PriceCard from './PriceCard';
const PriceGrid = () => {
    return (
        <div className="tw-container tw-mx-auto tw-py-6 tw-px-10 fondo_login">
            <h1 className="tw-text-center tw-text-4xl tw-text-white tw-font-medium tw-leading-snug tw-tracking-wider">Afíliate</h1>
            <div className="tw-h-1 tw-mx-auto tw-bg-indigo-200 tw-w-24 tw-opacity-75 tw-mt-4 tw-rounded"></div>
            <div className="tw-max-w-full md:tw-max-w-6xl tw-mx-auto tw-my-3 md:tw-px-8">
                <div className="tw-relative tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center">

                    <PriceCard 
                        priceCardTitle = "CESANTE"
                        priceCardSubTitle = "$2.500 /mes"
                        classContainer="tw-w-11/12 sm:tw-w-3/5 lg:tw-w-1/3 tw-max-w-sm tw-my-8  sm:tw-my-5 tw-relative tw-z-0 tw-rounded-lg tw-shadow-lg md:tw--mr-4"/>
                    <PriceCard 
                        priceCardTitle = "CONAECH"
                        priceCardSubTitle = "$3.000 /mes"
                        otherHeader classContainer="tw-w-full sm:tw-w-2/3 lg:tw-w-1/3 tw-max-w-md tw-my-8  sm:tw-my-5 tw-relative tw-z-10 tw-rounded-lg tw-shadow-lg"/>
                    <PriceCard 
                        priceCardTitle = "TRABAJADOR"
                        priceCardSubTitle = "$5.000 /mes"
                        classContainer="tw-w-11/12 sm:tw-w-3/5 lg:tw-w-1/3 tw-max-w-sm tw-my-8  sm:tw-my-5 tw-relative tw-z-0 tw-rounded-lg tw-shadow-lg md:tw--ml-4"/>
                        
                </div>
            </div>
        </div>
    );
};

export default PriceGrid;
