/**
 * Librerias de react o terceros
 */
 import React, { useEffect, useState } from "react";
 import anime from "animejs/lib/anime.es.js";
 
 const BannerSection = ({ image, title, classTitle }) => {
     // Estado del componenete, es para evitar que cada vez que haya un cambio en otro componente
     // se reinicie la animación
     const [isMounted, setIsMounted] = useState(false);
 
     useEffect(() => {
         if (!isMounted) {
             runAnim();
             setIsMounted(true);
         }
     }, [isMounted]);
 
     function runAnim() {
         //le seteamos lo siguientes estilos antes de comenzar la animación
         anime.set([".banner_image"], {
             //aparecera desde arriba
             translateY: "-100%",
             //Tendra una opacidad de 0 (oculto)
             opacity: 0,
         });
         anime.set([".banner_title"], {
             //aparecera desde el lado derecho
             translateX: "-100%",
             //Tendra una opacidad de 0 (oculto)
             opacity: 0,
         });
 
         let animBanner = anime.timeline({
             // duration: 1000,
             easing: "easeInOutQuint",
             // direction: "alternate",
             loop: false,
             autoplay: true,
         });
 
         animBanner
             .add({
                 //obtendra los elementos que tengan la clase
                 targets: [".banner_image"],
                 duration: 600,
                 translateY: 0,
                 //y le irá pintando una opacidad hasta llegar a 1
                 opacity: 1,
             })
             .add(
                 {
                     //obtendra los elementos que tengan la clase
                     targets: [".banner_title"],
                     duration: 1000,
                     translateX: 0,
                     //y le irá pintando una opacidad hasta llegar a 1
                     opacity: 0.7,
                 },
                 "-=300"
             );
     }
 
     return (
         <div className="tw-relative tw-flex tw-mx-auto">
             <img
                 src={image}
                 alt="baner principal de la página"
                 className="banner_image tw-object-contain tw-w-full"
             />
             <div
                 className={`${classTitle} banner_title tw-opacity-0 tw-absolute`}
             >
                 <p className="tw-text-white">{title}</p>
             </div>
         </div>
     );
 };
 
 export default BannerSection;