import React from 'react'
import { Formik } from "formik";
import SearchForm from '../molecules/forms/SearchForm';
/**
 * Validacion del formulario de busqueda
 */
const validation = (values) => {
    let errors = {};
    if (!values.search_content) {
        errors.search_content = "";
    }
    return errors;
};

const SearchContent = ({handleSearch}) => {
    return (
        <Formik
            initialValues={{
                //Datos a buscar
                search_content: "",
            }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                actions.resetForm(true);

                handleSearch(values)
                
            }}
            validate={validation}
        >
            {(props) => <SearchForm {...props} />}
        </Formik>
    );
};

export default SearchContent
