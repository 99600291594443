/**
 * Librerias de react o terceros
 */
import React from "react";

/**
 * Componentes
 */
import InputFieldText from "../../atoms/InputFieldText";

const LoginForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
}) => (
    <form
        onSubmit={handleSubmit}
        className="tw-mx-4 tw-w-full md:tw-mx-auto tw-py-2"
    >
        <div className="tw-flex tw-flex-wrap tw-mb-4">
            <InputFieldText
                classContainer="tw-w-4/5 tw-mx-auto tw-text-left"
                classLabel="tw-text-white tw-text-xl"
                classInput="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"
                // classError = "tw-text-white"
                name="rut"
                type="text"
                label="Rut"
                placeholder="Escribe tu rut."
            />
        </div>
        <div className="tw-flex tw-flex-wrap tw-mb-4">
            <InputFieldText
                classContainer="tw-w-4/5 tw-mx-auto tw-text-left"
                classLabel="tw-text-white tw-text-xl"
                classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"
                // classError = "tw-text-white"
                name="password"
                type="password"
                label="Contraseña"
                placeholder="Escribe tu contraseña."
            />
        </div>
        <div className="tw-flex tw-flex-wrap tw-mb-2">
            <div className="tw-w-full tw-px-3">
                <button
                    type="submit"
                    className="tw-block tw-mx-auto tw-px-6 tw-py-2 tw-text-lg tw-text-white tw-font-semibold tw-bg-green-600 tw-shadow-xl tw-rounded-lg hover:tw-bg-green-700 focus:tw-outline-none"
                >
                    Ingresar
                </button>
            </div>
        </div>
    </form>
);

export default LoginForm;