import React, { useEffect, useState } from 'react';

import axios from "axios";
import regions from '../../utils/region.json';
import UsersPspGrid from '../organisms/grids/UsersPspGrid';
import Loader from '../atoms/Loader';
import Paginate from '../molecules/generals/Paginate';


const BuscarProfesional = () => {
    const [regionSelected, setRegionSelected] = useState('Arica y Parinacota');
    const [comunaSelected, setComunaSelected] = useState('Sin seleccionar');
    const [usersPsp, setUsersPsp] = useState(null);
    const [sending, setSending] = useState(false);

    const [sortBy] = useState("updated_at");
    const [sortAsc, setSortAsc] = useState(false);
    //n° de pagina
    const [page, setPage] = useState(1);
    //n° por pagina
    const [perPage] = useState(18);
    const [totalUsersPsp, setTotalUsersPsp] = useState(1);

    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setUsersPsp(null);
        setPage(value);
    };

    useEffect(() => {
        setComunaSelected('Sin seleccionar');
    }, [regionSelected])

    
    const searchPsp = () => {
        
        setSending(true);
        const getContent = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let comuna = '';
                if(comunaSelected === 'Sin seleccionar'){
                    comuna = '';
                }else{
                    comuna = `&&comuna=${comunaSelected}`;
                }
                let url = `api/users/searchpsp?region=${regionSelected}${comuna}&&sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;
                console.log(url);
                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                // console.log(response);
                if (response.data.message) {
                    return false;
                }
                // Como es una data, entonces es el contenido requerido
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getContent().then((response) => {
            // console.log(response);
            if (!response) {
                setUsersPsp(null);
            } else {
                setTotalUsersPsp(response.last_page);
                setUsersPsp(response.data);
            }
            setSending(false);
        });
    }

    return (
        <div className=" tw-w-10/12 tw-mx-auto tw-my-10">
            <h1 className="tw-text-3xl md:tw-text-4xl tw-my-5 tw-font-semibold tw-text-center tw-text-gray-700">Busca un profesional en tu comuna</h1>
            <label htmlFor="Region" className=" tw-font-semibold tw-text-xl">Seleccione su región de interés</label>

            <select
                onChange={(e) => {setRegionSelected(e.target.value)}}
                value={regionSelected}
                name="Region" className="tw-w-full tw-border-2 tw-bg-gray-100 tw-rounded tw-px-3 tw-py-2 tw-outline-none">
                {
                    regions.regions.map((region, index) => (
                        <option value={`${region.name}`} key={index}>{region.name}</option>
                    ))
                }
            </select>
            <br />
            <br />
            <label htmlFor="Region" className=" tw-font-semibold tw-text-xl">Seleccione su comuna de interés</label>
            <select 
                onChange={(e) => {setComunaSelected(e.target.value)}}
                value={comunaSelected}
                name="Region"
                className="tw-w-full tw-border-2 tw-bg-gray-100 tw-rounded tw-px-3 tw-py-2 tw-outline-none">
                <option value="Sin seleccionar">Sin seleccionar</option>
                {
                    regions.regions.filter(region => (region.name === regionSelected))[0].communes.map(
                        (commune,index) => (<option value={`${commune.name}`} key={index}>{commune.name}</option>))
                }
            </select>
            <br />
            <br />
            <div className="tw-flex tw-w-full tw-justify-center">
                <button className="tw-border tw-bg-blue-500 
                    hover:tw-bg-blue-600
                    tw-rounded-lg tw-px-10 tw-py-2  tw-text-white
                    tw-text-lg tw-font-bold tw-shadow-xl tw-mb-3
                    "
                    onClick={() => {searchPsp()}}
                >Buscar</button>
            </div>

            {
                usersPsp ? (
                    <>
                        <Paginate
                            totalContent={totalUsersPsp}
                            page={page}
                            handleChangePage={handleChangePage}
                            sortAsc={sortAsc}
                            setSortAsc={setSortAsc}
                            show = {false}
                        />
                        {
                            sending ? (
                                <Loader />
                            ) : (
                                <UsersPspGrid usersPsp = {usersPsp} />
                            )
                        }
                        <Paginate
                            totalContent={totalUsersPsp}
                            page={page}
                            handleChangePage={handleChangePage}
                            sortAsc={sortAsc}
                            setSortAsc={setSortAsc}
                            show = {false}
                        />
                    </> 
                ) : ( null )
            }

        </div>
    );
};

export default BuscarProfesional
