/**
 * Librerias de react o terceros
 */
import React, { useState, useEffect } from "react";
import anime from "animejs/lib/anime.es.js";

/**
 * Assets
 */
import FacebookIcon from "../../assets/images/facebook.ico";
import TwitterIcon from "../../assets/images/twitter.ico";
import InstagramIcon from "../../assets/images/instagram.ico";
import YouTubeIcon from "../../assets/images/youtube.ico";
// import TransparenciaActiva from "../../assets/images/TransparenciaActiva.png";

const SocialNetwork = () => {
    // Estado del componenete, es para evitar que cada vez que haya un cambio en otro componente
    // se reinicie la animación
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (!isMounted) {
            runAnim();
            setIsMounted(true);
        }
    }, [isMounted]);

    // Función que define la animación de los iconos
    const runAnim = () => {
        let animeIcons = anime.timeline({
            easing: "easeInOutQuint",
            loop: false,
            autoplay: true,
        });

        animeIcons
            .add({
                targets: ["#img_trasparentlaw"],
                duration: 1000,
                opacity: [0, 100],
            })
            .add({
                targets: [
                    "#icon_ig",
                    "#icon_tw",
                    "#icon_yt",
                    "#icon_fb",
                    "#img_trasparentlaw",
                ],
                duration: 1000,
                scale: [0, 1],
            })
            .add(
                {
                    targets: [".button_item"],
                    opacity: [0, 1],
                },
                "-=700"
            );
    };

    return (
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-mx-auto">
            <div className="tw-flex tw-my-4 tw-mx-auto">
                <a href="https://www.instagram.com/colegionacionalpspchile/">
                    <img
                        src={InstagramIcon}
                        alt="facebook"
                        id="icon_ig"
                        className="tw-max-h-10  tw-transform tw-scale-0"
                    />
                </a>
                <a href="https://twitter.com/colegionacion14">
                    <img
                        src={TwitterIcon}
                        alt="twitter"
                        id="icon_tw"
                        className="tw-max-h-10 tw-ml-2 tw-transform tw-scale-0"
                    />
                </a>
                <a href="https://www.youtube.com/channel/UCJtSysUZ4lw4g02BoxcnGWw/featured">
                    <img
                        src={YouTubeIcon}
                        alt="youtube"
                        id="icon_yt"
                        className="tw-max-h-10 tw-ml-2 tw-transform tw-scale-0"
                    />
                </a>
                <a href="https://www.facebook.com/groups/434837880022650">
                    <img
                        src={FacebookIcon}
                        alt="facebook"
                        id="icon_fb"
                        className="tw-max-h-10 tw-ml-2 tw-transform tw-scale-0"
                    />
                </a>
            </div>
            {/* <img
                src={TransparenciaActiva}
                alt="Transparencia activa"
                id="img_trasparentlaw"
                className="tw-max-h-16 tw-mx-2 tw-opacity-0 tw-hidden lg:tw-inline-block tw-pt-2"
            /> */}
        </div>
    );
};

export default SocialNetwork;