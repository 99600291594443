import React, {useState, useContext} from 'react';
import {AiFillPlusCircle} from 'react-icons/ai';
import AddSectionForm from '../molecules/forms/AddSectionForm';
import { Formik } from "formik";
import Loader from '../atoms/Loader';

import axios from "axios";
import { AppContext } from '../../context/AppContext';


const validation = (values) => {
    let errors = {};
    if (!values.nombre) {
        errors.nombre = "No puede ser vacío.";
    }else if (values.nombre.length >= 30 ){
        errors.nombre = "Muy extenso.";
    }
    return errors;
};

const AddSection = ({handleSections}) => {

    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;

    const [showForm, setShowForm] = useState(false);
    const [sending, setSending] = useState(false);

    const handleShowForm = () => {
        setShowForm(!showForm);
    }


    const createSection = (values) => {

        setSending(true);

        const formData = new FormData();

        formData.append('nombre', values.nombre);

        const sendSection = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/sections`;
                const response = await axios.post(url, formData);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    handleAlertMessage(response.data.title, response.data.message, response.data.type);
                    setAlertRender(true);
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        sendSection().then((response) => {
            if (response) {
                handleAlertMessage('Sección creada', 'Sección creada correctamente.', 'success');
                setAlertRender(true);
                handleSections(response);
            } 
            setSending(false);
        });
    }

    return (
        <div className="tw-text-gray-700 tw-text-lg tw-w-11/12 tw-p-3 tw-mt-2 tw-mb-4 tw-mx-auto tw-border">
            <div className="tw-flex tw-justify-between tw-items-center">
                <h1>Agregar sección</h1>
                <button
                    onClick={() => {handleShowForm()}}
                >
                    <AiFillPlusCircle className="tw-text-gray-700 tw-text-4xl" />
                </button>
            </div>
            {
                showForm ? (
                    sending ? (
                        <Loader />
                    ) : (
                        <Formik
                            initialValues={{
                                //Datos de 
                                nombre: "",
                        
                            }}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(false);
                                actions.resetForm(true);
                                createSection(values);       
                            }}

                            validate={validation}
                            
                            >
                            {props => <AddSectionForm {...props}   />}
                        </Formik>
                    )
                ) : (null)
            }
            
        </div>
    );
};

export default AddSection;
