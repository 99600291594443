import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  // paragraphStyle,
  // table,
  textStyle,
  // image,
  // link,
  // video,
} from "suneditor/src/plugins";

const EditorOnlyText = ({ defaultValueEditor, handleChangeEditor }) => {
  return (
    <div className="tw-flex tw-flex-col tw-p-3 tw-justify-center">
      <h1 className="tw-text-white tw-text-3xl tw-font-bold tw-text-center">
        Detalles
      </h1>
      <div className="tw-mx-auto tw-w-full lg:tw-w-9/12">
        <SunEditor
          autoFocus={false}
          lang="es"
          showToolbar={true}
          setOptions={{
            showPathLabel: false,
            minHeight: "50vh",
            maxHeight: "50vh",
            placeholder: "Escriba su contenido aquí!!",
            plugins: [
              // align,
              font,
              fontColor,
              fontSize,
              formatBlock,
              hiliteColor,
              horizontalRule,
              lineHeight,
              list,
              // paragraphStyle,
              // table,
              textStyle,
              // image,
              // link,
              // video,
            ],
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
              ],
              // ["fontColor", "hiliteColor"],
              // ["removeFormat"],
              // "/", // Line break
              // ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],
              // ["table", "link", "image", "video"],
            ],
            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
            font: [
              "Arial",
              "Calibri",
              "Comic Sans",
              "Courier",
              "Garamond",
              "Georgia",
              "Impact",
              "Lucida Console",
              "Palatino Linotype",
              "Segoe UI",
              "Tahoma",
              "Times New Roman",
              "Trebuchet MS",
            ],
          }}
          defaultValue={defaultValueEditor}
          onChange={handleChangeEditor}
        />
      </div>
    </div>
  );
};

export default EditorOnlyText;
