import React, {useState, useEffect, useContext} from 'react'
import axios from "axios";
import Loader from '../atoms/Loader';
import { Redirect } from 'react-router';
import { AppContext } from '../../context/AppContext';

const DeleteContent = ({typeContent, titleContent, redirectTo}) => {

    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;

    const [contentSelected, setContentSelected] = useState(null);
    const [redirect, setRedirect] = useState(false);
    
    const [sending, setSending] = useState(false);

    useEffect(() => {
        const getContent = async (content) => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/notices/${content}`;
                if(typeContent==="2"){
                    url = `api/jobs/${content}`;
                }else if(typeContent==="3"){
                    url = `api/activities/${content}`;
                }
                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    return false;
                }
                // Como es una data, entonces es el contenido requerido
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getContent(`${titleContent}`).then((response) => {
            // console.log(response);
            if (!response) {
                setContentSelected(false);
            } else {
                setContentSelected(response);
            }
        });
    }, [titleContent, typeContent]);


    const deleteContent = () => {
        setSending(true);
        const deleteContent = async (content) => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/notices/${content}`;
                if(typeContent==="2"){
                    url = `api/jobs/${content}`;
                }else if(typeContent==="3"){
                    url = `api/activities/${content}`;
                }
                const response = await axios.delete(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    handleAlertMessage(response.data.title, response.data.message, response.data.type);
                    setAlertRender(true);
                    if(response.data.type === "alert" || response.data.type === "error" ){
                        return false;
                    }
                }
                return true;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        deleteContent(`${titleContent}`).then((response) => {
            if (response) {
                setRedirect(true);
            } 
            setSending(false);
        });
    }

    return (
        <div className="tw-bg-gray-600 tw-m-2 tw-rounded-md tw-p-2">
            {
                redirect ? (
                    <Redirect to={`/${redirectTo}`} />
                ) : (
                    contentSelected === null ? <Loader /> : (
                        sending ? (<Loader/>) : (
                            <>
                                <h1 className="tw-text-white tw-mb-3 tw-text-3xl tw-font-bold tw-text-center">
                                    Eliminar contenido
                                </h1>
                                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center lg:tw-items-start">
                                    <div className="tw-w-60 lg:tw-w-2/5">
                                        <img src={contentSelected.imagen_principal} className="tw-object-cover tw-mx-auto lg:tw-w-6/12" alt="Imagen principal"/>
                                    </div>
                                    <div className="tw-w-full lg:tw-w-3/5 tw-p-2">
                                        <h1 className="tw-text-white tw-text-xl">
                                            {contentSelected.titulo}
                                        </h1>
                                        <p className="tw-text-white tw-text-justify tw-mt-6">
                                            
                                            {
                                                typeContent === "2" ? (
                                                    <ul>
                                                        <li><span className="tw-text-lg">Correo:</span> {contentSelected.contacto}</li>
                                                        <li><span className="tw-text-lg">Teléfono:</span> {contentSelected.telefono}</li>
                                                        <li><span className="tw-text-lg">Ubicación:</span> {contentSelected.ubicacion}</li>
                                                        <li><span className="tw-text-lg">Modalidad:</span> {contentSelected.tipo}</li>
                                                        
                                                    </ul>
                                                ) : (
                                                    contentSelected.descripcion
                                                )
                                            }
                                            
                                        </p>
                                        <div className="tw-flex tw-mt-6">
                                            <button
                                                type="button"
                                                className="tw-ml-4 tw-text-md tw-font-semibold tw-bg-yellow-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-yellow-700 focu:tw-outline-none"
                                                onClick={() => {setRedirect(true)}}
                                            >
                                                Volver a la página anterior
                                            </button>
                                            <button
                                                type="button"
                                                className="tw-ml-4 tw-text-md tw-font-semibold tw-bg-red-500 tw-text-white tw-rounded-lg tw-h-10 tw-px-6 tw-block tw-shadow-xl hover:tw-bg-red-700 focu:tw-outline-none"
                                                onClick={() => {deleteContent()}}
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    ) 
                )
            }
        </div>
    )
}

export default DeleteContent
