import React from 'react'
import InputFieldText from '../../atoms/InputFieldText';
import { Field } from 'formik';

const RegistryForm = ({
    handleChange, 
    handleSubmit, 
    isSubmitting, 
    resetForm, 
    values, 
    errors, 
    touched, 

    handleRegions,
    handleCommune,
    regions,
    region,
	communes,
    setCommunes

}) => {
    
    return (
        
        <form onSubmit={handleSubmit}> 
            <h1 className="tw-text-white tw-text-2xl tw-text-center">Información personal</h1>
            <hr className="tw-w-1/2 tw-mx-auto tw-bg-white tw-h-px tw-rounded tw-mb-5"></hr>

            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3"
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="nombre"            
                    type="text" 
                    label="Nombres" />

                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="apellido_paterno"  type="text" label="Apellido paterno" />
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="apellido_materno"  type="text" label="Apellido materno" />
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/4 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="rut" type="text" label="Rut" placeholder="112223334" />

                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/4 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="fecha_nacimiento"  type="date" label="Fecha de nacimiento" placeholder="AAAA/MM/DD"/>

                <div className="tw-w-full lg:tw-w-1/4 tw-px-3">
                    <label className="tw-text-white tw-text-xl">Sexo</label>
                    <Field as="select" name="sexo" className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" >
                        <option value="Masculino">Masculino</option>
                        <option value="Femenino">Femenino</option>
                        <option value="Otro">Prefiero no decirlo</option> 
                    </Field>
                </div>

                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/4 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="nacionalidad"        
                    type="text" 
                    label="Nacionalidad" />
            </div>          
            
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="password"        
                    type="password" 
                    label="Contraseña" />

                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                    name="password_confirmation"        
                    type="password" 
                    label="Confirma tu constraseña" />
                

                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/3 tw-px-3"
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="telefono" placeholder="YYXXXXXX" type="tel"  label="Teléfono particular (opcional)" min="6" max="10"/>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/4 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="direccion"  type="text" label="Dirección particular" />
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/4 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="ciudad" type="text" label="Ciudad" />
                <div className="tw-w-full lg:tw-w-1/4 tw-px-3">
                    <label className="tw-text-white tw-text-xl">Región</label>
                    <Field as="select" name="region" className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"
                            // onClick = {()=>{setCommunes(null)}}
                            value={region}
                            onChange={e=> {handleRegions(e.target.value)}}
                            >
                        {
                            regions.map((region, index) => (
                                <option value={`${region.name}`} key={index}>{region.name}</option>
                            ))
                        }
                    </Field>
                </div>
                    {
                        communes === null ? (
                            null
                        ) : (
                            <div className="tw-w-full lg:tw-w-1/4 tw-px-3">            
                                <label className="tw-text-white tw-text-xl">Comuna</label>
                                <Field as="select" name="comuna" className="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white" 
                                        onClick = {e=> {handleCommune(e.target.value)}}
                                        onChange={handleChange}
                                        >
                                    {  
                                        communes.map((commune,index) => (
                                            <option value={`${commune.name}`} key={index}>{commune.name}</option>
                                        ))
                                    }
                                </Field>
                            </div>
                        )
                    }


            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/2 tw-px-3" 
                    classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="email"   type="email"     placeholder="ejemplo@email.com" label="Correo" />

                <InputFieldText 
                    classContainer = "tw-w-full lg:tw-w-1/2 tw-px-3" classLabel="tw-text-white tw-text-xl"
                    classError="tw-text-gray-300 tw-text-xl"
                    classInput="tw-w-full tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"  
                    name="celular" type="tel" placeholder="+56912345678" label="Celular" min="8" max="12"/>
            </div>

            <div className="tw-flex tw-items-center tw-justify-center lg:tw-justify-end tw-space-x-2 tw-mb-6 lg:tw-mr-10">
                <button type="submit"
                        className="tw-text-lg tw-font-semibold tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded-lg tw-px-6 tw-py-3 tw-block tw-shadow-xl"
                >
                    Paso siguiente
                </button>               
            </div>
        </form>
    );
};

export default RegistryForm;
