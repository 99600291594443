import React from 'react';

const HowToJoin = () => {
    return (
        <>
            <h1 className = "tw-text-3xl tw-mb-5 tw-font-semibold">¿Cómo Asociarse?</h1>
            <div className="tw-text-lg tw-text-justify tw-mx-8 tw-mb-5">
                <p>
                    Los psicopedagogos puedes afiliarse al Colegio de Psicopedagogos de Chile a traves de un sistema de inscripcion en linea, 
                    debiendo los postulantes completar los antecedentes requeridos en todos los campos o tambien concurriendo personalmente a  
                    las oficinas de su respectiva región, con dos fotos tamaño carnet con su nombre y numero de Cedula.
                    El colegio de Psicopedagogos debe verificar los antecedentes dentro de un breve plazo para concretar las inscripciones en linea.
                    <br /> <br/>
                    La inscripcion a pagar asciende a la suma unica de $5.000 que puede, y el valor de la mensualidad asciende a las siguientes sumas:
                    
                </p>

                <br />
                <ul className=" tw-list-disc tw-list-inside">
                    <li>$5.000.- en caso de que se este trabajando</li>
                    <li>$2.500.- en caso de que No se este trabajando</li>
                    <li>$3.000.- en caso de pertenecer a una organizacion que integran el Consejo Nacional de Asistentes de la educacion CONAECH.</li>
                </ul>
                <br />

                <p>pagar a traves de las siguientes modalidades:</p>
                <br />
                <ul className="tw-list-disc tw-list-inside">
                    <li>
                        Mediante la transferencia o deposito a la chequera electronica
                        del Colegio Nacional de Psicopedagogos N° 53371826731 del Banco
                        Estado a nombre del COLEGIO NACIONAL DE PSICOPEDAGOGOS DE CHILE
                        A.G., RUT N° 65.149.247-5. Realizada la operacion, es
                        indispensable enviar el comprobante de la misma por correo
                        electronico a registropspnacional@gmail.com, indicando su nombre completo y
                        numero de RUT.
                    </li>
                    <li> Directamente en nuestras oficinas regionales.</li>
                </ul>
                <br />
                <p>
                    Si el postulante a la colegiatura no cumple con los requisitos
                    exigidos por los Estatutos del Colegio de Psicopedagogos para la
                    afiliación, se le comunicara via correo electronico y se le
                    devolvera el pago si lo hubiera realizado.
                </p>
            </div>
        </>
    );
};

export default HowToJoin;
