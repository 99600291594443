import React, { useState } from 'react';
import { FaAddressCard } from "react-icons/fa";
import UserData from './UserData';

const UserRow = ({user, handleUsers}) => {
    const [seeInfo, setSeeInfo] = useState(false);


    function handleSeeInfo() {
        if(seeInfo){
            setSeeInfo(false);
        }else{
            setSeeInfo(true);
        }
    }
 
    return (
        <div className="tw-w-full tw-max-w-full tw-mx-auto tw-px-4 tw-py-4 tw-bg-gray-600 tw-shadow-md tw-rounded-lg">
            <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-between">
                
                <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-text-white">
                    <img className="tw-rounded-full tw-h-8 tw-w-8 tw-object-cover" src={user.imagen_perfil} alt="imagen usuario"/>
                    <p className="tw-ml-2 tw-text-base tw-font-medium">{user.nombre} {user.apellido_paterno}</p>
                    <p className="tw-ml-2 tw-hidden lg:tw-inline-block tw-text-base tw-font-medium">- Correo: {user.email}</p>
                </div> 

                <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-items-end lg:tw-items-center">        
                    {
                        user.estado === "desactivada" ? <p className="tw-bg-red-800 tw-px-2 tw-rounded-full tw-text-white tw-font-hairline tw-tracking-wider">Cuenta nueva!</p> : null
                    }
                    <button
                        onClick = {() => {handleSeeInfo()}}
                        >
                        <FaAddressCard className="tw-text-green-400 tw-mx-2 tw-text-3xl"/>
                    </button> 
                </div>
            </div>
            {
                seeInfo ? <UserData userRecived={user} handleUsers = {handleUsers}/> : null
            }
            
        </div>
    );
};

export default UserRow;
