/**
 * Librerias de react o terceros
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

/**
 * Estilos para la paginacion
 */
const useStyles = makeStyles((theme) => ({
    root: {
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

const Paginate = ({
    totalContent,
    page,
    handleChangePage,
    sortAsc,
    setSortAsc,
    show = true,
}) => {
    //estilo paginacion
    const classes = useStyles();

    return (
        <div
            className={`${classes.root} tw-px-2 tw-py-1 tw-flex tw-flex-col tw-items-center tw-justify-center`}
        >
            <Pagination
                count={totalContent}
                page={page}
                onChange={handleChangePage}
                color="primary"
                // size="large"
                // variant="outlined" shape="rounded"
            />

            {
                show ? (
                    <div className="tw-flex tw-justify-between tw-space-x-2">
                        <button
                            className={`${
                                sortAsc ? "tw-bg-gray-500 hover:tw-bg-gray-600 tw-shadow-lg" : "tw-bg-yellow-500 tw-shadow-lg hover:tw-bg-yellow-600"
                            } tw-text-white tw-py-1 tw-px-2 tw-rounded-sm`}
                            disabled={sortAsc === true ? true : false}
                            onClick={() => {
                                setSortAsc(true);
                            }}
                        >
                            Más antiguas
                        </button>
                    
                        <button
                            className={`${
                                sortAsc ? "tw-bg-yellow-500 hover:tw-bg-yellow-600 tw-shadow-lg" : "tw-bg-gray-500 tw-shadow-lg hover:tw-bg-gray-600"
                            } tw-text-white tw-py-1 tw-px-2 tw-rounded-sm`}
                            disabled={sortAsc === true ? false : true}
                            onClick={() => {
                                setSortAsc(false);
                            }}
                        >
                            Más nuevas
                        </button>
                    </div>
                ) : null
            }
        </div>
    );
};

export default Paginate;
