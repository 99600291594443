/**
 * Librerias de react o terceros
 */
import React from "react";

/**
 * Componentes
 */
import Loader from "../../atoms/Loader";
import NonContent from "../../atoms/NonContent";
import JobCard from "../../molecules/cards/JobCard";

const JobsGrid = ({ jobs, selectJob }) => {
    return (
        <div className="tw-flex tw-w-full tw-justify-center tw-flex-wrap tw-my-6 md:tw-px-2 lg:tw-px-4">
            {jobs === null ? (
                <Loader />
            ) : jobs.length === 0 ? (
                <NonContent />
            ) : (
                jobs.map((job) => (
                    <JobCard
                        key={job.id}
                        id = {job.id}
                        url = {job.url}
                        imagen_principal = {job.imagen_principal}
                        titulo = {job.titulo}
                        contacto = {job.contacto} 
                        ubicacion = {job.ubicacion}
                        telefono = {job.telefono}
                        updated_at = {job.updated_at}
                        tipo = {job.tipo}
                        selectJob = {selectJob}
                    />
                ))
            )}
        </div>
    );
};

export default JobsGrid;