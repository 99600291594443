/**
 * Librerias de react o terceros
 */
import React from "react";

const ContactCard = () => {
    return (
        <div className="tw-relative tw-flex tw-items-top tw-justify-center  dark:tw-bg-gray-900 sm:tw-items-center sm:tw-pt-0">
            <div className="tw-max-w-6xl tw-mx-auto sm:tw-px-6 lg:tw-px-8 ">
                <div className="tw-overflow-hidden ">
                    <div className="tw-grid tw-grid-cols-1">
                        <div className="tw-p-6   dark:tw-bg-gray-800 sm:tw-rounded-lg  tw-w-8/12 tw-mx-auto md:tw-w-full">
                            <h1 className="tw-text-4xl sm:tw-text-3xl tw-text-gray-800 dark:tw-text-white tw-font-extrabold tw-tracking-tight">
                                Comunícate con nosotros
                            </h1>
                            <p className="tw-text-normal tw-text-lg sm:tw-text-2xl tw-font-medium tw-text-gray-600 dark:tw-text-gray-400 tw-mt-2">
                                A través de los siguientes medios
                            </p>
                            <div className="tw-flex tw-items-center tw-mt-8 tw-text-gray-600 dark:tw-text-gray-400">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    className="tw-w-8 tw-h-8 tw-text-gray-500"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    Chile
                                </div>
                            </div>
                            {/* <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-600 dark:tw-text-gray-400">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    className="tw-w-8 tw-h-8 tw-text-gray-500"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                    />
                                </svg>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    +52 123456789
                                </div>
                            </div> */}
                            <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-600 dark:tw-text-gray-400">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    className="tw-w-8 tw-h-8 tw-text-gray-500"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                    />
                                </svg>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    administracion@colegionacionalpsp.cl
                                </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-mt-4 tw-text-gray-600 dark:tw-text-gray-400">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    className="tw-w-8 tw-h-8 tw-text-gray-500"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                    />
                                </svg>
                                <div className="tw-ml-4 tw-text-md tw-tracking-wide tw-font-semibold tw-w-40">
                                    soporte@colegionacionalpsp.cl
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactCard;