import React from 'react';
import { Link } from 'react-router-dom';
import Nosotros from '../../../assets/images/colage2.png';
const WhyJoin = () => {
    return (
        <div className="tw-flex tw-flex-col xl:tw-flex-row tw-w-11/12 tw-mx-auto tw-my-4">
            <div className="tw-relative tw-flex tw-justify-center tw-items-center tw-w-full xl:tw-w-1/2 tw-border-t-2 tw-border-b-2 tw-border-gray-700">
                <div className="tw-px-2 xl:tw-px-10">
                    <h1 className="tw-text-4xl tw-font-semibold tw-my-1 xl:tw-my-5 tw-text-center">
                        ¿Por qué Afiliarme?
                    </h1>
                    <p className="tw-text-xl tw-text-center tw-mb-4">
                        Cómo afiliado serás parte de una gran red de apoyo, donde podrás participar de capacitaciones exclusivas, serás participe de la toma de decisiones, aportando ideas, en la promoción y resolución de inquietudes, en las elecciones, en proyectos, emprendimientos y nuevos desafíos que nos lleven a la consolidación de nuestra profesión.
                    </p>
                    <p className="tw-text-xl tw-text-center tw-mb-4">
                        En esta página, no solo podrás ver nuestras noticias más recientes, sino que también podrás compartir documentos, revisar actividades, ver ofertas laborales, mostrarte en buscar profesional, etc.
                    </p>
                    <p className="tw-text-xl tw-text-center tw-mb-4">
                        El socio de este colegio es un socio pro activo.
                    </p>
                    <p className="tw-text-xl tw-text-center tw-mb-9">
                        ¡Ven y hazte participle!
                    </p>
                    <div className="tw-absolute tw-bottom-0 tw-right-0 tw-h-10 tw-p-2 tw-bg-gray-600">
                        <Link to="/sobre-nosotros" className="tw-text-white tw-font-medium hover:tw-no-underline hover:tw-text-orange-500">Descubre más sobre nosotros!</Link>
                    </div>
                </div>
            </div>
            <div className="tw-w-full xl:tw-w-1/2 tw-flex tw-items-center tw-justify-center">
                <img src={Nosotros} className="tw-mx-auto tw-w-full" alt="nosotros" />
            </div>

        </div>
    );
};

export default WhyJoin;
