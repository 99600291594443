/**
 * Librerias de react o terceros
 */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

/**
 * Contextos
 */
import { UserContext } from "../../context/UserContext";

/**
 * Componentes
 */
import EventsGrid from "../organisms/grids/EventsGrid";
import Paginate from "../molecules/generals/Paginate";

/**
 * Constantes
 */
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
import AddContentButton from "../atoms/buttons/AddContentButton";


const EventsActivities = () => {
    const userContext = useContext(UserContext);
    const { userLogged, authStatus } = userContext;

    const [events, setEvents] = useState(null);

    //Opciones de cada consulta
    //ordenado por?
    const [sortBy] = useState("updated_at");
    const [sortAsc, setSortAsc] = useState(false);
    //n° de pagina
    const [page, setPage] = useState(1);
    //n° por pagina
    const [perPage] = useState(18);
    const [totalEvents, setTotalEvents] = useState(0);

    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setEvents(null);
        setPage(value);
    };

    useEffect(() => {
        const getEvents = async () => {
            try {
                let url = `api/activities?sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;
                const response = await axios.get(url);
                //comprobamos que tipo de respuesta no entrego la api
                //si fue un mensaje o los datos requeirdos
                // console.log(response);
                if (response.data.message) {
                    // console.log('Recibimos un mensaje en la solicitud de noticias');
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
            }
        };
        getEvents().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            if (!response) {
                //array vacio, se mostrará sin contenido
                setEvents([]);
            } else {
                //como hubo un contenido obtenemos las noticias
                setTotalEvents(response.last_page);
                setEvents(response.data);
            }
        });
    }, [sortBy, sortAsc, perPage, page]);

    return (
        <>
            <div className="tw-flex tw-flex-col tw-w-full tw-p-2 tw-mb-2">
                {
                    authStatus === LOGGED_IN ? ( 
                        userLogged.tipo_usuario === ADMIN ? (
                            <AddContentButton typeContent = {3} redirectTo="eventos-actividades" nameContent="eventos o actividades" titleContent="Crea-un-nuevo-evento"/>
                        ) : (null)
                    ) : (null)
                }
                
                <Paginate
                    totalContent={totalEvents}
                    page={page}
                    handleChangePage={handleChangePage}
                    sortAsc={sortAsc}
                    setSortAsc={setSortAsc}
                />
                <EventsGrid events={events} />
                <Paginate
                    totalContent={totalEvents}
                    page={page}
                    handleChangePage={handleChangePage}
                    sortAsc={sortAsc}
                    setSortAsc={setSortAsc}
                />
            </div>
        </>
    );
};

export default EventsActivities;