/**
 * Librerias de react o terceros
 */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

/**
 * Contextos
 */
import { UserContext } from "../../context/UserContext";

/**
 * Componentes
 */
import JobsGrid from "../organisms/grids/JobsGrid";
import Paginate from "../molecules/generals/Paginate";

/**
 * Constantes
 */
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
import AddContentButton from "../atoms/buttons/AddContentButton";
import { Redirect } from "react-router";
import JobData from "../molecules/cards/JobData";

/**
 * Assests
 */
// import AddContentButton from "../atoms/buttons/AddContentButton";

const Jobs = () => {
    const userContext = useContext(UserContext);
    const { userLogged, authStatus } = userContext;

    //Estado para manejar las noticias
    const [jobs, setJobs] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    //Opciones de cada consulta
    //ordenado por?
    const [sortBy] = useState("updated_at");
    const [sortAsc, setSortAsc] = useState(false);
    //n° de pagina
    const [page, setPage] = useState(1);
    //n° por pagina
    const [perPage] = useState(18);
    const [totalJobs, setTotalJobs] = useState(0);

    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setJobs(null);
        setPage(value);
    };

    useEffect(() => {
        const getJobs = async () => {
            try {
                let url = `api/jobs?sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;
                const response = await axios.get(url);
                //comprobamos que tipo de respuesta no entrego la api
                //si fue un mensaje o los datos requeirdos
                // console.log(response);
                if (response.data.message) {
                    // console.log('Recibimos un mensaje en la solicitud de noticias');
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };
        getJobs().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            if (!response) {
                //array vacio, se mostrará sin contenido
                setJobs([]);
            } else {
                //como hubo un contenido obtenemos las noticias
                setTotalJobs(response.last_page);
                setJobs(response.data);
            }
        });
    }, [sortBy, sortAsc, perPage, page]);


    const selectJob = (value) => {
        setSelectedJob(null);
        const getJob = async (content) => {
            try {
                // console.log("requiriendo contenido a la api");
                const url = `api/jobs/${content}`;
                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    // console.log(
                    //     "Recibimos un mensaje en la solicitud"
                    // );
                    return false;
                }
                // Como es una data, entonces es el contenido requerido
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getJob(`${value}`).then((response) => {
            // console.log(response);
            if (!response) {
                setSelectedJob(null);
            } else {
                setSelectedJob(response);
            }
        });


    }

    return (
        <div className="tw-flex tw-flex-col tw-w-full tw-p-2 tw-mb-2">
            {
                authStatus === LOGGED_IN ? ( 
                    <>
                        {
                            userLogged.tipo_usuario === ADMIN ? (
                                <AddContentButton typeContent = {2} redirectTo="trabajos" nameContent="trabajo" titleContent="Crea-un-nuevo-trabajo"/>
                            ): ( null )
                        }
                        
                        {
                            selectedJob ? (
                                <JobData selectedJob = {selectedJob} setSelectedJob={setSelectedJob}/>
                            ) : (null)
                        }
                            
                        <Paginate
                            totalContent={totalJobs}
                            page={page}
                            handleChangePage={handleChangePage}
                            sortAsc={sortAsc}
                            setSortAsc={setSortAsc}
                        />
                        <JobsGrid jobs={jobs} selectJob={selectJob}/>
                        <Paginate
                            totalContent={totalJobs}
                            page={page}
                            handleChangePage={handleChangePage}
                            sortAsc={sortAsc}
                            setSortAsc={setSortAsc}
                        />
                    </>
                ) : <Redirect to="/" />
            }   
        </div>
    );
};

export default Jobs;