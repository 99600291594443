/**
 * Librerias de react o terceros
 */
import React from "react";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.locale("es");
dayjs.extend(relativeTime);

const NewsCard = ({
    titulo,
    descripcion,
    fechaActualizacion,
    imagenPrincipal,
    direccion,
}) => {
    return (
        <div className="tw-my-2 tw-max-w-xs tw-mx-auto tw-border tw-shadow-lg tw-rounded-md tw-bg-white">
            <div className="tw-relative">
                <img
                    className="tw-object-cover tw-h-52 tw-w-full tw-rounded-t-sm"
                    src={imagenPrincipal}
                    alt=""
                />
                <div className=" tw-absolute tw-bottom-0 tw-py-1 tw-w-full tw-flex tw-flex-col tw-items-start tw-bg-opacity-70 tw-bg-gray-500 tw-text-white">
                    <p className="tw-ml-2 tw-text-base tw-font-medium">
                        {titulo.slice(0, 35)}...
                    </p>
                    <p className="tw-text-xs tw-font-semibold tw-text-gray-200 tw-ml-2">
                        {dayjs(fechaActualizacion).fromNow()}
                    </p>
                </div>
            </div>
            <div className="tw-p-3">
                <span className="tw-leading-snug tw-text-justify">
                    {descripcion.slice(0, 150)}{" "}
                    <NavLink
                        to={/noticias/ + direccion}
                        className="tw-text-blue-500"
                    >
                        Ver más...
                    </NavLink>
                </span>
            </div>
        </div>
    );
};

export default NewsCard;