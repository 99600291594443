import React from 'react';

const SelectImage = ({mainImagePrevew, handleMainImage}) => {
    return (
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-items-center">
            <label className="tw-w-72 lg:tw-w-96 tw-flex tw-flex-col tw-items-center tw-px-3 tw-py-2 tw-bg-white tw-text-blueOil tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-border-blue-500 tw-cursor-pointer hover:tw-bg-blue-500 hover:tw-text-white">
                <svg
                    className="tw-w-8 tw-h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="tw-mt-2 tw-text-base tw-leading-normal">
                    Seleccione una imagen principal
                </span>
                <input
                    accept="image/*"
                    type="file"
                    className="tw-hidden"
                    onChange={handleMainImage}
                />
            </label>
            <div className="tw-flex tw-items-center tw-border tw-border-dashed tw-mt-2 lg:tw-mt-0 md:tw-ml-3 tw-w-28 tw-h-24">
                {mainImagePrevew ? (
                    <img
                        className="tw-object-contain tw-h-20 tw-w-full"
                        src={mainImagePrevew}
                        alt="imagen principal del contenido"
                    />
                ) : null}
            </div>
        </div>
    );
};

export default SelectImage;