/**
 * Librerias de react o terceros
 */
import React from "react";
import { useField } from "formik";

const InputFieldTextArea = ({
    label,
    classContainer,
    classLabel,
    classTextArea,
    classError,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <div className={`${classContainer}`}>
            <label className={`${classLabel}`}>{label}</label>
            <textarea
                className={`${classTextArea}`}
                maxLength="400"
                {...field}
                {...props}
            />
            {/* hay errores que mostrar? */}
            {meta.touched && meta.error && (
                <p className={`${classError}`}>{meta.error}</p>
            )}
        </div>
    );
};

//Propiedades por defecto
InputFieldTextArea.defaultProps = {
    classContainer: "tw-mx-auto",
    classLabel: "tw-text-lg tw-mr-2",
    classTextArea: "",
    classError: "tw-text-red-500",
};

export default InputFieldTextArea;