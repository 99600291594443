import React, { useContext, useState } from 'react';
import axios from "axios";
import { AppContext } from '../../../context/AppContext';
import { UserContext } from '../../../context/UserContext';
import Loader from '../../atoms/Loader';
import { ADMIN } from '../../../constants/AuthStatus';
const UserData = ({userRecived, handleUsers}) => {
    const [user, setUser] = useState(userRecived);

    const appContext = useContext(AppContext);
    const userContext = useContext(UserContext);
    const {handleAlertMessage, setAlertRender} = appContext;
    const {userLogged} = userContext;

    const [deleted, setDeleted] = useState(false);

    const editState = (state) => {

        if(user.id === userLogged.id){
            handleAlertMessage('Alerta', 'No puedes cambiar tu propio estado', 'alert');
            setAlertRender(true);
        }else{
            setUser(null);
            const newState = async () => {
                try {
                    let url = `api/users/updateState/${user.id}`;
                    const response = await axios.patch(url, {state : state});
                    //comprobamos que tipo de respuesta no entrego la api
                    //si fue un mensaje o los datos requeirdos
                    if (response.data.message) {
                        handleAlertMessage(response.data.title, response.data.message, response.data.type);
                        setAlertRender(true);
                        return false;
                    }
                    return response.data;
                } catch (error) {
                    // console.log(error);
                    return false;
                }
            };
            newState().then((response) => {
                // //si no hay contenidos o hubo un error, entonces se retorno falso
                if (response){
                    //como hubo un contenido obtenemos la data
                    setUser(response);
                    if(state === 'rechazada'){
                        handleUsers();
                    }
                }
            });
        }
    };

    const editType = (type) => {

        if(user.id === userLogged.id){
            handleAlertMessage('Alerta', 'No puedes cambiar tu propio estado', 'alert');
            setAlertRender(true);
        }else{
            setUser(null);
            const newType = async () => {
                try {
                    let url = `api/users/updateType/${user.id}`;
                    const response = await axios.patch(url, {type : type});
                    //comprobamos que tipo de respuesta no entrego la api
                    //si fue un mensaje o los datos requeirdos
                    if (response.data.message) {
                        handleAlertMessage(response.data.title, response.data.message, response.data.type);
                        setAlertRender(true);
                        return false;
                    }
                    return response.data;
                } catch (error) {
                    // console.log(error);
                    return false;
                }
            };
            newType().then((response) => {
                // //si no hay contenidos o hubo un error, entonces se retorno falso
                if (response){
                    //como hubo un contenido obtenemos la data
                    setUser(response);
                }
            });
        }
    };

    const deleteUser = (value) => {
        console.log(value);
        if(user.id === userLogged.id){
            handleAlertMessage('Alerta', 'No puedes cambiar tu propio estado', 'alert');
            setAlertRender(true);
        }else{ 
            // setUser(null);
            const eliminar = async () => {
                console.log('asdasd');
                try {
                    let url = `api/users/${user.id}`;
                    const response = await axios.delete(url);
                    //comprobamos que tipo de respuesta no entrego la api
                    //si fue un mensaje o los datos requeirdos
                    console.log(response);
                    if (response.data.message) {
                        handleAlertMessage(response.data.title, response.data.message, response.data.type);
                        setAlertRender(true);

                        if(response.data.type === 'success') {
                            return true;
                        } 

                        return false;
                    }
                    return false;
                } catch (error) {
                    console.log(error);
                    return false;
                }
            };
            eliminar().then((response) => {
                // //si no hay contenidos o hubo un error, entonces se retorno falso
                if (response){

                    //como hubo un contenido obtenemos la data
                    // setUser(response);
                    setDeleted(true);
                }
            });
        }
        console.log('asdfsadf');
    }

    return (
        
        <div className="tw-container tw-p-2 tw-mx-auto">
            {
                user === null ? <Loader /> : (
                    <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-bg-gray-700">
                    
                        <div className="tw-flex tw-justify-center lg:tw-justify-start  tw-items-start tw-w-full lg:tw-w-3/12 tw-mt-5">
                            <img className="tw-mx-2 tw-object-scale-down tw-w-3/12 lg:tw-w-full tw-rounded-full lg:tw-rounded-none" src={user.imagen_perfil} alt="imagen usuario"/>
                        </div>

                        <div className="tw-flex tw-flex-col tw-items-center lg:tw-items-start tw-w-full lg:tw-w-9/12 tw-px-6 tw-py-2">
                            <h1 className="tw-text-xl tw-text-white tw-font-semibold tw-text-center sm:tw-text-left">Información personal</h1>
                            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-mb-2  tw-bg-white tw-mx-auto sm:tw-mx-0"></hr>
                        
                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-7/12 tw-px-3">
                                    <span>Nombre:</span> <p className="tw-inline tw-text-green-400">{user.nombre} {user.apellido_paterno} {user.apellido_materno}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-5/12 tw-px-3">
                                    <span>Rut:</span> <p className="tw-inline tw-text-green-400">{user.rut}</p>
                                </div>
                            </div>
                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-4/12 tw-px-3">
                                    <span>Nacionalidad:</span> <p className="tw-inline tw-text-green-400">{user.nacionalidad}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-3/12 tw-px-3">
                                    <span>Sexo:</span> <p className="tw-inline tw-text-green-400">{user.sexo}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-5/12 tw-px-3">
                                    <span>Fecha de nacimiento:</span> <p className="tw-inline tw-text-green-400">{user.fecha_nacimiento}</p>
                                </div>
                            </div>
                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Teléfono:</span> <p className="tw-inline tw-text-green-400">{user.telefono}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Celular:</span> <p className="tw-inline tw-text-green-400">{user.celular}</p>
                                </div>
                            </div>
                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Correo:</span> <p className="tw-inline tw-text-green-400">{user.email}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Dirección:</span> <p className="tw-inline tw-text-green-400">{user.direccion}</p>
                                </div>
                            </div>
                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Cuidad:</span> <p className="tw-inline tw-text-green-400">{user.ciudad}</p>
                                    
                                </div>
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Comuna:</span> <p className="tw-inline tw-text-green-400">{user.comuna}</p>
                                </div>
                            </div>
                            <div className="tw-w-full sm:tw-w-6/12 tw-px-3 tw-mb-1 tw-text-white tw-font-medium">
                                <span>Región:</span> <p className="tw-inline tw-text-green-400">{user.region}</p>
                            </div>
                            
                            <h1 className="tw-text-xl tw-text-white tw-font-semibold tw-text-center sm:tw-text-left">Información laboral</h1>
                            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-mb-2  tw-bg-white tw-mx-auto sm:tw-mx-0"></hr>

                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Teléfono:</span> <p className="tw-inline tw-text-green-400">{user.telefono_laboral}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Cargo:</span> <p className="tw-inline tw-text-green-400">{user.cargo}</p>
                                </div>
                            </div>

                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Dirección:</span> <p className="tw-inline tw-text-green-400">{user.direccion_laboral}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Cuidad:</span> <p className="tw-inline tw-text-green-400">{user.ciudad_laboral}</p>
                                </div>
                            </div>
                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-6/12 tw-px-3">
                                    <span>Comuna:</span> <p className="tw-inline tw-text-green-400">{user.comuna_laboral}</p>
                                </div>
                            </div>
                        
                            <h1 className="tw-text-xl tw-font-semibold tw-text-white tw-text-center sm:tw-text-left">Información academica</h1>
                            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-mb-2  tw-bg-white tw-mx-auto sm:tw-mx-0"></hr>

                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-7/12 tw-px-3">
                                    <span>Casa de estudio:</span> <p className="tw-inline tw-text-green-400">{user.casa_estudio}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-5/12 tw-px-3">
                                    <span>Fecha titulación:</span> <p className="tw-inline tw-text-green-400">{user.fecha_titulacion}</p>
                                </div>
                            </div>
                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full tw-px-3">
                                    <span>Código validación título:</span> <p className="tw-inline tw-text-green-400">{user.codigo_validacion_titulo}</p>
                                </div>
                            </div>
                        
                            <h1 className="tw-text-xl tw-font-semibold tw-text-white tw-text-center sm:tw-text-left">Información de la cuenta</h1>
                            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-bg-white tw-mb-2 tw-mx-auto sm:tw-mx-0"></hr>

                            <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-mb-1 tw-text-white tw-font-medium">
                                <div className="tw-w-full sm:tw-w-1/3 tw-px-3">
                                    <span>Estado de la cuenta:</span> <p className="tw-inline tw-text-green-400">{user.estado}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-1/3 tw-px-3">
                                    <span>Tipo de afiliación:</span> <p className="tw-inline tw-text-green-400">{user.tipo_afiliacion}</p>
                                </div>
                                <div className="tw-w-full sm:tw-w-1/3 tw-px-3">
                                    <span>Tipo de cuenta:</span> <p className="tw-inline tw-text-green-400">{user.tipo_usuario}</p>
                                </div>
                            </div> 
                            <div className="tw-flex tw-flex-wrap tw-my-3 tw-justify-center sm:tw-justify-end tw-text-gray-200 tw-text-center">
                                {
                                    deleted ? (
                                        <p className="tw-text-xl tw-text-red-300">Usuario eliminado</p>
                                    ) : (
                                        user.estado === "activa" ? (
                                            <div className="tw-flex tw-justify-between">
                                                <button className="tw-bg-red-800 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                                                        onClick = {()=>{deleteUser(user.id)}}
                                                >
                                                    Eliminar cuenta
                                                </button>
                                                <button className="tw-bg-red-800 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                                                        onClick = {()=>{editState('suspendida')}}
                                                >
                                                    Suspender cuenta
                                                </button>
                                                {
                                                    user.tipo_usuario === ADMIN ? (
                                                        <button className="tw-bg-red-800 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                                                                onClick = {()=>{editType('normal')}}
                                                        >
                                                            Quitar permisos de administrador
                                                        </button> 
                                                    ) : ( 
                                                        <button className="tw-bg-yellow-600 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                                                                onClick = {()=>{editType('admin')}}
                                                        >
                                                            Dar permisos de administrador
                                                        </button> 
                                                     )
                                                }
                                            </div>
                                        ) : (
                                            user.estado === "suspendida" ? (
                                                <button className = "tw-bg-green-700 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                                                    onClick = {()=>{editState('activa')}}
                                                >
                                                        Activar cuenta
                                                </button> 
                                    
                                            ) : (
                                                user.estado === "rechazada" ? null : (
                                                    <>
                                                        <button className = "tw-bg-green-700 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                                                                onClick = {()=>{editState('activa')}}
                                                        >
                                                            Aceptar solicitud
                                                        </button> 
                                        
                                                        <button className="tw-bg-red-800 tw-rounded-md tw-mr-2 tw-mt-4 lg:tw-mt-0 tw-p-3 tw-font-medium"
                                                                onClick = {()=>{editState('rechazada')}}
                                                        >
                                                            Rechazar solicitud
                                                        </button> 
                                                    </>
                                                )
                                            )
                                        )
                                    )
                                }
                            </div>    
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default UserData;
