import React, { useContext, useEffect, useState } from 'react';
import { FiTrash2, FiEdit2 } from "react-icons/fi";
import DocumentsTable from '../../organisms/tables/DocumentsTable';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import UploadDocument from '../forms/UploadDocument';
import { AppContext } from '../../../context/AppContext';
import { ADMIN } from '../../../constants/AuthStatus';
import { makeStyles }       from '@material-ui/core/styles';
import Pagination           from '@material-ui/lab/Pagination';
import UpdateSection from '../../organisms/UpdateSection';
import Loader from '../../atoms/Loader';

/**
 * Estilos para la paginacion
 */
 const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const SectionRow = ({id, nombre, filterSection}) => {

    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;

    const userContext = useContext(UserContext);
    const { userLogged } = userContext;

    const [sectionName, setSectionName] = useState(nombre);
    const [documents, setDocuments] = useState(null);
    const [editSection, setEditSection] = useState(false);
    const [deleteSectionForm, setDeleteSectionForm] = useState(false);
    const [sending, setSending] = useState(false);
    //Opciones de cada consulta
    //ordenado por?
    const [sortBy] = useState("updated_at");
    const [sortAsc] = useState(false);
    //n° de pagina
    const [page, setPage] = useState(1);
    //n° por pagina
    const [perPage] = useState(5);
    const [totalDocuments, setTotalDocuments] = useState(0);
    
    //estilo paginacion
    const classes = useStyles();

    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setDocuments(null);
        setPage(value);
    };


    const handleEdit = () => {
        setEditSection(!editSection);
    }

    const handleDelete = () => {
        setDeleteSectionForm(!deleteSectionForm);
    }
    
    useEffect(() => {
        const getDocuments = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/sections/${id}/documents?sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;

                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                // console.log(response);
                if (response.data.message) {
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getDocuments().then((response) => {
            if (response) {
                setTotalDocuments(response.last_page);
                setDocuments(response.data);
            } else {
                setDocuments([]);
            }
        });
    }, [id, sortBy, sortAsc, perPage, page])

    const getDocuments = () => {
        const getDocuments = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/sections/${id}/documents?sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;

                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                // console.log(response);
                if (response.data.message) {
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getDocuments().then((response) => {
            if (response) {
                setTotalDocuments(response.last_page);
                setDocuments(response.data);
            } else {
                setDocuments([]);
            }
        });
    }

    const deleteDocument = (value) => {
        
        const deleteDocument = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/documents/${value}`;
                const response = await axios.delete(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    handleAlertMessage(response.data.title, response.data.message, response.data.type);
                    setAlertRender(true);
                }
                return true;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        deleteDocument().then((response) => {
            getDocuments();
        });
    }

    const deleteSection  = (value) => {
        setSending(true);

        const deleteSection = async (value) => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/sections/${value}`;
                const response = await axios.delete(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    handleAlertMessage(response.data.title, response.data.message, response.data.type);
                    setAlertRender(true);
                    return true;
                }
                return false;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        deleteSection(value).then((response) => {
            if (response) {
                filterSection(value);
                handleDelete();
            } 
            setSending(false);
        });
    }

    return (
        <div className="tw-mt-2 tw-mb-4 tw-w-11/12 tw-mx-auto tw-relative">
            {
                sending ? (<Loader />) : (
                    <>
                        {
                            userLogged.tipo_usuario === ADMIN ? (
                                <div className="tw-absolute tw-top-2 tw-left-2/3 lg:tw-left-1/3 tw-pl-4 tw-flex tw-space-x-4">
                                    <button className="tw-bg-yellow-300 tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-9 tw-h-9 lg:tw-w-10 lg:tw-h-10 tw-p-2"
                                        onClick={()=>{handleEdit()}}
                                    >
                                        <FiEdit2 className="tw-text-4xl tw-text-black"/>
                                    </button>

                                    <button className="tw-bg-red-700 tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-9 tw-h-9 lg:tw-w-10 lg:tw-h-10 tw-p-2"
                                        onClick={()=>{handleDelete()}}
                                    >
                                        <FiTrash2 className="tw-text-4xl tw-text-white"/>
                                    </button>
                                </div>
                            ) : (null)
                        }
                        <div className=" tw-w-8/12 lg:tw-w-1/3 tw-h-16 tw-bg-gray-700 tw-rounded-t-lg">
                            <h1 className="tw-p-3 tw-text-center tw-text-xl tw-text-white tw-break-words">{sectionName}</h1>
                        </div>
                        {
                            editSection ? (
                                <UpdateSection id={id} handleEdit={handleEdit} setSectionName = {setSectionName} />
                            ) : (null)
                        }
                        {
                            deleteSectionForm ? (
                                
                                    <div className="tw-flex tw-flex-col tw-space-y-3 tw-bg-gray-600 tw-p-5">
                                        <span className="tw-text-white">
                                            Está seguro de eliminar la sección? Si elimina la sección se borrarán todos los documentos dentro de esta.
                                        </span>
                                        <div className="tw-flex tw-space-x-4">
                                            <button className="tw-bg-red-700 hover:tw-bg-red-800 tw-p-2 tw-text-white tw-rounded-md tw-shadow-lg"
                                                onClick={()=>{deleteSection(id)}}
                                            >
                                                Sí, eliminar
                                            </button>
                                            <button className="tw-bg-yellow-600 hover:tw-bg-yellow-800 tw-p-2 tw-text-white tw-rounded-md tw-shadow-lg"
                                                onClick={()=>{handleDelete()}}
                                            >
                                                No eliminar
                                            </button>
                                        </div>
                                    </div>
                            ) : (null)
                        }
                        <div className="tw-bg-gray-700 tw-py-2 tw-rounded-b-lg tw-rounded-tr-lg">    
                            <DocumentsTable idSection = {id} documents = {documents} deleteDocument={deleteDocument} />
                            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-4 lg:tw-space-y-0 tw-items-center tw-justify-between tw-mt-3 tw-mx-2">
                                {
                                    userLogged.tipo_usuario === ADMIN ? (
                                        <UploadDocument idSection = {id} getDocuments={getDocuments} />
                                    ) : (null)
                                }
                                <div className={`${classes.root}`}>
                                    <Pagination count={totalDocuments} page={page} onChange={handleChangePage} color="primary" />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default SectionRow;
