import React, { useContext } from 'react';

import { AiFillContacts, AiFillPhone } from "react-icons/ai";
import { MdLocationOn, MdTimelapse } from "react-icons/md";
import { BiHide } from "react-icons/bi";

import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import ContentEditable from 'react-contenteditable';
import UpdateContentButton from '../../atoms/buttons/UpdateContentButton';
import DeleteContentButton from '../../atoms/buttons/DeleteContentButton';
import { UserContext } from '../../../context/UserContext';
import { ADMIN } from '../../../constants/AuthStatus';
dayjs.locale("es");
dayjs.extend(relativeTime);

const JobData = ({selectedJob, setSelectedJob}) => {
    const userContext = useContext(UserContext);
    const {userLogged} = userContext;
    return (
        <div className="tw-pt-12 tw-relative">
            <div className="tw-flex tw-space-x-2 tw-absolute tw-top-6 tw-right-0 lg:tw-right-48">
                <button className="tw-flex tw-justify-center tw-items-center tw-border tw-bg-gray-700 tw-rounded-full tw-w-10 tw-h-10 tw-p-2"
                    onClick={() => {setSelectedJob(null)}}
                >
                    <BiHide className="tw-text-4xl tw-text-white"/>
                </button>
                {
                    userLogged.tipo_usuario === ADMIN ? (
                        <>
                        <UpdateContentButton typeContent={2} redirectTo="trabajos" titleContent={selectedJob.url} />
                        <DeleteContentButton typeContent={2} redirectTo="trabajos" titleContent={selectedJob.url}/>
                        </>
                    ) : (null)
                }
            </div>
            <div className="tw-bg-gray-700 tw-w-full lg:tw-w-8/12 tw-mx-auto tw-my-5 tw-p-3 tw-rounded-md tw-shadow-xl">
                <h1 className="tw-text-center tw-text-2xl tw-text-white tw-mb-3">{selectedJob.titulo}</h1>
                <div className="tw-flex tw-flex-col lg:tw-flex-row">
                    <div className="tw-w-full lg:tw-w-5/12 lg:tw-pr-2">
                        <img alt="imagen trabajo" className="tw-object-cover tw-object-center tw-mx-auto tw-w-60 md:tw-w-1/5 lg:tw-w-2/3 tw-mb-2 tw-bg-gray-700 tw-rounded" src={selectedJob.imagen_principal} />
                        <p className="tw-text-lg tw-text-white">
                            <AiFillContacts className="tw-inline-block tw-text-2xl tw-text-blue-600"/> {selectedJob.contacto}
                        </p>
                        
                        <p className="tw-text-lg tw-text-white  ">
                            <AiFillPhone className="tw-inline-block tw-text-2xl tw-text-yellow-600 tw-transform tw-rotate-90"/> {selectedJob.telefono}
                        </p>
                        
                        <p className="tw-text-lg tw-text-white  ">
                            <MdLocationOn className="tw-inline-block tw-text-2xl tw-text-green-600"/> {selectedJob.ubicacion}
                        </p>
                        <p className="tw-text-md tw-text-gray-200 tw-my-2 tw-text-right">
                            {dayjs(selectedJob.updated_at).fromNow()}
                        </p>
                        <p className="tw-bg-yellow-600 tw-p-2 tw-rounded-sm tw-text-white">
                            <MdTimelapse className="tw-inline-block tw-text-2xl tw-text-white"/> {selectedJob.tipo}
                        </p>
                    </div>
                    <div className="tw-w-full lg:tw-w-7/12 tw-bg-white">
                        <h1 className="tw-text-white tw-text-lg tw-bg-gray-700">Detalles:</h1>
                        <div className="sun-editor-editable ">
                            <ContentEditable
                                // innerRef={this.contentEditable}
                                html={selectedJob.contenido} // innerHTML of the editable div
                                disabled={true} // use true to disable editing
                                // onChange={this.handleChange} // handle innerHTML change
                                tagName="article" // Use a custom HTML tag (uses a div by default)
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobData;
