/**
 * Librerias de react o terceros
 */
import React, { useContext } from "react";

/**
 * contextos
 */
import { UserContext } from "../../../context/UserContext";

/**
 * Iconos
 */
import { IoMdLogOut } from "react-icons/io";

const LogoutButton = () => {
    // Propiedades a usar del contexto
    const userContext = useContext(UserContext);
    const { logout } = userContext;

    return (
        <button
            className="tw-mx-auto tw-p-1 tw-rounded-full focus:tw-outline-none tw-bg-red-500"
            onClick={() => {
                logout();
            }}
        >
            <IoMdLogOut className="tw-text-xl tw-text-white" />
        </button>
    );
};

export default LogoutButton;