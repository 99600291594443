/**
 * Librerias de react o terceros
 */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Formik, Field, Form } from 'formik';
/**
 * Contextos
 */
import { UserContext } from "../../context/UserContext";
import UsersTable from '../organisms/tables/UsersTable';
import Paginate from "../molecules/generals/Paginate";
import { Redirect } from "react-router";
import { AppContext } from "../../context/AppContext";
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
import SearchContent from "../organisms/SearchContent";

const Affiliates = () => {

    const userContext = useContext(UserContext);
    const { userLogged, authStatus } = userContext;
    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;
    //Estado para manejar las noticias
    const [users, setUsers] = useState(null);

    const [userState, setUserState] = useState('');
    //Opciones de cada consulta
    //ordenado por?
    const [sortBy] = useState("created_at");
    const [sortAsc, setSortAsc] = useState(false);
    //n° de pagina
    const [page, setPage] = useState(1);
    //n° por pagina
    const [perPage] = useState(18);
    const [totalUsers, setTotalUsers] = useState(0);

    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setUsers(null);
        setPage(value);
    };
 
    const handleUsers = () =>  {
        setUsers(null);
        setUserState('');
        setUserState('all');
    }

    useEffect(() => {
        const getUsers = async () => {
            try {

                let url = `api/users?sort_by=${sortBy}${
                sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}${userState !== 'all' ? `&estado=${userState}`: ''}`;

                let response = await axios.get(url);
                // console.log(url);
                //comprobamos que tipo de respuesta no entrego la api
                //si fue un mensaje o los datos requeirdos
                // console.log(response);
                if (response.data.message) {
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };
        getUsers().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            
            if (!response) {
                //array vacio, se mostrará sin contenido
                setUsers([]);
            } else {
                //como hubo un contenido obtenemos las noticias
                setUsers(response.data);
                setTotalUsers(response.last_page);
            }
        });
    }, [sortBy, sortAsc, perPage, page, userState]);

    const handleFilter = (value) => {
        if(value.picked !== userState){
            setUsers(null);
            setUserState(value.picked);
        }
    }
    const handleSearch = (value) => {
        setUsers(null);
        // setSearchText(value.search_content);
        const getUsers = async () => {
            try {
                
                let url = `api/users/search`;
                let response = await axios.post(url, {searchText: value.search_content});
                
                //comprobamos que tipo de respuesta no entrego la api
                //si fue un mensaje o los datos requeirdos
                console.log(response);
                if (response.data.message) {
                    return false;
                }else if(response.data.data.length === 0){
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
            }
        };
        getUsers().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            if (!response) {
                //array vacio, se mostrará sin contenido
                setUserState('');
                setUserState('all');
                handleAlertMessage('Sin contenido', 'El valor buscado no fue encontrado', 'alert');
                setAlertRender(true);
            } else {
                //como hubo un contenido obtenemos las noticias
                setTotalUsers(response.last_page);
                setUsers(response.data);
            }
        });
    }

    return (
        <>
            {   
                authStatus === LOGGED_IN ? (
                    userLogged.tipo_usuario === ADMIN ? (
                        
                        <div className="tw-flex tw-flex-col tw-w-full tw-p-2 tw-mb-2">
                            {/* <h1 className="tw-text-3xl md:tw-text-4xl tw-my-5 tw-font-semibold tw-text-center tw-text-gray-700">Afiliados</h1> */}

                            <div className="tw-flex tw-flex-col xl:tw-flex-row tw-justify-center tw-items-center tw-my-10">
                                <div className="tw-w-full lg:tw-w-3/6 tw-mb-5 xl:tw-mb-0">
                                    <SearchContent handleSearch={handleSearch}/>
                                </div>
                                <Formik
                                    initialValues={{
                                        picked: userState,
                                    }}
                                    onSubmit={(values) => {
                                        handleFilter(values);
                                    }}
                                    >
                                    {({ values }) => (
                                        <Form>
                                            <div role="group" aria-labelledby="my-radio-group" className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-border tw-mx-2">
                                                <label className="tw-ml-5 tw-mr-2">
                                                    <span>
                                                        <Field type="radio" name="picked" value="activa" /> Activas
                                                    </span>
                                                </label>
                                                <label className="tw-mx-2">
                                                    <span>
                                                        <Field type="radio" name="picked" value="suspendida" /> Suspendidas
                                                    </span>
                                                </label>
                                                <label className="tw-mx-2">
                                                    <span>
                                                        <Field type="radio" name="picked" value="desactivada" /> Nuevos
                                                    </span>
                                                </label>
                                                <label className="tw-mx-2">
                                                    <span>
                                                        <Field type="radio" name="picked" value="all" /> Todas
                                                    </span>
                                                </label>
                                    
                                                <button className="tw-bg-gray-700 tw-rounded-md tw-text-white tw-px-2 tw-py-1" type="submit">Filtrar</button>
                                            </div>
                                        </Form>
                                    )}
                                    </Formik>
                            </div>

                            <Paginate
                                totalContent={totalUsers}
                                page={page}
                                handleChangePage={handleChangePage}
                                sortAsc={sortAsc}
                                setSortAsc={setSortAsc}
                            />
                            <UsersTable users={users} handleUsers={handleUsers} />
                            <Paginate
                                totalContent={totalUsers}
                                page={page}
                                handleChangePage={handleChangePage}
                                sortAsc={sortAsc}
                                setSortAsc={setSortAsc}
                            />
                        </div>
                    ) : <Redirect to ="/" /> 
                ) : <Redirect to ="/" />
            }
        </>

    );
};

export default Affiliates;