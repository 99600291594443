import React from 'react';
import { useField } from 'formik';
const InputField = ({ label, classContainer, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className={`${classContainer}`}>
            <label className="tw-block tw-text-white tw-font-medium tw-mb-2">
                {label}
            </label>
            <input className="tw-block tw-w-full tw-bg-gray-200 tw-border-2 tw-border-gray-200 tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white focus:tw-border-green-500" 
                    {...field} 
                    {...props} 
            />
            {meta.touched && meta.error && (
                <p className="tw-text-red-400">{meta.error}</p>
            )}
        </div>
    );
};

const RecoverPassForm = ({handleChange, handleSubmit, isSubmitting, resetForm, values, errors, touched, cancelChangePass}) => {

	return (
        <form onSubmit={handleSubmit} className="tw-w-full">
            <h1 className="tw-text-xl tw-font-semibold tw-text-white tw-text-center sm:tw-text-left">Cambia tu contraseña</h1>
            <hr className="tw-w-1/2 tw-h-px tw-rounded tw-bg-white tw-mb-2 tw-mx-auto sm:tw-mx-0"></hr>

            <div className="tw-flex tw-text-lg tw-flex-wrap tw-mb-6">
                <InputField classContainer = "tw-w-full tw-px-3" name="codeVerify" type="text" label="Código de verificación" />
            </div>

            <div className="tw-flex tw-text-lg tw-flex-wrap tw-mb-3">
                <InputField classContainer = "tw-w-full tw-px-3" name="passNew" type="password" label="Contraseña Nueva" />
            </div>
            <div className="tw-flex tw-text-lg tw-flex-wrap tw-mb-3">
                <InputField classContainer = "tw-w-full tw-px-3" name="passNewAgain" type="password" label="Repetir Contraseña Nueva" />
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-1 tw-justify-center tw-text-gray-200 tw-text-center">
                <button className = "tw-bg-red-600 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        type="button"
                        onClick = {cancelChangePass}
                >
                    Cancelar
                </button> 

                <button className="tw-bg-green-700 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        type="submit"
                >
                    Actualizar
                </button> 
            </div>

        </form>	
    );
};

export default RecoverPassForm;