/**
 * Librerias de react o terceros
 */
import React from "react";

/**
 * Iconos
 */
import { FaBomb } from "react-icons/fa";

const ErrorPage = () => {
    return (
        <div className="tw-mx-auto tw-my-10 tw-w-11/12">
            <div className="tw-text-center">
                <FaBomb
                    className="tw-text-gray-700 tw-m-4 tw-mx-auto tw-text-8xl"
                />
                <p className="tw-text-3xl tw-text-gray-700">
                    La página solicitada no existe.
                </p>
            </div>
        </div>
    );
};

export default ErrorPage;