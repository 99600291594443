import React, {useState, useContext} from 'react';
import { Formik } from "formik";
import Loader from '../atoms/Loader';
import axios from "axios";
import { AppContext } from '../../context/AppContext';
import UpdateSectionForm from '../molecules/forms/UpdateSectionForm';


const validation = (values) => {
    let errors = {};
    if (!values.nombre) {
        errors.nombre = "No puede ser vacío.";
    }else if (values.nombre.length >= 30 ){
        errors.nombre = "Muy extenso.";
    }
    return errors;
};

const UpdateSection = ({id, handleEdit, setSectionName}) => {
    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;

    const [sending, setSending] = useState(false);

    const updateSection = (values) => {

        setSending(true);

        const sendSection = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/sections/${id}`;
                const response = await axios.put(url, {nombre: values.nombre});
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    handleAlertMessage(response.data.title, response.data.message, response.data.type);
                    setAlertRender(true);
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        sendSection().then((response) => {
            if (response) {
                handleAlertMessage('Sección actualizada', 'Sección actualizada correctamente.', 'success');
                setAlertRender(true);
                setSectionName(response.nombre);
                handleEdit();
            } 
            setSending(false);
        });
    }

    return (
        <>
            {
                sending ? (
                    <Loader />
                ) : (
                    <Formik
                        initialValues={{
                            //Datos de 
                            nombre: "",
                    
                        }}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                            actions.resetForm(true);
                            updateSection(values);       
                        }}

                        validate={validation}
                        
                        >
                        {props => <UpdateSectionForm {...props} handleEdit={handleEdit}   />}
                    </Formik>
                )
            }
            
        </>
    );
};

export default UpdateSection;
