/**
 * Librerias de react o terceros
 */
import React, { useContext, useState } from "react";
import { Formik } from "formik";
import axios from "axios";
/**
 * Contextos
 */
import { UserContext } from "../../context/UserContext";

/**
 * Componentes
 */
import LoginForm from "../molecules/forms/LoginForm";
// import Logo from "../atoms/anims/Logo";

/**
 * Contantes
 */
import { NOT_LOGGED_IN } from "../../constants/AuthStatus";
import { Redirect } from "react-router";
import SendEmailPassForm from "../molecules/forms/SendEmailPassForm";
import RecoverPassForm from "../molecules/forms/RecoverPassForm";
import Loader from "../atoms/Loader";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";

/**
 * Validacion de los datos recibidos por el formulario
 */
const validation = (values) => {
    let errors = {};

    if (!values.rut) {
        errors.rut = "Ingresa tu rut!";
    }
    if (!values.password) {
        errors.password = "Ingresa tu contraseña!";
    }
    return errors;
};

const validationEmail = (values) => {
    let errors = {};

    if (!values.email) {
        errors.email = "Ingresa tu email!";
    }

    return errors;
};

const validationPass = values => {
    let errors = {};
  
    if (!values.codeVerify) {
      errors.passOld = "Ingresa el código de verificación que enviamos a tu correo!";
    }
    if(!values.passNew){
       errors.passNew = "Ingresa tu contraseña nueva!";
    }
    if(!values.passNewAgain){
        errors.passNewAgain = "Ingresa tu contraseña nueva!";
    }

    if(values.passNew && values.passNewAgain){
        if(values.passNew !== values.passNewAgain){
            errors.passNewAgain = "las contraseñas no coinciden";
        }
    }

    return errors;
};

const Login = () => {
    //Porpiedades del contexto a usar
    const userContext = useContext(UserContext);
    //obtenemos las funciones de login y logout, ademas del estado de autenticacion para redireccionar
    const { login, authStatus } = userContext;

    const appContext = useContext(AppContext);
    const {handleAlertMessage, setAlertRender} = appContext;

    const [recoverEmail, setRecoverEmail] = useState(false);
    const [changePass, setChangePass] = useState(false);
    const [sending, setSending] = useState(false);
    const cancelRecoverPass = () => {
        setRecoverEmail(false);
        setChangePass(false);
    }

    const sendEmail = (values) => {
        setSending(true);
        const sendEmailtoken = async () => {
            try {
                
                let url = `api/users/resetPasswordEmail`;
                let response = await axios.post(url, values);

                if(response.data.message){
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        response.data.title,
                        response.data.message,
                        response.data.type
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
                    if(response.data.type === 'error' || response.data.type === 'alert'){
                        return false;
                    }

                    return true;
                }
                return false;
            } catch (error) {
                return false;
            }
        };
        sendEmailtoken().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            setSending(false);

            if (response) {
                setChangePass(true);
            } else{
                    handleAlertMessage(
                        'Alerta',
                        'No fue posible enviar el correo con el código de verificación',
                        'alert'
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
            }
        });
    }

    const sendPassword = (values) => {
        // console.log(values);
        setSending(true);
        const sendPasswordData = async () => {
            try {
                
                let url = `api/users/resetPassword`;
                let response = await axios.post(url, values);
                if(response.data.message){
                    // Elevamos el titulo, mensaje y el tipo de mensaje
                    handleAlertMessage(
                        response.data.title,
                        response.data.message,
                        response.data.type
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);

                    return true;
                }
                return false;
            } catch (error) {
                return false;
            }
        };
        sendPasswordData().then((response) => {
            //si no hay contenidos o hubo un error, entonces se retorno falso
            setSending(false);

            if (response) {
                setRecoverEmail(false);
                setChangePass(false);
            } else{
                    handleAlertMessage(
                        'Alerta',
                        'No fue posible actualizar la contraseña',
                        'alert'
                    );
                    // Activamos para que se renderice la alerta
                    setAlertRender(true);
            }
        });
    }
    //Si no está logueado entonces mostramos el formulario
    if (authStatus === NOT_LOGGED_IN) {
        return (
            <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-my-5">
                    <div className="tw-flex tw-flex-col tw-items-center tw-w-full lg:tw-w-4/12 tw-py-8 fondo_login">
                        <div className="tw-w-3/6">
                            <h1 className="tw-text-white tw-text-4xl">
                                Hola, Bienvenido
                            </h1>
                            <hr className="tw-mx-auto tw-bg-white tw-h-px tw-rounded tw-mb-5"></hr>
                        </div>
                        {
                            recoverEmail ? (
                                sending ? <Loader /> : (
                                    changePass ? (
                                        <div className="tw-w-4/5">
                                            <Formik
                                                initialValues={{
                                                    //Datos de 
                                                    codeVerify: "",
                                                    passNew: "",
                                                    passNewAgain: ""
                                            
                                                }}
                                                onSubmit={(values, actions) => {
                                                    actions.setSubmitting(false);
                                                    actions.resetForm(true);
                                                    sendPassword(values);            
                                                }}

                                                validate={validationPass}
                                                
                                                >
                                                {props => <RecoverPassForm {...props} cancelChangePass = {cancelRecoverPass}  />}
                                            </Formik>
                                        </div>
                                    ) : (
                                        <Formik
                                            initialValues={{
                                                //Datos de
                                                email: "",
                                            }}
                                            onSubmit={(values, actions) => {
                                                actions.setSubmitting(false);
                                                actions.resetForm(true);
                                                sendEmail(values); 
                                                // setChangePass(true);
                                                // login(values);
                                            }}
                                            validate={validationEmail}
                                        >
                                            {(props) => <SendEmailPassForm handleCancel={cancelRecoverPass} {...props} />}
                                        </Formik>
                                    )
                                )
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            //Datos de loggin
                                            rut: "",
                                            password: "",
                                        }}
                                        onSubmit={(values, actions) => {
                                            actions.setSubmitting(false);
                                            actions.resetForm(true);
                                            login(values);
                                        }}
                                        validate={validation}
                                    >
                                        {(props) => <LoginForm {...props} />}
                                    </Formik>
                                    <div className="tw-mt-5">
                                        <Link to="registro" className="tw-text-white hover:tw-text-blueOil tw-mr-5 tw-bg-blue-500 tw-p-3 tw-rounded-md">Registrarse!</Link>
                                        <button className="tw-text-white hover:tw-text-blue-600"
                                            onClick={() => {setRecoverEmail(true)}}
                                        >
                                            Recuperar contraseña
                                        </button>
                                    </div>
                                </>
                            )
                        }
                    </div>
            </div>
        );
    } else {
        //de lo contrario redireccionamos
        return <Redirect to="/" />;
    }
};

export default Login;