/**
 * Librerias de react o terceros
 */
import React from "react";

const MessageSelected = ({message, destroyMessage}) => {
    return (
        <div className="tw-flex tw-flex-col tw-w-full tw-h-screen tw-border">
            <div className="tw-flex tw-py-3 tw-px-2 tw-w-full tw-border-b">
                <div className="tw-flex tw-flex-col tw-w-9/12">
                    <p className="tw-text-gray-700 tw-text-lg tw-font-medium">
                        {message.subject}
                    </p>
                    <p className="tw-text-gray-500 tw-text-md">
                        {message.name} - {message.email}
                    </p>
                </div>
                <div className="tw-flex tw-justify-end tw-items-center tw-w-3/12">
                    <button className="tw-shadow-xl tw-border-1 tw-rounded-sm tw-p-1 tw-bg-red-600 tw-text-white focus:tw-outline-none"
                        onClick={()=>{destroyMessage(message.id)}}
                    >Eliminar</button>
                </div>
            </div>
            <div className="tw-h-full tw-p-3 tw-text-justify">
                <p>{message.message}</p>
            </div>
        </div>
    );
};

export default MessageSelected;