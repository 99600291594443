import React from 'react'
import {FaCloudUploadAlt} from 'react-icons/fa';

const RegistryFormPart4 = ({
    handleChange, 
    handleSubmit, 
    isSubmitting, 
    resetForm, 
    values, 
    errors, 
    touched, 


    handleFile, 
    archivoTitulo,
    archivoCarnet,
    archivoMenores,
    setArchivoTitulo,
    setArchivoCarnet,
    setArchivoMenores,
    setCurrentForm

}) => {
    
    return (
        <form onSubmit={handleSubmit}> 
            <h1 className="tw-text-white tw-text-2xl tw-text-center">Documentación necesaria</h1>
            <hr className="tw-w-1/2 tw-mx-auto tw-bg-white tw-h-px tw-rounded tw-mb-5"></hr>           
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                
                <div className="tw-mx-4 lg:tw-ml-16">
                    <p className="tw-text-white tw-text-xl">Adjuntar fotocopia carnet</p>
                    {
                        archivoCarnet ? (
                            <div className="tw-flex tw-items-center tw-mt-4">
                                <p className="tw-text-white tw-text-md">{archivoCarnet.name.slice(0,30)}...</p>
                                <button className="tw-ml-3 tw-p-1 tw-rounded-full tw-border tw-border-white tw-text-white tw-bg-red-500 tw-font-bold"
                                    onClick={() => {setArchivoCarnet(null)}}
                                >
                                    Quitar
                                </button>
                            </div>
                        
                        ) : (
                            <label className="tw-flex tw-items-center tw-mx-auto tw-mt-2 tw-px-3 tw-h-10 tw-bg-white tw-text-blueOil tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-cursor-pointer hover:tw-bg-blue-500 hover:tw-text-white">
                                <FaCloudUploadAlt className="tw-text-3xl" />
                                <span className="tw-ml-2 tw-text-base tw-leading-normal">
                                    Seleccionar fotocopia carnet
                                </span>
                                <input
                                    accept=".pdf"
                                    type="file"
                                    className="tw-hidden"
                                    onChange={handleFile}
                                    name="archivo_carnet"
                                />
                            </label>)
                    }
                </div>
                {/* <div className="tw-w-100 lg:tw-w-1/3 tw-m-4 lg:tw-ml-16 lg:tw-mb-5"> */}
                <div className="tw-w-100 tw-m-4 tw-mt-2 lg:tw-mt-0">
                    <p className="tw-text-white tw-text-xl tw-text-center">Adjuntar certificado de "Inhabilidad para trabajar con menores de edad"</p>
                    {
                        archivoMenores ? (
                            <div className="tw-flex tw-items-center tw-mt-4">
                                <p className="tw-text-white tw-text-md">{archivoMenores.name.slice(0,30)}...</p>
                                <button className="tw-ml-3 tw-p-1 tw-rounded-full tw-border tw-border-white tw-text-white tw-bg-red-500 tw-font-bold"
                                    onClick={() => {setArchivoMenores(null)}}
                                >
                                    Quitar
                                </button>
                            </div>
                        
                        ) : (
                            <label className="tw-flex tw-items-center tw-mx-auto tw-mt-2 tw-px-3 tw-h-10 tw-bg-white tw-text-blueOil tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-cursor-pointer hover:tw-bg-blue-500 hover:tw-text-white">
                                <FaCloudUploadAlt className="tw-text-3xl" />
                                <span className="tw-ml-2 tw-text-base tw-leading-normal">
                                    Seleccionar certificado
                                </span>
                                <input
                                    accept=".pdf"
                                    type="file"
                                    className="tw-hidden"
                                    onChange={handleFile}
                                    name="archivo_menores"
                                />
                            </label>)
                    }
                </div>
            </div>
            {/* <div className=""> */}
            <div className="tw-w-100 lg:tw-w-1/3 tw-m-4 lg:tw-ml-16 lg:tw-mb-5">
                <p className="tw-text-white tw-text-xl">Adjuntar título académico</p>
                {
                    archivoTitulo ? (
                        <div className="tw-flex tw-items-center tw-mt-4">
                            <p className="tw-text-white tw-text-md">{archivoTitulo.name.slice(0,30)}...</p>
                            <button className="tw-ml-3 tw-p-1 tw-rounded-full tw-border tw-border-white tw-text-white tw-bg-red-500 tw-font-bold"
                                onClick={() => {setArchivoTitulo(null)}}
                            >
                                Quitar
                            </button>
                        </div>
                    
                    ) : (
                        <label className="tw-flex tw-items-center tw-mx-auto tw-mt-2 tw-px-3 tw-h-10 tw-bg-white tw-text-blueOil tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-cursor-pointer hover:tw-bg-blue-500 hover:tw-text-white">
                            <FaCloudUploadAlt className="tw-text-3xl" />
                            <span className="tw-ml-2 tw-text-base tw-leading-normal">
                                Seleccionar archivo título
                            </span>
                            <input
                                accept=".pdf"
                                type="file"
                                className="tw-hidden"
                                onChange={handleFile}
                                name="archivo_titulo"
                            />
                        </label>)
                }
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
                <div className="tw-w-full tw-px-3">
                        <input required type="checkbox"/> 
                    <a 
                        href="https://api.colegionacionalpsp.cl/api/documents/codigo-de-etica-profesional.pdf"
                        className="tw-text-white">
                        Declaro haber leído y respetar el código de ética profesional del colegio de
                            psicopedagogos de Chile y me someto a su control ético.
                    </a>
                </div>
            </div>

            <div className="tw-flex tw-items-center tw-justify-center lg:tw-justify-end tw-space-x-2 tw-mb-6 lg:tw-mr-10">
                {/* <div className="tw-w-full tw-px-3 tw-bg-white"> */}
                    <button type="button"
                            className="tw-text-lg tw-font-semibold tw-bg-yellow-400 hover:tw-bg-yellow-600 tw-text-white tw-rounded-lg tw-p-2 lg:tw-px-6 lg:tw-py-2 tw-block tw-shadow-xl"
                            onClick={()=>{setCurrentForm(3)}}
                    >
                        Paso anterior
                    </button>               
                    <button type="submit"
                            className="tw-text-lg tw-font-semibold tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded-lg tw-p-2 lg:tw-px-6 lg:tw-py-3 tw-block tw-shadow-xl"
                    >
                        Solicitar afiliarme
                    </button>               
                {/* </div> */}
            </div>
        </form>
    );
};

export default RegistryFormPart4;
