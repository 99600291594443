import React, { useState } from 'react';
import { Formik } from "formik";
import axios from "axios";
import UpdateTitleDescriptionForm from '../forms/UpdateTitleDescriptionForm';
import Loader from '../../atoms/Loader';
import NonContent from '../../atoms/NonContent';

const validation = (values) => {
    let errors = {};
    //validacion titulo 
    if (!values.title) {
        errors.title = "Ingrese el título!";
    } else if (values.title.length < 10 || values.title.length > 100) {
        errors.title = "Título demasiado corto o demasiado largo!";
    }
    // validacion descripcion 
    if (!values.description) {
        errors.description = "Ingrese una descripción!";
    } else if (
        values.description.length < 10 ||
        values.description.length > 400
    ) {
        errors.description = "Descripción demasiado corta o demasiado extensa!";
    }
    return errors;
};

const validationJob = (values) => {
    let errors = {};

    if (!values.title) {
        errors.title = "Ingrese el título!";
    } else if (values.title.length < 10 || values.title.length > 100) {
        errors.title = "Título demasiado corto o demasiado largo!";
    }
    
    if (!values.location) {
        errors.location = "Ingrese una ubicación!";
    } else if (
        values.location.length < 10 ||
        values.location.length > 60
    ) {
        errors.location = "Ubicación demasiado corta o demasiado extensa!";
    }

    if (!values.contact) {
        errors.contact = "Ingrese un contácto!";
    } else if (
        values.contact.length < 10 ||
        values.contact.length > 60
    ) {
        errors.contact = "Contácto demasiado corto o demasiado extenso!";
    }

    if (values.phone.length > 20) {
        errors.phone = "Teléfono muy extenso!";
    }

    return errors;
};

const UpdateTitleDescription = ({typeContent, contentSelected, setContentSelected}) => {


    const [unlock, setUnlock] = useState(false);
    const [sending, setSending] = useState(false);

    const updateData = (values) => {
        setSending(true);
        const sendUpdatedData = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let urlAxios = `api/notices/${contentSelected.url}`;
                if(typeContent === "2"){
                    urlAxios = `api/jobs/${contentSelected.url}`;
                }else if(typeContent === "3"){
                    urlAxios = `api/activities/${contentSelected.url}`;
                }
                
                let data = {titulo : values.title, descripcion : values.description};

                if(typeContent === "2"){
                    data = {
                        titulo : values.title,
                        tipo : values.type,
                        ubicacion : values.location,
                        contacto : values.contact,
                        telefono : values.phone
                    };
                    
                }

                const response = await axios.patch(urlAxios, data);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    return false;
                }
                // Como es una data, entonces es el contenido requerido
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        sendUpdatedData().then((response) => {
            console.log(response);
            if (!response) {
                setContentSelected(false);
            } else { 
                setContentSelected(response);
            }
            setSending(false);
            setUnlock(false);
        });
    }; 

    return (
        <div className="tw-relative">
            {
                sending ? <Loader /> : (
                    contentSelected ? (
                        <> 
                            {
                                (typeContent === "1" || typeContent === "3") ? (
                                    <Formik
                                        initialValues={{
                                            title: contentSelected.titulo,
                                            description: contentSelected.descripcion,
                                        }}
                                        onSubmit={(values, actions) => {
                                            
                                            actions.setSubmitting(false);
                                            // actions.resetForm(true);
                                            updateData(values);   
                                        }}
                                        validate={validation}
                                    >
                                        {(props) => (
                                            <UpdateTitleDescriptionForm
                                                {...props}
                                                unlock = {unlock}
                                                setUnlock= {setUnlock}
                                                typeContent={typeContent}
                                            />
                                        )}
                                    </Formik>
                                ) : (
                                    typeContent === "2" ? (
                                        <Formik
                                            initialValues={{
                                            
                                                title : contentSelected.titulo,
                                                type : contentSelected.tipo,
                                                location: contentSelected.ubicacion,
                                                contact: contentSelected.contacto,
                                                phone : contentSelected.telefono
                                                
                                            }}
                                            onSubmit={(values, actions) => {
                                                actions.setSubmitting(false);
                                                // actions.resetForm(true);
                                                updateData(values);
                                                
                                            }}
                                            validate={validationJob}
                                        >
                                            {(props) => (
                                                <UpdateTitleDescriptionForm
                                                    {...props}
                                                    unlock = {unlock}
                                                    setUnlock= {setUnlock}
                                                    typeContent={typeContent}
                                                />
                                            )}
                                        </Formik>
                                    ) : (null)
                                )
                            }
                            {
                                unlock ? (null) : (
                                    <div className="tw-absolute tw-flex tw-w-full tw-items-center tw-justify-center tw-bg-gray-800 tw-bg-opacity-50 tw-h-full tw-top-0">
                                        <button className="tw-bg-yellow-600 tw-border tw-border-white tw-text-white tw-p-3 tw-rounded-md"
                                            onClick={() =>{setUnlock(true)}}
                                        >
                                            Editar datos principales
                                        </button>
                                    </div>
                                )
                            }
                        </>
                    ) : ( <NonContent /> )
                )
            }
        </div>
    );
};

export default UpdateTitleDescription;