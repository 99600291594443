import React from 'react'
import InputFieldText from "../../atoms/InputFieldText";
const AddSectionForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
}) => {
    return (
        <form onSubmit={handleSubmit} className="tw-w-full tw-p-2">
            <div className="tw-flex tw-w-full">
                <InputFieldText
                    classContainer="tw-w-8/12 lg:tw-w-10/12 tw-border tw-border-gray-600"
                    classLabel="tw-hidden"
                    classInput="tw-px-4 focus:tw-outline-none tw-w-full tw-py-2 tw-text-base"
                    classError = "tw-text-red-400 tw-absolute lg:tw-bottom-2 lg:tw-right-4"
                    bgRed={false}

                    name="nombre"
                    type="text"
                    label=""
                    placeholder="Ingrese el nombre de la sección ..."
                    maxLength={30}
                    
                />
                {/* <input name="search_content" className="tw-rounded-l-2xl tw-px-4 focus:tw-outline-none tw-w-full tw-py-2" type="text" placeholder=" Ingrese su búsqueda ..." /> */}
                <div className="tw-flex tw-items-center tw-w-4/12 lg:tw-w-2/12 tw-px-2 ">
                    <button
                        type="submit"
                        className="tw-text-base tw-bg-blue-500 
                        hover:tw-bg-blue-600 tw-text-white tw-rounded-lg tw-h-9 lg:tw-h-12 tw-w-full tw-px-3 lg:tw-px-6 tw-block tw-shadow-xl focu:tw-outline-none"
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AddSectionForm;
