import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router";
import { LOGGED_IN } from "../../constants/AuthStatus";
import { UserContext } from "../../context/UserContext";
import Loader from "../atoms/Loader";
import UserFullCard from "../molecules/cards/UserFullCard";
import ChangeUserPass from "../molecules/forms/ChangeUserPass";
import { Formik } from "formik";
import EditUserConfig from "../molecules/forms/EditUserConfig";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
import EditPhotoForm from "../molecules/forms/EditPhotoForm";

const validation = (values) => {
  let errors = {};

  if (!values.passOld) {
    errors.passOld = "Ingresa tu contraseña anterior!";
  }
  if (!values.passNew) {
    errors.passNew = "Ingresa tu contraseña nueva!";
  }
  if (!values.passNewAgain) {
    errors.passNewAgain = "Ingresa tu contraseña nueva!";
  }

  if (values.passNew && values.passNewAgain) {
    if (values.passNew !== values.passNewAgain) {
      errors.passNewAgain = "las contraseñas no coinciden";
    }
  }

  if (values.passOld && values.passNew) {
    if (values.passOld === values.passNew) {
      errors.passNew = "La contraseña es igual a la anterior";
    }
  }

  return errors;
};
const validationConfig = (values) => {
  let errors = {};

  if (!values.celular) {
    errors.celular = "Ingresa tu celular!";
  }

  if (!values.email) {
    errors.email = "Ingresa tu correo!";
  }

  if (!values.direccion) {
    errors.direccion = "Ingresa tu dirección!";
  }

  if (!values.ciudad) {
    errors.ciudad = "Ingresa tu ciudad!";
  }

  if (!values.comuna) {
    errors.comuna = "Ingresa tu comuna!";
  }

  if (!values.region) {
    errors.region = "Ingresa tu región!";
  }

  if (!values.cargo) {
    errors.cargo = "Ingresa tu cargo!";
  }

  if (!values.direccion_laboral) {
    errors.direccion_laboral = "Ingresa tu dirección laboral!";
  }

  if (!values.ciudad_laboral) {
    errors.ciudad_laboral = "Ingresa tu ciudad laboral!";
  }

  if (!values.comuna_laboral) {
    errors.comuna_laboral = "Ingresa tu comuna laboral!";
  }

  return errors;
};

const UserConfig = ({ match }) => {
  const userContext = useContext(UserContext);
  const { userLogged, setUserLogged, authStatus } = userContext;
  const appContext = useContext(AppContext);
  const { handleAlertMessage, setAlertRender } = appContext;
  const [user, setUser] = useState(null);
  const [btnChangePass, setBtnChangePass] = useState(true);
  const [btnEditConfig, setBtnEditConfig] = useState(true);
  const [btnEditPhoto, setBtnEditPhoto] = useState(true);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (match && match.path === "/configuracion-usuario/:userID") {
      //perfil propio
      setUser(userLogged);
    }
  }, [match, userLogged]);

  function editConfig() {
    setBtnEditConfig(false);
  }

  function cancelEditConfig() {
    setBtnEditConfig(true);
  }

  function editPhoto() {
    setBtnEditPhoto(false);
  }

  function cancelEditPhoto() {
    setBtnEditPhoto(true);
  }

  function changePass() {
    setBtnChangePass(false);
  }

  function cancelChangePass() {
    setBtnChangePass(true);
  }

  function updateUserPass(values) {
    setSending(true);

    const newConfig = {
      passOld: values.passOld,
      passNew: values.passNew,
    };

    const url = `api/users/${user.id}`;

    axios
      .patch(url, newConfig)
      .then((response) => {
        // console.log(response);
        if (response.data.message) {
          // Elevamos el titulo, mensaje y el tipo de mensaje
          handleAlertMessage(
            response.data.title,
            response.data.message,
            response.data.type
          );
          // Activamos para que se renderice la alerta
          setAlertRender(true);
        }
        setBtnChangePass(true);
        setSending(false);
      })
      .catch((error) => {
        // alert("Se ha producido un error, contácte con el administrador \n");
        return false;
      });
  }

  function saveConfig(values) {
    setUser(null);
    const url = `api/users/${user.id}`;
    axios
      .patch(url, values)
      .then((response) => {
        // console.log(response);
        if (response.data.message) {
          // Elevamos el titulo, mensaje y el tipo de mensaje
          handleAlertMessage(
            response.data.title,
            response.data.message,
            response.data.type
          );
          // Activamos para que se renderice la alerta
          setAlertRender(true);
        } else {
          setUserLogged(response.data);
          // setUser(response.data);
        }
        setBtnEditConfig(true);
        setSending(false);
      })
      .catch((error) => {
        // alert("Se ha producido un error, contácte con el administrador \n");
        return false;
      });
  }

  function changePhoto(value) {
    // console.log(value);
    let formData = new FormData();

    formData.append("imagen_perfil", value);

    if (value) {
      setUser(null);
      const url = `api/users/updatePhoto/${user.id}`;
      axios
        .post(url, formData)
        .then((response) => {
          // console.log(response);
          if (response.data.message) {
            // Elevamos el titulo, mensaje y el tipo de mensaje
            handleAlertMessage(
              response.data.title,
              response.data.message,
              response.data.type
            );
            // Activamos para que se renderice la alerta
            setAlertRender(true);
          } else {
            setUserLogged(response.data);
            // setUser(response.data);
          }
          setBtnEditPhoto(true);
          setSending(false);
        })
        .catch((error) => {
          // console.log(error.message);
          // alert("Se ha producido un error, contácte con el administrador \n");
          return false;
        });
    } else {
      handleAlertMessage(
        "Alerta",
        "Debe subir una foto si quiere cambiar la imagen de perfil",
        "alert"
      );
      // Activamos para que se renderice la alerta
      setAlertRender(true);
    }
  }

  return (
    <>
      {authStatus === LOGGED_IN ? (
        user === null ? (
          <Loader />
        ) : (
          <div className="tw-container tw-p-2 tw-mx-auto">
            <h1 className="tw-text-xl md:tw-text-3xl tw-font-medium tw-text-right tw-mb-5">
              Configuración de usuario
            </h1>
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-bg-gray-700">
              <div className="tw-flex tw-flex-col tw-justify-center lg:tw-justify-start tw-items-start tw-w-full lg:tw-w-3/12 tw-mt-5">
                {btnEditPhoto ? (
                  <img
                    className="tw-mx-auto lg:tw-mx-2 tw-object-cover tw-w-40 tw-h-40 lg:tw-h-auto lg:tw-w-full tw-rounded-full lg:tw-rounded-none"
                    src={user.imagen_perfil}
                    alt="imagen usuario"
                  />
                ) : sending ? (
                  <p className="tw-text-white">Cargando ... </p>
                ) : (
                  <EditPhotoForm
                    changePhoto={changePhoto}
                    cancelEditPhoto={cancelEditPhoto}
                  />
                )}

                <div className="tw-flex tw-w-full tw-mx-auto lg:tw-mx-2 tw-justify-center tw-my-5">
                  {btnEditPhoto ? (
                    <button
                      className="tw-text-white tw-bg-blueOil tw-border tw-rounded-md tw-mr-2 tw-p-2 tw-font-medium"
                      onClick={() => {
                        editPhoto();
                      }}
                    >
                      Cambiar foto perfil
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center lg:tw-items-start tw-w-full lg:tw-w-9/12 tw-px-6 tw-py-2">
                {!btnEditConfig ? (
                  sending ? (
                    <p className="tw-text-white">Cargando ... </p>
                  ) : (
                    <Formik
                      initialValues={{
                        //Datos de
                        telefono: user.telefono,
                        celular: user.celular,
                        email: user.email,
                        direccion: user.direccion,
                        ciudad: user.ciudad,
                        comuna: user.comuna,
                        region: user.region,
                        telefono_laboral: user.telefono_laboral,
                        cargo: user.cargo,
                        direccion_laboral: user.direccion_laboral,
                        ciudad_laboral: user.ciudad_laboral,
                        comuna_laboral: user.comuna_laboral,
                        buscador: user.buscador,
                      }}
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        actions.resetForm(true);
                        saveConfig(values);
                      }}
                      validate={validationConfig}
                    >
                      {(props) => (
                        <EditUserConfig
                          {...props}
                          cancelEditConfig={cancelEditConfig}
                        />
                      )}
                    </Formik>
                  )
                ) : (
                  <UserFullCard user={user} />
                )}

                {!btnChangePass ? (
                  sending ? (
                    <p className="tw-text-white">Cargando ... </p>
                  ) : (
                    <Formik
                      initialValues={{
                        //Datos de
                        passOld: "",
                        passNew: "",
                        passNewAgain: "",
                      }}
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        actions.resetForm(true);
                        updateUserPass(values);
                      }}
                      validate={validation}
                    >
                      {(props) => (
                        <ChangeUserPass
                          {...props}
                          cancelChangePass={cancelChangePass}
                        />
                      )}
                    </Formik>
                  )
                ) : null}
                <div className="tw-flex tw-flex-wrap tw-my-3 tw-justify-center sm:tw-justify-end tw-text-gray-200 tw-text-center">
                  {btnChangePass ? (
                    <button
                      className="tw-bg-yellow-700 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                      onClick={() => {
                        changePass();
                      }}
                    >
                      Actualizar Contraseña
                    </button>
                  ) : null}

                  {btnEditConfig ? (
                    <button
                      className="tw-bg-yellow-500 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                      // disabled =  {btnSave}
                      onClick={() => {
                        editConfig();
                      }}
                    >
                      Editar
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default UserConfig;
