import React from 'react';
import Logo from '../../../assets/images/logoPsp.png';
const Banner = () => {
    return (
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-w-11/12 tw-mx-auto tw-py-10 tw-px-24 fondo_login">
                <img src={Logo} alt="BANNER" className="tw-object-cover tw-object-center tw-w-full sm:tw-w-2/4 lg:tw-w-1/4 tw-h-full"/>
                <div className="tw-flex-1 tw-text-center tw-px-4 tw-py-10 m-2">
                    <h1 className="tw-text-white tw-font-semibold tw-text-4xl tw-mb-10">
                        Colegio Nacional De Psicopedagogos De Chile Asociación Gremial Registro 610-8
                    </h1>
                    <p className="tw-text-white tw-text-2xl">
                        No te limites a observar, ven y se parte de la historia…
                    </p>
                    <p className="tw-text-white tw-text-3xl tw-tracking-widest">
                        CONSTRUYAMOS PSICOPEDAGOGÍA
                    </p>
                </div>
        </div>
    );
};

export default Banner;
