import React, { useEffect, useContext, useState } from 'react';
import AddSection from '../organisms/AddSection';

import axios from "axios";
import Paginate from "../molecules/generals/Paginate";

import { UserContext } from "../../context/UserContext";
import { ADMIN, LOGGED_IN } from "../../constants/AuthStatus";
import SectionsTable from '../organisms/tables/SectionsTable';
import { Redirect } from 'react-router';

const Documents = () => {
    
    const userContext = useContext(UserContext);
    const { userLogged, authStatus } = userContext;

    const [sections, setSections] = useState(null);

    //Opciones de cada consulta
    //ordenado por?
    const [sortBy] = useState("updated_at");
    const [sortAsc, setSortAsc] = useState(false);
    //n° de pagina
    const [page, setPage] = useState(1);
    //n° por pagina
    const [perPage] = useState(3);
    const [totalSections, setTotalSections] = useState(0);

    //cambiar la pagina actual
    const handleChangePage = (event, value) => {
        setSections(null);
        setPage(value);
    };

    const handleSections = (section) => {

        let sectionsNew = sections;
        sectionsNew.unshift(section)
        setSections(sectionsNew);

    }
    const filterSection = (id) => {
        let sectionsNew = sections.filter(section => section.id !== id);
        
        
        if(sectionsNew.length === 0) {
            getSections();
        }else{
            setSections(sectionsNew);
        }
    }
    const getSections = () => {
        const getSections = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/sections?sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;

                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getSections().then((response) => {
            if (response) {
                setTotalSections(response.last_page);
                setSections(response.data);
            } else {
                setSections([]);
            }
        });
    }
    useEffect(() => {
        const getSections = async () => {
            try {
                // console.log("requiriendo contenido a la api");
                let url = `api/sections?sort_by=${sortBy}${
                    sortAsc ? "&asc" : "&desc"
                }&per_page=${perPage}&page=${page}`;

                const response = await axios.get(url);
                //Comprobamos si la respuesta es un mensaje
                if (response.data.message) {
                    return false;
                }
                return response.data;
            } catch (error) {
                // console.log(error);
                return false;
            }
        };

        getSections().then((response) => {
            if (response) {
                setTotalSections(response.last_page);
                setSections(response.data);
            } else {
                setSections([]);
            }
        });
    }, [sortBy, sortAsc, perPage, page])

    return (
        <div className="tw-flex tw-flex-col tw-w-full tw-p-2 tw-mb-2">
            {
                authStatus === LOGGED_IN ? (
                    <>
                        {
                            userLogged.tipo_usuario === ADMIN ? (
                                <AddSection handleSections={handleSections} />
                            ) : (null)
                        }
                        <Paginate
                            totalContent={totalSections}
                            page={page}
                            handleChangePage={handleChangePage}
                            sortAsc={sortAsc}
                            setSortAsc={setSortAsc}
                        />
                        <SectionsTable sections = {sections} filterSection={filterSection} />
                        <Paginate
                            totalContent={totalSections}
                            page={page}
                            handleChangePage={handleChangePage}
                            sortAsc={sortAsc}
                            setSortAsc={setSortAsc}
                        />
                    </>
                ) : (<Redirect to ="/" />)
            }
            
        </div>
    );
};

export default Documents;