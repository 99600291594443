/**
 * Librerias de react o terceros
 */
import React, { useContext } from "react";
import { FaUserCog } from "react-icons/fa";
import { NavLink } from "react-router-dom";

/**
 * Contextos
 */
import { UserContext } from "../../../context/UserContext";

/**
 * Componentes
 */
import LogoutButton from "../../atoms/buttons/LogoutButton";

const UserInfo = () => {
    // Propiedades del contexto a usar
    const userContext = useContext(UserContext);
    const { userLogged } = userContext;

    return (
        <>
            {/* Hay un usuario logueado para mostrar su info? */}
            {userLogged ? (
                <div className="sdb_item tw-flex tw-flex-col xl:tw-flex-row tw-justify-between tw-items-center tw-rounded-md tw-mx-2 lg:tw-mb-2 tw-p-1 lg:tw-bg-blueOil">
                    <img
                        className="tw-hidden lg:tw-block tw-object-cover tw-h-16 tw-w-16 tw-mx-auto tw-rounded-full"
                        src={`${userLogged.imagen_perfil}`}
                        alt="imagen usuario"
                    />
                    <div className="tw-hidden lg:tw-block tw-mx-2">
                        <h1 className="tw-text-white tw-text-xs">
                            {userLogged.nombre} {userLogged.apellido_paterno}
                        </h1>
                        <h2 className="tw-text-gray-400 tw-text-xs">
                            {userLogged.tipo_usuario === "admin"
                                ? "Administrador"
                                : "Afiliado"}
                        </h2>
                    </div>
                    <div className="tw-flex tw-flex-row xl:tw-flex-col tw-justify-center tw-items-center tw-space-x-4 xl:tw-space-x-0 xl:tw-space-y-2">
                        <NavLink to={`/configuracion-usuario/${userLogged.id}`}>
                            <button className="tw-mx-auto tw-p-1 tw-rounded-full focus:tw-outline-none tw-bg-blue-500">
                                <FaUserCog className="tw-text-white tw-text-xl"/>
                            </button>
                        </NavLink>
                        <LogoutButton />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UserInfo;