import React, { useContext, useState } from 'react';
import {FaCloudDownloadAlt} from 'react-icons/fa';
import {RiDeleteBin2Fill} from 'react-icons/ri';
import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import DeleteDocument from '../../organisms/DeleteDocument';
import { UserContext } from '../../../context/UserContext';
import { ADMIN } from '../../../constants/AuthStatus';
dayjs.locale("es");
dayjs.extend(relativeTime);

const DocumentCard = ({id,nombre, peso, path_file, autorNombre, autorApellido, time, deleteDocument}) => {
    
    const userContext = useContext(UserContext);
    const {userLogged} = userContext;

    const [showForm, setShowForm] = useState(false);
    
    return (
        <div className="tw-w-full tw-max-w-full tw-mx-auto tw-px-4 tw-py-4 tw-shadow-md tw-rounded-lg tw-bg-gray-600">
            {
                showForm ? (
                    <DeleteDocument idDocument = {id} setShowForm={setShowForm} documentName={nombre} size={peso} deleteDocument={deleteDocument}  />
                ) : (
                    <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-between tw-text-white" >
                        <div className="tw-flex tw-flex-col tw-w-7/12 lg:tw-w-4/12">
                            <p className="tw-mx-2 lg:tw-hidden tw-text-base tw-font-medium">{nombre.slice(0,20)}...</p>
                            <p className="tw-mx-2 tw-hidden lg:tw-inline-block tw-text-base tw-font-medium">{nombre.slice(0,40)}...</p>
                            <p className="tw-mx-2 tw-text-sm">{dayjs(time).fromNow()}</p>
                        </div>
                        <div className="tw-flex tw-justify-center tw-w-2/12 lg:tw-w-1/12">
                            <p className="tw-mx-2 tw-text-base tw-font-medium">{peso}</p>
                        </div>
                        <div className="tw-hidden lg:tw-flex tw-justify-center tw-w-2/12">
                            <p className="tw-mx-2 tw-text-base tw-font-medium">{autorNombre} {autorApellido}</p>
                        </div>
                        <div className="tw-flex tw-w-2/12 lg:tw-w-1/12">
                            <a href={`https://api.colegionacionalpsp.cl/api/${path_file}`} className="tw-p-1 tw-rounded-md tw-bg-green-500 hover:tw-bg-green-700">
                                <FaCloudDownloadAlt className="tw-text-white tw-text-xl lg:tw-text-2xl" />
                            </a>
                            {
                                userLogged.tipo_usuario === ADMIN ? (
                                    <button className="tw-ml-2 lg:tw-ml-4 tw-p-1 tw-rounded-md tw-bg-red-600 hover:tw-bg-red-700 focus:tw-outline-none"
                                        onClick={() => {setShowForm(true)}}
                                    > 
                                        <RiDeleteBin2Fill className="tw-text-white tw-text-xl lg:tw-text-2xl" />
                                    </button>
                                ) : (null)
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default DocumentCard;
