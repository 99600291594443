/**
 * Librerias de react o tereceros
 */
import React from "react";

/**
 * Componentes
 */
import SocialnetCard from "../molecules/cards/SocialnetCard";
import ContactCard from "../molecules/cards/ContactCard";

/**
 * Assets
 */
import Nosotros2 from "../../assets/images/colage2.png";


const SocialnetSection = () => {
    return (
        <div className="tw-flex tw-relative tw-flex-col md:tw-flex-row">
            <div className="tw-w-full md:tw-w-1/3">
                <SocialnetCard />
            </div>
            <div className="tw-w-full md:tw-w-1/3">
                <ContactCard />
            </div>
            <div className="tw-w-full md:tw-w-1/3">
                <img
                    src={Nosotros2}
                    alt="Fotografia de nosotros"
                    className="tw-object-cover tw-w-full tw-h-full tw-opacity-80"
                />
            </div>
            {/* separador largo*/}
            <div className="tw-absolute tw-left-0 tw-top-0 tw-w-2/3 md:tw-flex md:tw-justify-center">
                <div className="tw-h-52 tw-w-3 tw-bg-blue-300 tw-opacity-80 "></div>
            </div>
            {/* separador corto */}
            <div className="tw-absolute tw-top-0 tw-left-1/3 tw-w-2/3 tw-flex tw-justify-end lg:tw-justify-center">
                <div className="tw-h-5 tw-w-16 tw-bg-green-300"></div>
            </div>
        </div>
    );
};

export default SocialnetSection;
