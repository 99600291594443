/**
 * Librerias de react o terceros
 */
import React, { useContext } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import UpdateContentButton from "../../atoms/buttons/UpdateContentButton";
import DeleteContentButton from "../../atoms/buttons/DeleteContentButton";
import { UserContext } from "../../../context/UserContext";
import { ADMIN, LOGGED_IN } from "../../../constants/AuthStatus";
dayjs.locale("es");
dayjs.extend(relativeTime);

const EventCard = ({ id, title, description, mainPicture, time, url }) => {

    const userContext = useContext(UserContext);
    const {userLogged, authStatus} = userContext;

    return (
        <div className="tw-relative tw-max-w-lg tw-mx-2 tw-shadow-2xl tw-rounded-lg tw-mt-6">
            <img
                src={mainPicture}
                className="tw-object-cover tw-w-full"
                alt={title}
            />
            <div
                className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-absolute tw-top-0 tw-w-full tw-h-full tw-p-2 tw-bg-black tw-bg-opacity-90 
                    tw-transform tw-transition tw-duration-300 tw-opacity-0 hover:tw-opacity-100"
            >
                <h1 className="tw-text-center tw-text-3xl tw-font-semibold tw-text-white">
                    {title}
                </h1>
                <p className="tw-text-center tw-text-lg tw-text-white">
                    {description}
                </p>
                <p className="tw-text-gray-400">{dayjs(time).fromNow()}</p>
                {
                    authStatus === LOGGED_IN ? (
                        userLogged.tipo_usuario === ADMIN ? (
                            <div className="tw-flex tw-w-full tw-items-center tw-justify-end tw-space-x-4 tw-absolute tw-bottom-4 tw-right-6">
                                <UpdateContentButton typeContent={3} redirectTo="eventos-actividades" titleContent={url}/>
                                <DeleteContentButton typeContent={3} redirectTo="eventos-actividades" titleContent={url} />
                            </div>
                        ) : (null)
                    ) : (null)
                }
            </div>
        </div>
    );
};

export default EventCard;