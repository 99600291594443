import React from 'react';
import HowToJoin from '../molecules/registry/HowToJoin';
import RegistrySection from '../organisms/RegistrySection';

const Registry = () => {
    return (
        <div>
            <div className="tw-w-11/12 tw-mx-auto tw-my-10">
                <HowToJoin />
            </div>
            <div className="tw-w-11/12 tw-mx-auto tw-mb-2 fondo_login tw-py-3">
                <RegistrySection />
            </div>
        </div>
    );
};

export default Registry;
