/**
 * Libreria
 */
import React, { useContext } from "react";

/**
 * Contextos
 */
import { UserContext } from "../../../context/UserContext";

/**
 * Constantes
 */
import { NOT_LOGGED_IN } from "../../../constants/AuthStatus";

/**
 * Componentes
 */
import Logo from "../../atoms/anims/Logo";
import SocialNetwork from "../../atoms/SocialNetwork";
import { NavLink } from "react-router-dom";

const Header = () => {
    //Propiedades del contexto a usar
    const userContext = useContext(UserContext);
    const { authStatus } = userContext;

    return (
        <div className="tw-flex tw-flex-col xl:tw-flex-row fondo_header tw-justify-end tw-shadow-md">
            {/* Se debe mostrar el logo? */}
            {authStatus === NOT_LOGGED_IN ? (
                <div className="tw-mx-auto xl:tw-ml-20 tw-py-2">
                    <NavLink to="/">
                        <Logo />
                    </NavLink>
                </div>
            ) : null}
            <div className="tw-flex  tw-items-center tw-justify-center xl:tw-justify-start  tw-w-full xl:tw-w-5/12">
                <h1 className="tw-font-serif tw-text-xl tw-text-white tw-text-center tw-mt-3 lg:tw-mt-0 tw-hidden lg:tw-block">
                    Colegio Nacional De Psicopedagogos De Chile Asociación Gremial Registro 610-8
                </h1>
            </div>
            <div className="tw-flex tw-items-center tw-w-full xl:tw-w-5/12">
                {/* Redes sociales */}
                <SocialNetwork />
            </div>
        </div>
    );
};

export default Header;