import React from 'react';

import ContactFormSection from '../organisms/ContactFormSection';
import SocialnetSection from '../organisms/SocialnetSection';


const Contact = () => {
    return (
        <div className=" tw-w-full">
            {/* <h1 className="tw-text-3xl md:tw-text-4xl tw-my-5 tw-font-semibold tw-text-center tw-text-gray-700">Contáctanos</h1>                         */}
            <ContactFormSection />
            <SocialnetSection />
        </div>
    );
};

export default Contact;