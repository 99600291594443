/**
 * Librerias de react o terceros
 */
import React, { useEffect, useContext } from "react";
import anime from "animejs/lib/anime.es.js";

/**
 * Contextos
 */
import { AppContext } from "../../context/AppContext";

/**
 * Iconos
 */
import { IoMdCloseCircle } from "react-icons/io";

const AlertMessage = ({ classContainer, classTitle, classMessage }) => {
    // Seteamos el o los contexto a usar
    const appContext = useContext(AppContext);
    // Recibimos las propiedades del mensase
    // titulo, mensaje y tipo de mensaje
    const {
        alertRender,
        setAlertRender,
        messageTitle,
        alertMessage,
        messageType,
    } = appContext;

    useEffect(() => {
        //si hay una alerta que renderizar
        if (alertRender) {
            function runAnimOpen() {
                //le seteamos lo siguientes estilos antes de comenzar la animación
                anime.set([".alert"], {
                    //aparecera desde el fondo
                    scale: 0,
                    //Tendra una opacidad de 0 (oculto)
                    opacity: 0,
                });

                //Linea de tiempo de animacion (Cuando se abre/cierra) de cada elemento indicado
                let animeAlert = anime.timeline({
                    //la linea completa de animacion tardara 250ms
                    // duration: 350,
                    //Tipo de aceleracion
                    easing: "easeInOutSine",
                    //no se va a repetir
                    loop: false,
                    //comenzará automaticamente cuando se llame la funcion
                    autoplay: true,
                    endDelay: 5000,
                    // mensaje antes de comenzar la animacion
                    // begin() {
                    //     console.log("Mostrando alerta.");
                    // },
                    //cuando acabe la animacion se indicara que el menu esta abierto
                    complete() {
                        setAlertRender(false);
                    },
                });
                //agregando escenas a la linea de tiempo
                animeAlert
                    .add({
                        //obtendra los elementos que tengan la clase alert
                        targets: [".alert"],
                        duration: 350,
                        scale: 1,
                        //y le irá pintando una opacidad hasta llegar a 1
                        opacity: 1,
                    })
                    .add({
                        //obtendra los elementos que tenga de id alert
                        targets: [".alert"],
                        delay: 2000,
                        duration: 350,
                        scale: 0,
                        //y le irá pintando una opacidad hasta llegar a 1
                        opacity: 0,
                    });
            }
            runAnimOpen();
        }
    }, [alertRender, setAlertRender]);

    function runAnimClose() {
        //le seteamos lo siguientes estilos antes de comenzar la animación
        anime.set([".alert"], {
            //estara en pantalla
            scale: 1,
            //Tendra una opacidad de 1 (se muestra)
            opacity: 1,
        });

        //Linea de tiempo de animacion (Cuando se abre/cierra) de cada elemento indicado
        let animeAlert = anime.timeline({
            //la linea completa de animacion tardara 250ms
            // duration: 350,
            //Tipo de aceleracion
            easing: "easeInOutSine",
            //no se va a repetir
            loop: false,
            //comenzará automaticamente cuando se llame la funcion
            autoplay: true,
            //cuando acabe la animacion se indicara que el menu esta abierto
            complete() {
                // console.log("Alerta cerrada.");
                setAlertRender(false);
            },
        });
        //agregando escenas a la linea de tiempo
        animeAlert.add({
            //obtendra los elementos que tenga de id alert
            targets: [".alert"],
            duration: 350,
            scale: 0,
            //y le irá pintando una opacidad hasta llegar a 1
            opacity: 0,
        });
    }

    // Por defecto es un mensaje de error
    // Asi que verificamos el tipo para saber que estilo tendrá la alerta
    // si no es de tipo alert o success, entonces dejamos las clases que vendrán por las props o las por defecto
    if (messageType === "alert") {
        classTitle = "tw-bg-yellow-500 tw-text-white";
        classMessage =
            "tw-border-yellow-400 tw-bg-yellow-200 tw-text-yellow-700";
    } else if (messageType === "success") {
        classTitle = "tw-bg-green-500 tw-text-white";
        classMessage = "tw-border-green-400 tw-bg-green-200 tw-text-green-700";
    }

    return (
        <div className="alert tw-absolute tw-bottom-24 tw-right-0 tw-z-40 tw-flex tw-w-full tw-max-w-lg tw-p-2 tw-items-center tw-transform tw-scale-0 tw-opacity-0">
            <div className={`tw-mx-auto tw-text-justify ${classContainer}`}>
                <div
                    className={`tw-flex tw-justify-between tw-rounded-t-lg tw-font-bold tw-px-4 tw-py-2 ${classTitle}`}
                >
                    <p>{messageTitle}</p>
                    <button
                        className="focus:tw-outline-none"
                        onClick={() => {
                            runAnimClose();
                        }}
                    >
                        <IoMdCloseCircle className="tw-text-xl" />{" "}
                    </button>
                </div>
                <div
                    className={`tw-rounded-b-lg tw-border tw-border-t-0 tw-px-4 tw-py-3 ${classMessage}`}
                >
                    <ul>
                        {
                            alertMessage.split("-").map((item, index) => {
                                return <li key={index}>{item}</li>;
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

// Por defecto será una alerta de error
AlertMessage.defaultProps = {
    classTitle: "tw-bg-red-600 tw-text-white",
    classMessage: "tw-border-red-400 tw-bg-red-300 tw-text-red-700",
};

export default AlertMessage;