/**
 * Librerais de react o terceros
 */
import React from "react";

/**
 * Iconos
 */
import { FaFolderOpen } from "react-icons/fa";

const NonContent = ({textColor}) => {
    return (
        <div className="tw-mx-auto tw-my-10 tw-w-11/12">
            <div className="tw-text-center">
                <FaFolderOpen className={`${textColor} tw-m-4 tw-text-7xl tw-mx-auto`} />
                <p className={`tw-text-3xl ${textColor}`}>Sin contenido.</p>
            </div>
        </div>
    );
};

NonContent.defaultProps = {
    textColor: "tw-text-gray-700",
};

export default NonContent;