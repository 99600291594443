// Proveedores de propiedades
import { AppProvider } from "../context/AppContext";
import { UserProvider } from "../context/UserContext";

// Pagina con el layout principal
import MasterPage from "./pages/MasterPage";

function App() {
    return (
        <AppProvider>
            <UserProvider>
                <MasterPage />
            </UserProvider>
        </AppProvider>
    );
}

export default App;
