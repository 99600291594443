import React from 'react';
import InputFieldText from "../../atoms/InputFieldText";

const SendEmailPassForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
    
    handleCancel
}) => {
    return (
        <form
            onSubmit={handleSubmit}
            className="tw-mx-4 tw-w-full md:tw-mx-auto tw-py-2"
        >
            <div className="tw-flex tw-flex-wrap tw-mb-4">
                <InputFieldText
                    classContainer="tw-w-4/5 tw-mx-auto tw-text-left"
                    classLabel="tw-text-white tw-text-xl"
                    classInput="tw-w-full tw-rounded tw-py-2 tw-px-4 focus:tw-outline-none focus:tw-bg-white"
                    // classError = "tw-text-white"
                    name="email"
                    type="text"
                    label="Correo de recuperación"
                    placeholder="Escribe tu correo de recuperación"
                />
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-1 tw-justify-center tw-text-gray-200 tw-text-center">
                <button className = "tw-bg-red-600 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        type="button"
                        onClick = {handleCancel}
                >
                    Cancelar
                </button> 

                <button className="tw-bg-green-700 tw-rounded-md tw-mr-2 tw-p-3 tw-font-medium"
                        type="submit"
                >
                    Enviar correo
                </button> 
            </div>
        </form>
    );
};

export default SendEmailPassForm;
