/**
 * Librerias de react o terceros
 */
import React from "react";

/**
 * Componentes
 */
import InputFieldText from "../../atoms/InputFieldText";

/**
 * Iconos
 */
import { BiSearchAlt } from "react-icons/bi";

const SearchForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
}) => {
    return (
        <form onSubmit={handleSubmit} className="tw-w-full">
            <div className="tw-flex tw-justify-end tw-mx-auto xl:tw-mx-0 tw-rounded-2xl tw-bg-gray-500 tw-border tw-border-gray-600  tw-w-full">
                {/* <input name="search_content" className="tw-rounded-l-2xl tw-px-4 focus:tw-outline-none tw-w-full tw-py-2" type="text" placeholder=" Ingrese su búsqueda ..." /> */}
                <InputFieldText
                    classContainer="tw-w-full"
                    classLabel="tw-hidden"
                    classInput="tw-rounded-l-2xl tw-px-4 focus:tw-outline-none tw-w-full tw-py-2"
                    // classError = "tw-text-white"
                    name="search_content"
                    type="text"
                    label=""
                    placeholder="Ingrese su búsqueda ..."
                />
                <div className="tw-flex hover:tw-bg-gray-600 tw-flex-wrap tw-rounded-r-2xl tw-content-center tw-px-2 tw-bg-gray-500  ">
                    <button type="submit">
                        <BiSearchAlt className="tw-text-white tw-text-2xl" />
                    </button>
                </div>
            </div>
        </form>
    );
};
 
 export default SearchForm;